export enum Severity{
  success='success',
  info='info',
  warn='warn',
  error='error'
}
export enum TabCustomerOrder {
  Customer=0,
  Produit=1,
  TabViewInvoice=2,
  TotalDetails=3
}
