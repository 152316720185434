import { inject } from '@angular/core';
import { CanActivateChildFn, Router } from '@angular/router';
import { GlobalService } from '@core/service/global.service';
import { AuthorizationService } from '@core/service/authorization/authorization.service';
import { MenuLink } from '@shared/tools/enums/enum-router';

export const authCanActivateChildGuard: CanActivateChildFn = async (childRoute, state) => {
  let router= inject(Router);
  let globalService= inject(GlobalService);
  let authorizationService= inject(AuthorizationService);

  //console.log(`authCanActivateChildGuard .::. DEBUT`);
  if( !authorizationService.userConnected?.isConnected ){
    //console.log(`authGuard .::. NOT CONNECTED`);
    router.navigate([ MenuLink.Login ]);
    globalService.showLoader(false);
    return false;
  }
  //console.log(childRoute.data);
  if( childRoute.data['permissions'] ){
    const hasPermission = await authorizationService.hasPermission(childRoute.data['permissions'], 'AND');
    if( !hasPermission ){
      await router.navigate([ MenuLink.Page401 ]);
    }
    //console.log(`authCanActivateChildGuard .::. hasPermission`);
    globalService.showLoader(false);
    return Promise.resolve(hasPermission);
  }
  //console.log(`authCanActivateChildGuard .::. FIN`);
  globalService.showLoader(false);
  return Promise.resolve(true);
};
