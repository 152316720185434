import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@core';
import { ChantUserModel } from '@core/models/cu/chant-user-model';
import { ChantUserService } from '@core/service/cu/chantuser/chantuser.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ImportALlModule } from '@shared/imports';
import { MenuLink } from '@shared/tools/enums/enum-router';

@Component({
  selector: 'app-panel-bar-verticale-one',
  standalone: true,
  imports: [
    CommonModule,
    ImportALlModule,
    //SongItemsCarouselComponent,
  ],
  templateUrl: './panel-bar-verticale-one.component.html',
  styleUrl: './panel-bar-verticale-one.component.scss'
})
export class PanelBarVerticaleOneComponent extends UnsubscribeOnDestroyAdapter {

  @Input()
  showLogo: boolean=true;
  
  constructor(
    public chantUserService: ChantUserService,
    private router: Router,
  ) {super();}

  ngOnInit() {
    this.chantUserService.loadPopularLyricsData(5, true);
    this.chantUserService.loadLatestLyricsData(5, true);
  }

  get popularChantUserList(): ChantUserModel[]{
    return this.chantUserService.popularChantUserListBL;
  }
  get latestChantUserList(): ChantUserModel[]{
    return this.chantUserService.latestChantUserListBL;
  }

  navigateToCUDetailsPublic(row: ChantUserModel) {
    this.router.navigate([MenuLink.ChantUserDetailsPublic, row.id]);//, { queryParams });
  }

}
