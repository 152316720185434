import { Injectable, inject } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { UserModel, UserTextField } from '@core/models/user/user-model';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { EnumStatus, enumStatus } from '@shared/tools/enums/enum-status.enum';
import { SessionAuthService } from './session-auth/session-auth.service';
import { ApiService } from './api/api.service';
import { GlobalService } from './global.service';
import { Auth, signInWithPopup } from '@angular/fire/auth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { GoogleAuthProvider } from "@firebase/auth";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;

  token: string='';
  isConnected =false;
  user: UserModel = new UserModel();
  userSubject = new Subject<UserModel>();

  public textField = UserTextField;
  public enumText = EnumText;
  public status = enumStatus;
  //angularFireAuth= inject(AngularFireAuth);
  //firebaseAuth= inject(Auth);

  constructor(
    private router: Router,
    public apiService: ApiService,
    public globalService: GlobalService,
    public sessionAuthService: SessionAuthService,
    private firebaseAuth: Auth,
  ) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }
  loginWithEmail()//: Observable<void>
  {
    const promise = signInWithEmailAndPassword(this.firebaseAuth, '','').then(()=> {});
    //from(promise);
  }

  async SignInWithOAuthFirebase()//: Observable<firebase.User | null>
  {
    this.globalService.showLoader();
    return new Promise(async (resolve, reject) => {
      await signInWithPopup(this.firebaseAuth, new GoogleAuthProvider()).then(async (result: any)=>{
        let providerId = result.providerId;
        let idFirebaseToken = result._tokenResponse.oauthAccessToken;
        await this.apiService.postData(`${ApiUrl.Firebase_SignInWithOAuth}`, { providerId: providerId, idFirebaseToken: idFirebaseToken } ).subscribe({
          next: (result) => {
            this.user = result;
            console.log(`NEXT.::.`);
            console.log(this.user);
            this.setUser( this.user )
            resolve(this.user);
          },
          error: (ex) => {
            console.log(`ERROR.::.`);
            console.log(ex);
            reject(ex);
          },
          complete: () => {
            console.log(`COMPLETE.::.`);
            this.globalService.showLoader(false);
          }
        });
      }).catch(error => {
        console.log(`signInWithPopup.::. error`);
        console.log(error);
        reject();
      });
    });
  }

/*
{
    "user": {
        "uid": "AGTwsx8GSIh1Ywpgv3gVfePzqcF3",
        "email": "forkyoumyman@gmail.com",
        "emailVerified": true,
        "displayName": "Fork You",
        "isAnonymous": false,
        "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJ7mXig16RqBlSVG2wfH3ik0Dzc6w8Qb4wFim50wdv4lZ6JbNIL=s96-c",
        "providerData": [
            {
                "providerId": "google.com",
                "uid": "102935763238604729403",
                "displayName": "Fork You",
                "email": "forkyoumyman@gmail.com",
                "phoneNumber": null,
                "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJ7mXig16RqBlSVG2wfH3ik0Dzc6w8Qb4wFim50wdv4lZ6JbNIL=s96-c"
            },
            {
                "providerId": "password",
                "uid": "forkyoumyman@gmail.com",
                "displayName": "Fork You",
                "email": "forkyoumyman@gmail.com",
                "phoneNumber": null,
                "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocJ7mXig16RqBlSVG2wfH3ik0Dzc6w8Qb4wFim50wdv4lZ6JbNIL=s96-c"
            }
        ],
        "stsTokenManager": {
            "refreshToken": "AMf-vBzEaCBN0Eovzd8D34aHUr4-7hQW0rxacCzHaV-TmZ8UZkquZ1bpacpGi1Hi1SvaAoSd5BJ-ItOZTl-iyVakfcV2Czgm5mmbOJDqFuZEhwPTQpEqdb7NMkBIoket3C67AOa_FW29mWFljQnDuuq29RpmQGuB_vo5HOK7pEx8CTk2gcjKukKiYDPhKt3J7fo9yJq6TMYrSBHrAum_Pmuga-h72OhNg_veCEzClzuVWVvj03oOEzbeR1Fb8wyp37YJ9qmNZSFHY1uPdIo1eVAQP4awfJgTK1cPR-D9LZOzp-g8RQisKH3DvNF2PUswgj5ftRy7kIFSkXetYa9MzUrjdXICfNkwlRRIqkeCbitxTxJb1yGrfpH02Bo62l2J8ln1apphT1wgPnybyikW4vLPbLP6W1DqdWfujTUvPq_OxjdF6ksydOOaKgoCIRlHCbQEGwBHwdnk",
            "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MDI3MTI2ODJkZjk5Y2ZiODkxYWEwMzdkNzNiY2M2YTM5NzAwODQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRm9yayBZb3UiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jSjdtWGlnMTZScUJsU1ZHMndmSDNpazBEemM2dzhRYjR3RmltNTB3ZHY0bFo2SmJOSUw9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2hhbnQtY2hvcmFsZS1ldC1ncm91cGUiLCJhdWQiOiJjaGFudC1jaG9yYWxlLWV0LWdyb3VwZSIsImF1dGhfdGltZSI6MTcxNDgyMDIwNywidXNlcl9pZCI6IkFHVHdzeDhHU0loMVl3cGd2M2dWZmVQenFjRjMiLCJzdWIiOiJBR1R3c3g4R1NJaDFZd3BndjNnVmZlUHpxY0YzIiwiaWF0IjoxNzE0ODIwMjA3LCJleHAiOjE3MTQ4MjM4MDcsImVtYWlsIjoiZm9ya3lvdW15bWFuQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTAyOTM1NzYzMjM4NjA0NzI5NDAzIl0sImVtYWlsIjpbImZvcmt5b3VteW1hbkBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.OBKw1zmZRga37JaXn7v8vvC1WFeS0w3gMAdi1hqCeIB6X4k9pR1-J6HFo43b9esIcSdfFvdnmpKaFNUsljhLQshDhrtpCSCEnMWalQnM939yB31JEPpn1iaw69X5Bc-3GcnWBzFqHY0NB0-7Y3WGWUkUhf1u5LpVaLQoRpfXFBUE1LmDRwpEJcVxusKA85OXevG88qIuBmRZFuNX-vEWJ_P_W6pGK-3_TW5MqvCWBpUzOwv7RhBdMlDv5-4V6FOA1UCQ7d-RfU1rfkFwuZ_dFge2pwrCHMCoiSMNq5yy9GyzK-mTYfX45aAWqGKb3tPOFkBjvYhfn26IY4RpI0wX-w",
            "expirationTime": 1714823807289
        },
        "createdAt": "1712578140373",
        "lastLoginAt": "1714820207352",
        "apiKey": "AIzaSyBav7q-UIyndJbWs4QmJBRmGa6NQ8qLmGs",
        "appName": "[DEFAULT]"
    },
    "providerId": "google.com",
    "_tokenResponse": {
        "federatedId": "https://accounts.google.com/102935763238604729403",
        "providerId": "google.com",
        "email": "forkyoumyman@gmail.com",
        "emailVerified": true,
        "firstName": "Fork",
        "fullName": "Fork You",
        "lastName": "You",
        "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocJ7mXig16RqBlSVG2wfH3ik0Dzc6w8Qb4wFim50wdv4lZ6JbNIL=s96-c",
        "localId": "AGTwsx8GSIh1Ywpgv3gVfePzqcF3",
        "displayName": "Fork You",
        "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MDI3MTI2ODJkZjk5Y2ZiODkxYWEwMzdkNzNiY2M2YTM5NzAwODQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRm9yayBZb3UiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUNnOG9jSjdtWGlnMTZScUJsU1ZHMndmSDNpazBEemM2dzhRYjR3RmltNTB3ZHY0bFo2SmJOSUw9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2hhbnQtY2hvcmFsZS1ldC1ncm91cGUiLCJhdWQiOiJjaGFudC1jaG9yYWxlLWV0LWdyb3VwZSIsImF1dGhfdGltZSI6MTcxNDgyMDIwNywidXNlcl9pZCI6IkFHVHdzeDhHU0loMVl3cGd2M2dWZmVQenFjRjMiLCJzdWIiOiJBR1R3c3g4R1NJaDFZd3BndjNnVmZlUHpxY0YzIiwiaWF0IjoxNzE0ODIwMjA3LCJleHAiOjE3MTQ4MjM4MDcsImVtYWlsIjoiZm9ya3lvdW15bWFuQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7Imdvb2dsZS5jb20iOlsiMTAyOTM1NzYzMjM4NjA0NzI5NDAzIl0sImVtYWlsIjpbImZvcmt5b3VteW1hbkBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.OBKw1zmZRga37JaXn7v8vvC1WFeS0w3gMAdi1hqCeIB6X4k9pR1-J6HFo43b9esIcSdfFvdnmpKaFNUsljhLQshDhrtpCSCEnMWalQnM939yB31JEPpn1iaw69X5Bc-3GcnWBzFqHY0NB0-7Y3WGWUkUhf1u5LpVaLQoRpfXFBUE1LmDRwpEJcVxusKA85OXevG88qIuBmRZFuNX-vEWJ_P_W6pGK-3_TW5MqvCWBpUzOwv7RhBdMlDv5-4V6FOA1UCQ7d-RfU1rfkFwuZ_dFge2pwrCHMCoiSMNq5yy9GyzK-mTYfX45aAWqGKb3tPOFkBjvYhfn26IY4RpI0wX-w",
        "context": "",
        "oauthAccessToken": "ya29.a0AXooCgv0jHQwXm_GWf-pYqL9DxBpFKeOymPFDFSYGbJto5mkirxUABf1-KKupnsIFvs9a-RXC8mqbMYsGhptTaWjpqK9FH2b8YB9PqOSoalpjfIMlgq-zO-jUdo2DcTDR8qHtoJvbQ9jwiMn0oKqtyTJTPZOWOBWxwLxaCgYKASISARMSFQHGX2MiNaz3CKEKGbPKJRbovVzmqQ0171",
        "oauthExpireIn": 3599,
        "refreshToken": "AMf-vBzEaCBN0Eovzd8D34aHUr4-7hQW0rxacCzHaV-TmZ8UZkquZ1bpacpGi1Hi1SvaAoSd5BJ-ItOZTl-iyVakfcV2Czgm5mmbOJDqFuZEhwPTQpEqdb7NMkBIoket3C67AOa_FW29mWFljQnDuuq29RpmQGuB_vo5HOK7pEx8CTk2gcjKukKiYDPhKt3J7fo9yJq6TMYrSBHrAum_Pmuga-h72OhNg_veCEzClzuVWVvj03oOEzbeR1Fb8wyp37YJ9qmNZSFHY1uPdIo1eVAQP4awfJgTK1cPR-D9LZOzp-g8RQisKH3DvNF2PUswgj5ftRy7kIFSkXetYa9MzUrjdXICfNkwlRRIqkeCbitxTxJb1yGrfpH02Bo62l2J8ln1apphT1wgPnybyikW4vLPbLP6W1DqdWfujTUvPq_OxjdF6ksydOOaKgoCIRlHCbQEGwBHwdnk",
        "expiresIn": "3600",
        "oauthIdToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImFjM2UzZTU1ODExMWM3YzdhNzVjNWI2NTEzNGQyMmY2M2VlMDA2ZDAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODM2MDYxNTQ0Mzk1LTZtaG84aGllcGFrNXZqYmU2ZGxqamdwczRobGxlOTVuLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODM2MDYxNTQ0Mzk1LTZtaG84aGllcGFrNXZqYmU2ZGxqamdwczRobGxlOTVuLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAyOTM1NzYzMjM4NjA0NzI5NDAzIiwiZW1haWwiOiJmb3JreW91bXltYW5AZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJLcXpJaXgwZnNZUWVjdzhtOHMzbzZBIiwiaWF0IjoxNzE0ODIwMjA3LCJleHAiOjE3MTQ4MjM4MDd9.eqc59AkaHQqaIB1ZrvjtVRQgzetITXPiFHX6VploQZOM-Lvhw1D5I6gNipsXZAO0dEnbzzhH57DBiHyoL9m5uE7d6d4ETI92e7OSWqt9OlQP2NVN0VtcvJgO1k1y2sj_hDg1Hqenw2dPz9qsiYNWjQEYwSP4eI3RZPvp842c3wgpbN0JgLxH_CSZEQxeDQukRVRV_cawWZ22oPHota97RNz2ns5HRSr1DMY5f1rlGqkshRkcGf_gBz1m5o5yGKZaawzSaJmEPsZa58qVZrsTrRyuDSXIcrF_mK2Otjyf8LvlwHoP7CTFECf5Yyj00Czd77tpu31-QljBZNQQl0ZYHg",
        "rawUserInfo": "{\"name\":\"Fork You\",\"granted_scopes\":\"https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid\",\"id\":\"102935763238604729403\",\"verified_email\":true,\"given_name\":\"Fork\",\"locale\":\"en-GB\",\"family_name\":\"You\",\"email\":\"forkyoumyman@gmail.com\",\"picture\":\"https://lh3.googleusercontent.com/a/ACg8ocJ7mXig16RqBlSVG2wfH3ik0Dzc6w8Qb4wFim50wdv4lZ6JbNIL=s96-c\"}",
        "kind": "identitytoolkit#VerifyAssertionResponse"
    },
    "operationType": "signIn"
}
*/


/*
{
    "user": {
        "uid": "bx8Wc5fWlwYoE9uDr467zhRFyjF2",
        "email": "duverseau.jeanfritz@gmail.com",
        "emailVerified": true,
        "isAnonymous": false,
        "providerData": [
            {
                "providerId": "google.com",
                "uid": "104318815688099275600",
                "displayName": "Jean Fritz DUVERSEAU",
                "email": "duverseau.jeanfritz@gmail.com",
                "phoneNumber": null,
                "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocInmcTVqhAd2fboUCbzgQoYXimyNGjX6Jl5VJ4RcXY9pMPeNL9IOQ=s96-c"
            },
            {
                "providerId": "apple.com",
                "uid": "000314.01e699a007684acbaa78b0554415d787.1044",
                "displayName": null,
                "email": "duverseau.jeanfritz@gmail.com",
                "phoneNumber": null,
                "photoURL": null
            },
            {
                "providerId": "password",
                "uid": "duverseau.jeanfritz@gmail.com",
                "displayName": null,
                "email": "duverseau.jeanfritz@gmail.com",
                "phoneNumber": null,
                "photoURL": null
            }
        ],
        "stsTokenManager": {
            "refreshToken": "AMf-vByPAhAzVSUMBy4jiELkTIc5Vdr5SGWQS8uSOrrE4smhrA4fdIaMEJOgmY0VpJwaKAqjVYW7-WtG11FR3KAHr_DE3eM4GuDHFTnrRjMl2RQHudSdu5XnPxHEiU2vhkh1t6Nmzoi8vqSI8aOfKcgTZKwFLfGpizn5NpF7FYyXw2v6eJ5qP-G46Ys0AGdXNVc_8aanjatBmMqFfG0qt2OYsNUGiVRX2vqD1g26cEDL06Qsp3Aaa_enjtqcKGKi3NfZOSeSQIZl7mcMIhCmCoBevqFtCH3WtCgZfzrwyE7PeWN9ADlVMxeV5sd3QFZSHDZ8JoTx1vBIqnnZ_WlvAVLJ61q-CobpG5R1QS1ykMrZKUJzMZjUiZ4zE4yU__Gw4YaaCqSjTwbofoSijvmv_SXS2NGDqlDEFQawXo3z8q04rNPmwiwVzlzMevdhAU2TSSfipfzoOlv5T1H4CjmoO5MEWYksdrS4CdoREceMUBuwToWfavsO6JQ",
            "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MDI3MTI2ODJkZjk5Y2ZiODkxYWEwMzdkNzNiY2M2YTM5NzAwODQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2hhbnQtY2hvcmFsZS1ldC1ncm91cGUiLCJhdWQiOiJjaGFudC1jaG9yYWxlLWV0LWdyb3VwZSIsImF1dGhfdGltZSI6MTcxNDgxODU4MCwidXNlcl9pZCI6ImJ4OFdjNWZXbHdZb0U5dURyNDY3emhSRnlqRjIiLCJzdWIiOiJieDhXYzVmV2x3WW9FOXVEcjQ2N3poUkZ5akYyIiwiaWF0IjoxNzE0ODE4NTgwLCJleHAiOjE3MTQ4MjIxODAsImVtYWlsIjoiZHV2ZXJzZWF1LmplYW5mcml0ekBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJhcHBsZS5jb20iOlsiMDAwMzE0LjAxZTY5OWEwMDc2ODRhY2JhYTc4YjA1NTQ0MTVkNzg3LjEwNDQiXSwiZ29vZ2xlLmNvbSI6WyIxMDQzMTg4MTU2ODgwOTkyNzU2MDAiXSwiZW1haWwiOlsiZHV2ZXJzZWF1LmplYW5mcml0ekBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.Na3OcUFdw5L6vCGwafHJR-Y0JTkv1rA-S0bs9EZc2TF9taculljt1E4CVCoUHJjyXZIGRBV58trS0jlQ7JPTn0IaLFeH5Ri0wrVbWc9x6ruoXC6rhulV6yGUhHDqxxgc5S-OViSvGqSYFV_4nTFYStnvZhReZRQr7zRjIdh8bEZk6TOrQLAFdNWa19YRrUYfviTfqc-CxkWjg1CiNjtLElXxuFymbF58ZgpptZLVpw9FwtOTElEN6DIKH5XO2qmbs0zDVOazRmK0yMjZj86UNM1APLBnaPAOWks0WM9ad1yQzJm6go6txEs3ErmrFW6nBc8oWgz0Yjtd5RmMh5C_1g",
            "expirationTime": 1714822180107
        },
        "createdAt": "1500812447000",
        "lastLoginAt": "1714818524955",
        "apiKey": "AIzaSyBav7q-UIyndJbWs4QmJBRmGa6NQ8qLmGs",
        "appName": "[DEFAULT]"
    },
    "providerId": "google.com",
    "_tokenResponse": {
        "federatedId": "https://accounts.google.com/104318815688099275600",
        "providerId": "google.com",
        "email": "duverseau.jeanfritz@gmail.com",
        "emailVerified": true,
        "firstName": "Jean Fritz",
        "fullName": "Jean Fritz DUVERSEAU",
        "lastName": "DUVERSEAU",
        "photoUrl": "https://lh3.googleusercontent.com/a/ACg8ocInmcTVqhAd2fboUCbzgQoYXimyNGjX6Jl5VJ4RcXY9pMPeNL9IOQ=s96-c",
        "localId": "bx8Wc5fWlwYoE9uDr467zhRFyjF2",
        "displayName": "Jean Fritz DUVERSEAU",
        "idToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijc2MDI3MTI2ODJkZjk5Y2ZiODkxYWEwMzdkNzNiY2M2YTM5NzAwODQiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2hhbnQtY2hvcmFsZS1ldC1ncm91cGUiLCJhdWQiOiJjaGFudC1jaG9yYWxlLWV0LWdyb3VwZSIsImF1dGhfdGltZSI6MTcxNDgxODU4MCwidXNlcl9pZCI6ImJ4OFdjNWZXbHdZb0U5dURyNDY3emhSRnlqRjIiLCJzdWIiOiJieDhXYzVmV2x3WW9FOXVEcjQ2N3poUkZ5akYyIiwiaWF0IjoxNzE0ODE4NTgwLCJleHAiOjE3MTQ4MjIxODAsImVtYWlsIjoiZHV2ZXJzZWF1LmplYW5mcml0ekBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJhcHBsZS5jb20iOlsiMDAwMzE0LjAxZTY5OWEwMDc2ODRhY2JhYTc4YjA1NTQ0MTVkNzg3LjEwNDQiXSwiZ29vZ2xlLmNvbSI6WyIxMDQzMTg4MTU2ODgwOTkyNzU2MDAiXSwiZW1haWwiOlsiZHV2ZXJzZWF1LmplYW5mcml0ekBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.Na3OcUFdw5L6vCGwafHJR-Y0JTkv1rA-S0bs9EZc2TF9taculljt1E4CVCoUHJjyXZIGRBV58trS0jlQ7JPTn0IaLFeH5Ri0wrVbWc9x6ruoXC6rhulV6yGUhHDqxxgc5S-OViSvGqSYFV_4nTFYStnvZhReZRQr7zRjIdh8bEZk6TOrQLAFdNWa19YRrUYfviTfqc-CxkWjg1CiNjtLElXxuFymbF58ZgpptZLVpw9FwtOTElEN6DIKH5XO2qmbs0zDVOazRmK0yMjZj86UNM1APLBnaPAOWks0WM9ad1yQzJm6go6txEs3ErmrFW6nBc8oWgz0Yjtd5RmMh5C_1g",
        "context": "",
        "oauthAccessToken": "ya29.a0AXooCguMU_OYKzHVpIAZ9dYbUGSglHwNn3bmYqQwM4QUvu9GE36Y5kQ-MWtsAzQEqG2XG0ntPaxD3sYMyAOb8wheMr3uKytzRtZuMHma1XgBjgWd7nehWFACJv9nC9RMAiz0kd0U1Vzzqp2Xz1d--G5JIsAHtgE9QZ9eaCgYKAR8SARESFQHGX2MiCac2Lkmso2UAJo8uvnRLaQ0171",
        "oauthExpireIn": 3599,
        "refreshToken": "AMf-vByPAhAzVSUMBy4jiELkTIc5Vdr5SGWQS8uSOrrE4smhrA4fdIaMEJOgmY0VpJwaKAqjVYW7-WtG11FR3KAHr_DE3eM4GuDHFTnrRjMl2RQHudSdu5XnPxHEiU2vhkh1t6Nmzoi8vqSI8aOfKcgTZKwFLfGpizn5NpF7FYyXw2v6eJ5qP-G46Ys0AGdXNVc_8aanjatBmMqFfG0qt2OYsNUGiVRX2vqD1g26cEDL06Qsp3Aaa_enjtqcKGKi3NfZOSeSQIZl7mcMIhCmCoBevqFtCH3WtCgZfzrwyE7PeWN9ADlVMxeV5sd3QFZSHDZ8JoTx1vBIqnnZ_WlvAVLJ61q-CobpG5R1QS1ykMrZKUJzMZjUiZ4zE4yU__Gw4YaaCqSjTwbofoSijvmv_SXS2NGDqlDEFQawXo3z8q04rNPmwiwVzlzMevdhAU2TSSfipfzoOlv5T1H4CjmoO5MEWYksdrS4CdoREceMUBuwToWfavsO6JQ",
        "expiresIn": "3600",
        "oauthIdToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImFjM2UzZTU1ODExMWM3YzdhNzVjNWI2NTEzNGQyMmY2M2VlMDA2ZDAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODM2MDYxNTQ0Mzk1LTZtaG84aGllcGFrNXZqYmU2ZGxqamdwczRobGxlOTVuLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODM2MDYxNTQ0Mzk1LTZtaG84aGllcGFrNXZqYmU2ZGxqamdwczRobGxlOTVuLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA0MzE4ODE1Njg4MDk5Mjc1NjAwIiwiZW1haWwiOiJkdXZlcnNlYXUuamVhbmZyaXR6QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiRzlZVGNvVWozdnI2ZjdCREsyVE1fdyIsImlhdCI6MTcxNDgxODU4MCwiZXhwIjoxNzE0ODIyMTgwfQ.iMJyT_yZjaG1N5EHsCHUwSRkGxspyw0wzlfjLK3IK6ZXeZKLDbbsyHMnm25ks91avgZMcYFvHG2qGItuByBtTpuk4e7ZG7_7WpHnEBAstg2_eVn0KMns3T3Fu1Hl1KRBjQnuCohnlKTRvOXq-OOg877dWPYFrRtvCoOZseSm0BOupjrI1_OC3_WZyQcVl3RlZ_w2RPWQk0Cr4-58lB0-1mkfAMctdWq7NxJqEqXFR2czFy0ulyERr4VGef7g7SwvN9mGvyrhjCGbeHau8FiCNrFbMCXbmZMHQmiUfvrUFvXjl6MbEMVvp-BZC-xdtaYuduyFIQeWBGzTmYa-J1S16g",
        "rawUserInfo": "{\"name\":\"Jean Fritz DUVERSEAU\",\"granted_scopes\":\"https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile\",\"id\":\"104318815688099275600\",\"verified_email\":true,\"given_name\":\"Jean Fritz\",\"locale\":\"fr\",\"family_name\":\"DUVERSEAU\",\"email\":\"duverseau.jeanfritz@gmail.com\",\"picture\":\"https://lh3.googleusercontent.com/a/ACg8ocInmcTVqhAd2fboUCbzgQoYXimyNGjX6Jl5VJ4RcXY9pMPeNL9IOQ=s96-c\"}",
        "kind": "identitytoolkit#VerifyAssertionResponse"
    },
    "operationType": "signIn"
}
*/
  async loginWithGoogle2()//: Observable<firebase.User | null>
  {
    return new Promise((resolve, reject) => {
      // this.angularFireAuth.signInWithRedirect(new GoogleAuthProvider()).then((result)=>{
      //   console.log('signInWithRedirect');
      //   console.log(result);
      //   //resolve(this.user);
      // });
    });
  }

  public SignInWithEmailAndPasswordFireBase( login: any) {
    this.globalService.showLoader();
    return new Promise((resolve, reject) => {
      this.apiService.postData(`${ApiUrl.Firebase_SignInWithEmailAndPassword}`, login ).subscribe({
        next: (result) => {
          this.user = result;
          this.setUser( this.user )
          resolve(this.user);
        },
        error: (ex) => {
          console.log(ex);
          reject(ex);
        },
        complete: () => {
          this.globalService.showLoader(false);
        }
      });
    });
  }

  authenticate( login: any ) {
    this.globalService.showLoader();
    console.log(login);
    console.log(`authenticate .::. DEBUT`);
    return new Promise((resolve, reject) => {
      console.log(`authenticate .::. Promise`);
      this.apiService.postData(`${ApiUrl.Authenticate}`, login ).subscribe( ( result: any ) => {
        console.log(`authenticate .::. postData`);
        this.globalService.showLoader(false);
        if (result.status == EnumStatus.Disable) {
            const message = "Sorry, your account is deactivated, Please contact your administrator";
            reject(message);
        }else{
          this.user = result;
          this.setUser( this.user )
          console.log(this.user);
          resolve(this.user);
        }
        console.log(result);
        console.log(`authenticate .::. result`);
      },
      (error) => {
        console.log(`authenticate .::. error`);
        console.log(error);
        //let msg = this.globalService.errorHandleMsg(error);
        reject('Username or password is incorrect');//+'<br /><br />'+msg);
      }
    );
    });
    console.log(`authenticate .::. FIN`);
  }
  public setUser( result: UserModel ){
    this.user = result;
    this.isConnected = true;
    this.user.isConnected = this.isConnected;
    this.sessionAuthService.setItem(this.textField.user, JSON.stringify(this.user));
    localStorage.setItem(this.textField.isConnected, String(this.isConnected));
    this.notify();
    this.sessionAuthService.currentPermissions=this.user.permissions;
    this.sessionAuthService.notifyPermissionCode();
  }
  public resetUserPermission( result: UserModel ){
    this.setUser( result );
  }


  ok(body?: {
    username: string;
    nomComplet: string;
    photoPath: string;
    token: string;
  }) {
    return of(new HttpResponse({ status: 200, body }));
  }
  error(message: string) {
    return throwError(message);
  }

  public logoutScreen() {
    this.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/']);
      }
    });
  }

  logout() {
    this.globalService.showLoader();
    this.isConnected = false;
    this.sessionAuthService.removeItem( this.textField.user );
    this.sessionAuthService.setItem(this.textField.isConnected, String(this.isConnected));
    this.user = new UserModel();
    this.notify();
    this.sessionAuthService.currentPermissions=[];
    this.sessionAuthService.notifyPermissionCode();
    this.globalService.showLoader(false);

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(this.currentUserValue);
    return of({ success: false });
  }

  notify(): void {
    this.userSubject.next( this.user );
    this.currentUserSubject.next( this.user );
  }

  endpoint: string = ApiUrl.User;

  // Session
  get userConnected() : UserModel {
    return this.sessionAuthService.userConnected;
  }

}
