import { Component, Input, OnInit } from '@angular/core';
import { SongItemsCarouselComponent } from '../song-items-carousel/song-items-carousel.component';
import { Router } from '@angular/router';
import { ChantUserService } from '@core/service/cu/chantuser/chantuser.service';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { ChantUserModel } from '@core/models/cu/chant-user-model';
import { TitleBrandComponent } from '../title-brand/title-brand.component';
import { ImportALlModule } from '@shared/imports';

@Component({
  selector: 'app-popular-lyrics',
  standalone: true,
  imports: [
    ImportALlModule,
    SongItemsCarouselComponent,
  ],
  templateUrl: './popular-lyrics.component.html',
  styleUrl: './popular-lyrics.component.scss'
})
export class PopularLyricsComponent  extends UnsubscribeOnDestroyAdapter implements OnInit {
  responsiveOptions: any[]=[];
  @Input() offset: number=5;

  constructor(
    private chantUserService: ChantUserService,
    private router: Router,
  ) {super();}

  ngOnInit() {
    this.chantUserService.loadPopularLyricsData(this.offset);

    this.responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 6,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 2,
            numScroll: 1
        }
    ];
  }
  get popularChantUserList(): ChantUserModel[]{
    return this.chantUserService.popularChantUserList;
  }
  goToChantUser(cu: ChantUserModel){
    this.router.navigate([this.menuLink.ChantUserDetailsPublic, cu.id ])
  }
}
