import { Injectable } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';

import { AuthorizationService } from '../authorization/authorization.service';
import { GlobalService } from '@core/service/global.service';
import { UserModel, UserTextField } from '@core/models/user/user-model';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { enumStatus, EnumStatus } from '@shared/tools/enums/enum-status.enum';
import { AuthService } from '@core';
//import { AuthService } from 'auth.service';
import { SessionAuthService } from '../session-auth/session-auth.service';
import { RoleTextField } from '@core/models/security/role-model';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})

export class UsersService {

  //public isConnected:boolean =false;
  user: UserModel = new UserModel();
  userSubject = new Subject<UserModel>();

  public textField=UserTextField;
  public enumText= EnumText;
  public status=enumStatus;

  endpoint: string = ApiUrl.User;

  constructor( private apiService: ApiService) {}

  getAll() : Observable<any[]>{
    return this.apiService.getData(`${this.endpoint}` )
  }

  getOne( id: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` )
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, )
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, )
  }

  delete( id: string ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` )
  }

}
