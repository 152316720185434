import { Component, Input, input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, InfoService, UserModel } from '@core';
import { GlobalService } from '@core/service/global.service';
import { ImportALlModule } from '@shared/imports';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { environment } from 'environments/environment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthFormComponent } from '../auth-form/auth-form.component';

@Component({
  selector: 'app-login-dialog-form',
  standalone: true,
  imports: [
    ImportALlModule,
    AuthFormComponent,
  ],
  templateUrl: './login-dialog-form.component.html',
  styleUrl: './login-dialog-form.component.scss'
})
export class LoginDialogFormComponent {
  
}
