import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ChantDesperanceModel } from '@core/models/ce/chant-desperance-model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { KeyValueModel } from '@core/key-value-model';

@Component({
  selector: 'app-diagramme-note',
  standalone: true,
  imports: [],
  templateUrl: './diagramme-note.component.html',
  styleUrl: './diagramme-note.component.scss'
})
export class DiagrammeNoteComponent implements OnInit {

  keyValue: KeyValueModel= new KeyValueModel();
  //contentTypeDiagrammeNote:string='';
  pathUrl:any='';
  // @ViewChild('pdfDiagrammeNoteViewer') pdfDiagrammeNoteViewer!: ElementRef;
  public screenWidth: any;
  public screenHeight: any;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public dynamicDialogRef: DynamicDialogRef,
    public elementRef: ElementRef,
    private sanitizer: DomSanitizer,
  ){
    if( dynamicDialogConfig && dynamicDialogConfig.data ){
        if( dynamicDialogConfig.data.keyValue ){
        let ce = dynamicDialogConfig.data.keyValue;
        console.log(ce.pathUrl, this.pathUrl);
        this.pathUrl = this.sanitizer.bypassSecurityTrustResourceUrl(ce.pathUrl);

        //let content = this.keyValue.contentType.split('/');
        //this.contentTypeDiagrammeNote = content[0];
      }else{
        this.keyValue = new KeyValueModel();
        this.onClose();
      }
    }
  }


  ngOnInit() {
    this.screenWidth = `${window.innerWidth}px`;
    this.screenHeight = `${window.innerHeight}px`;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = `${window.innerWidth}px`;
    this.screenHeight = `${window.innerHeight}px`;
  }

// Fonction pour vérifier si l'URL est déjà sécurisée
private isAlreadySafe(url: string): boolean {
  try {
    // Tente de créer une SafeResourceUrl à partir de l'URL
    this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // Si cela réussit, l'URL est déjà sécurisée
    return true;
  } catch (e) {
    // Si une exception est levée, cela signifie que l'URL n'est pas sécurisée
    return false;
  }
}

  onClose(){
    this.dynamicDialogRef.close();
  }

}
