import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ImportALlModule } from '@shared/imports';
import { MenuItem } from 'primeng/api';
import { PTableCeItemsComponent } from '../p-table-ce-items/p-table-ce-items.component';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ChantdesperanceService } from '@core/service/ce/chantdesperance/chantdesperance.service';
import { RowDataDto, TabRowsDataDto } from '@core/models/ce/row-data-dto';
import { GlobalService } from '@core/service/global.service';
import { ActivatedRoute, Params, Router, RouterStateSnapshot } from '@angular/router';
import { TabView } from 'primeng/tabview';
import { GroupeChantantService } from '@core/service/ce/groupChantant/groupe-chantant.service';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { delay } from 'bluebird';
import { CE, CEEBV } from '@shared/tools/constants/constants';
import { PanelBarVerticaleOneComponent } from '../bar-verticale/panel-bar-verticale-one/panel-bar-verticale-one.component';
import { PopularLyricsComponent } from '@shared/components/popular-lyrics/popular-lyrics.component';

@Component({
  selector: 'app-p-tabview-ce-list',
  standalone: true,
  imports: [
    ImportALlModule,
    PTableCeItemsComponent,
    PopularLyricsComponent,
    PanelBarVerticaleOneComponent,
  ],
  templateUrl: './p-tabview-ce-list.component.html',
  styleUrl: './p-tabview-ce-list.component.scss'
})
export class PTabViewCEListComponent  extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  selectedIndex: number=0;
  groupeId:number=12;
  public tabRowsDataDto: TabRowsDataDto[] = [];
  @ViewChild(TabView) tabView!: TabView;

  constructor(
    public chantdesperanceService: ChantdesperanceService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public groupeCeService: GroupeChantantService,
    public globalService: GlobalService,
  ){
    super(); //console.log(`constructor`);
  }

  ngAfterViewInit(): void {
    //console.log(`ngAfterViewInit`);
  }

  async ngOnInit() {
    //console.log(`ngOnInit : PTabViewCEListComponent`);
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['id']){
        this.groupeId = params['id'];
      }
    });
    this.title = this.groupeCeService.isShowCEEBV?'CHANTS CHORALES & GROUPES EEBV':"CHANTS D'ESPÉRANCE";
    await this.loadCEByGroupeData();
  }

  async loadCEByGroupeData()  {
    this.isLoading=true;
    this.globalService.showLoader();
    //if( !this.callRunning1 ){
      // this.callRunning1=true;
      this.subs.sink = await this.chantdesperanceService.getAllByGroupeV2({ groupeId:this.groupeId, isChoeur:0 }).subscribe({
        next: (result) => {
          //console.log(`loadCEByGroupeData :: result:`,result);
          this.globalService.showLoader(false);
          this.tabRowsDataDto = result;
          this.menuItemBreadcrumb = [
            { icon: 'pi pi-home', route: '/' },
            //{ icon: 'pi pi-arrow-circle-left', label: `BACK`, route: `${MenuLink.GroupeCEPublic}` },
            { icon: 'pi pi-book', label: `${this.title}`, route: `${MenuLink.GroupeCEPublic}` },
          ];
          //console.log(`loadCEByGroupeData :: next`);
          if( this.tabRowsDataDto.length > 0 ){
            let group = this.tabRowsDataDto[0].chantDesperanceList[0].groupeChantant;
            this.menuItemBreadcrumb.push({ /*icon: 'pi pi-book', route: `${MenuLink.GroupeCEPublic}`,*/ label: `${group.nom.toUpperCase()}` });
          }
          console.log(this.tabRowsDataDto);
          // this.callRunning1=false;
          this.globalService.showLoader(false);
        },
        error: (ex) => {
          // this.callRunning1=false;
          console.log(`loadCEByGroupeData :: error`, ex);
          this.globalService.showLoader(false);
        },
        complete: () => {
          this.globalService.showLoader(false);
          this.isLoading=false;
          // this.callRunning1=false;
          //console.log(`loadCEByGroupeData :: complete`);
        }
      });
    //}
    // while(this.callRunning1){
    //   await delay(100);
    // }
  }

  onChangeTabView(tab: any){
    this.selectedIndex = tab.index;
    //console.log('onChangeTabView:',tab);
    //console.log('header:', this.tabView.tabs[this.selectedIndex].header);
  }
  public retour(){
    let title = !this.groupeCeService.isShowCEEBV? CE : CEEBV
    //console.log(`retour: ${MenuLink.GroupeCEPublic} || ${this.groupeId} || ${title}`);
    this.router.navigate([ MenuLink.GroupeCEPublic, title ]);
  }

}
