import { HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { Severity } from '@shared/tools/enums/severities';

export const errorInterceptor: HttpInterceptorFn = (request, next) => {
  //let authService = inject(AuthService);
  //let router = inject(Router);
  let messageService = inject(MessageService);
  return next(request).pipe(
    catchError((err: any) => {
      const error = err.error?.message || err.statusText;
      console.log(`errorInterceptor: catchError  -  ICI`, err);
      if ([404,403].includes(err.status)) {
        // auto logout if 401 response returned from api
        //authService.logout();
        //location.reload();
      }
      switch(err.status){
        case HttpStatusCode.BadRequest:
          messageService.add({severity:Severity.error, summary: EnumText.error, detail: error});
          break;
        case HttpStatusCode.Unauthorized:
          //router.navigate([MenuLink.Page401]);
          break;
        case HttpStatusCode.InternalServerError:
          messageService.add({severity:Severity.error, summary: EnumText.error, detail: error});
          break;
      }
      console.log(`errorInterceptor: ${error}`);
      return throwError(error);
    })
  );
};
