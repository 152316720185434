import { Route } from '@angular/router';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { authGuard } from '@core/guard/auth.guard';
import { LayoutWithOutHeaderComponent } from './layout/app-layout/layout-without-header/layout-without-header.component';
import { Page404Component } from './authentication/page404/page404.component';
import { EnumRouterLink, MenuLink } from '@shared/tools/enums/enum-router';
import { authCanActivateChildGuard } from '@core/guard/auth-can-activate-child.guard';
import { MainPublicLayoutComponent } from './layout/app-layout/main-public-layout/main-public-layout.component';
import { HomeComponent } from './components/dashboard/home/home.component';
import { PTabViewCEListComponent } from '@components/public/p-tabview-ce-list/p-tabview-ce-list.component';
import { PGroupeCeListComponent } from '@components/public/p-groupe-ce-list/p-groupe-ce-list.component';
import { PFavorisCeListComponent } from '@components/public/p-favoris-ce-list/p-favoris-ce-list.component';
import { ChantViewDetailsComponent } from '@components/public/p-view-details-ce/chant-view-details.component';
import { LayoutHeaderTransparentComponent } from './layout/app-layout/layout-header-transparent/layout-header-transparent.component';
import { ContactComponent } from '@components/public/contact/contact.component';
import { GuideComponent } from '@components/public/guide/guide.component';
import { PrivacyPolicyComponent } from '@components/public/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '@components/public/terms-conditions/terms-conditions.component';
import { PTabviewCuListComponent } from '@components/public/p-tabview-cu-list/p-tabview-cu-list.component';
import { SearchPageComponent } from '@components/public/search-page/search-page.component';
import { ChantViewDetailsDialogComponent } from '@components/public/p-view-details-ce/chant-view-details-dialog/chant-view-details-dialog.component';

export const APP_ROUTE: Route[] = [
  {
    path: '', component: LayoutHeaderTransparentComponent,
    children: [
      { path: '', component: HomeComponent },
    ]
  },
  {
    path: '', component: MainPublicLayoutComponent,
    children: [
      {
        path: EnumRouterLink.GroupeCEPublic, //data: { permissions: PermissionCode.ManageListChantUser, data:{ permissions: PermissionCode.ManageListChantUser, breadcrumb: 'MAIN',}, },
        children: [
          { path: '', component: PGroupeCeListComponent },
          { path: ':id', component: PGroupeCeListComponent },
        ]
      },
      {
        path: EnumRouterLink.ChantDesperancePublic,
        children:[
          { path: '', component: PTabViewCEListComponent },
          { path: ':id', component: PTabViewCEListComponent },
        ]
      },
      { path: EnumRouterLink.Genre, component: HomeComponent },
      { path: EnumRouterLink.Artist, component: HomeComponent },

      { path: EnumRouterLink.GroupeCEPublic, component: PGroupeCeListComponent },
      { path: EnumRouterLink.FavorisPublic, component: PFavorisCeListComponent },
      { path: EnumRouterLink.Contact, component: ContactComponent, data: { permissions: 'PermissionCode.ManageUsers'} },
      { path: EnumRouterLink.PrivacyPolicy, component: PrivacyPolicyComponent },
      { path: EnumRouterLink.TermsConditions, component: TermsConditionsComponent },
      { path: EnumRouterLink.Guide, component: GuideComponent },
      {
        path: EnumRouterLink.SearchPagePublic,
        children:[
          { path: '', component: SearchPageComponent },
          { path: ':id', component: SearchPageComponent },
        ]
      },
    ]
  },
  {
    path: '', component: MainPublicLayoutComponent,//LayoutWithOutHeaderComponent,
    children: [
      {
        path: EnumRouterLink.ChantDesperanceViewDetailsPublic,
        children:[
          { path: '', component: ChantViewDetailsComponent },
          { path: ':id', component: ChantViewDetailsComponent },
        ]
      },
      {
        path: EnumRouterLink.ChantUserViewDetailsPublic,//
        children:[
          { path: '', component: ChantViewDetailsComponent },
          { path: ':id', component: ChantViewDetailsComponent },
        ]
      },
      {
        path: EnumRouterLink.ChantDesperanceViewDetailsPublic+'test',//
        children:[
          { path: '', component: ChantViewDetailsDialogComponent },
          { path: ':id', component: ChantViewDetailsDialogComponent },
        ]
      },
    ]
  },
  {
    path: '', component: MainLayoutComponent,
    children: [
      //{ path: '', redirectTo: '/dashboard/main', pathMatch: 'full' },
      {
        path: EnumRouterLink.Dashboard,//'dashboard',
        loadChildren: () =>
          import('./components/dashboard/dashboard.routes').then((m) => m.DASHBOARD_ROUTE),
      },
      {
        path: 'advance-table',
        loadChildren: () =>
          import('./advance-table/advance-table.routes').then(
            (m) => m.ADVANCE_TABLE_ROUTE
          ),
      },
      {
        path: 'extra-pages',
        loadChildren: () =>
          import('./extra-pages/extra-pages.routes').then(
            (m) => m.EXTRA_PAGES_ROUTE
          ),
      },
      {
        path: 'multilevel',
        loadChildren: () =>
          import('./multilevel/multilevel.routes').then(
            (m) => m.MULTILEVEL_ROUTE
          ),
      },
    ],
  },
  {
    path: '', component: MainLayoutComponent,
    //canActivate: [ authGuard ],
    canActivateChild: [ authCanActivateChildGuard ],
    children: [
      //{ path: '', redirectTo: '/', pathMatch: 'full' },
      {
        path: EnumRouterLink.ChantDesperanceDirRoot,
        loadChildren: () =>
          import('./components/chant-desperance/chant-desperance.routing').then(
            (m) => m.CHANT_DESPERANCE_ROUTE
          ),
      },
      {
        path: EnumRouterLink.ChantUserDirRoot,
        //canActivateChild: [ authGuard ],
        loadChildren: () =>
          import('./components/chant-user/chant-user.routing').then(
            (m) => m.CHANT_USER_ROUTE
          ),
      },
      {
        path: EnumRouterLink.Security,
        data: { breadcrumb: EnumRouterLink.Security, },
        canActivate: [ authGuard ],
        canActivateChild: [ authCanActivateChildGuard ],
        loadChildren: () => import('./components/security/security-routing.module').then((m) => m.SECURITY_ROUTE),
      },
    ],
  },
  {
    path: EnumRouterLink.Authentication,
    component: LayoutWithOutHeaderComponent,
    loadChildren: () =>
      import('./authentication/auth.routes').then((m) => m.AUTH_ROUTE),
  },

  //{ path: 'google_vignette', component: GoogleAdsComponent },
  { path: '**', component: Page404Component },
];
