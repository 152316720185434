<div *ngIf="!isViewOnly" class="hearderPadding"></div>
<div class="container bgw">
  <div *ngIf="!isViewOnly"  class="hearderPadding"></div>

  <div class="inbox-body no-pad">
    <section class="mail-list">
      <div class="mail-sender">
        <div class="mail-heading">
          <h4 class="vew-mail-header pull-left cp" (click)="retour()" pTooltip="Click to go back" tooltipPosition="bottom">
            <span class="titleGroupSmall">{{dataCE.nomGroupeOuAlbum}}</span>
            <b><i class="pi pi-arrow-circle-left" ></i> {{dataCE.position}}- {{dataCE.titre}}</b>
          </h4>
          <span class="pull-right tr cp">
            <img *ngIf="dataCE.audioPath" id="imgBtnAudio" width="30" [src]="enumImages.IC_SONG" class="img-circle cp"
              (click)="mediaPlayerService.playAudio(dataCE, chantdesperanceService.sessionChantdesperanceCurrentList)" pTooltip="Click to play note" tooltipPosition="bottom" />

            <img *ngIf="dataCE.diagrammeNotePath" id="imgBtnDiagrammeNote" width="30" src="./assets/images/ic_solfege.png" class="img-circle cp"
              (click)="mediaPlayerService.showDiagrammeNote(dataCE)" pTooltip="Click to see note" tooltipPosition="bottom" />

            <img *ngIf="!isViewOnly" class="cp" (click)="addRemove(enumActionCall.CALL_FAVORIS_FUNC)" ImageAlign="Middle" [src]="imgFavoris" [pTooltip]="textFavoris" tooltipPosition="bottom" style="width: 1.8rem;" />
            <button *ngIf="!isViewOnly" [matMenuTriggerFor]="menuOption" class="btn" pTooltip="Menu" tooltipPosition="bottom" >
              <app-feather-icons [icon]="'more-vertical'" [class]="'user-menu-icons'" ></app-feather-icons>
            </button>
            <mat-menu #menuOption="matMenu" class="profile-menu" style="z-index: 99999;">
              <div class="noti-list" style="z-index: 99999;">
                <div class="menu ">
                  <div class="user_dw_menu">
                    <button mat-menu-item class="user-item-list" (click)="isShowZoom=!isShowZoom">
                      <app-feather-icons [icon]="'zoom-in'" [class]="'user-menu-icons'" ></app-feather-icons>Zoom In
                    </button>
                    <!-- <button mat-menu-item class="user-item-list" (click)="changerTaille(-1)">
                      <app-feather-icons [icon]="'zoom-out'" [class]="'user-menu-icons'"></app-feather-icons>Zoom Out
                    </button> -->

                    <span [class]="alignPositionSelected(position.RIGHT)" pTooltip="Align Right" tooltipPosition="bottom">
                      <button mat-menu-item class="user-item-list" (click)="setAlignPosition(position.RIGHT)">
                        <app-feather-icons [icon]="'align-right'" [class]="'user-menu-icons'"></app-feather-icons>
                      </button>
                    </span>
                    <span [class]="alignPositionSelected(position.CENTER)" pTooltip="Align Center" tooltipPosition="bottom">
                      <button mat-menu-item class="user-item-list" (click)="setAlignPosition(position.CENTER)">
                        <app-feather-icons [icon]="'align-center'" [class]="'user-menu-icons'"></app-feather-icons>
                      </button>
                    </span>
                    <span [class]="alignPositionSelected(position.LEFT)" pTooltip="Align Left" tooltipPosition="bottom">
                      <button mat-menu-item class="user-item-list" (click)="setAlignPosition(position.LEFT)">
                        <app-feather-icons [icon]="'align-left'" [class]="'user-menu-icons'"></app-feather-icons>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </mat-menu>
          </span>

          <div class="clear clear-fix" style="clear: both;" ></div>
        </div>

        <hr class="clear">

          <div class="d-flex">
            <div class="flex-grow-1">
              <i *ngIf="dataCE.auteurChant!=''" class="vew-mail-header pull-left cp" (click)="retour()">
                <i class="pi pi-user"></i>: {{dataCE.auteurChant}}
              </i>
              <span class="date float-end cp">
                <b *ngIf="dataCE.nbrOfViews>0">{{dataCE.nbrOfViews}} Views</b> &nbsp;
                <span (click)="addRemove(enumActionCall.CALL_LIKE_FUNC)" [pTooltip]="textLike" tooltipPosition="bottom"><i [class]="imgLike"></i>: {{dataCE.nbrOfLikes}}</span>
              </span>
            </div>
          </div>

      </div>

      <div class="view-mail">
        <button *ngIf="isShowPrecedentRecordBtn && !isViewOnly" class="dynamic-to-top-left" (click)="goToRecordSong(enumActionRecord.RECORD_PRECEDENT)"
        pTooltip="Click to go backward" tooltipPosition="bottom">
          <i class="fa fa-chevron-left"></i>
        </button>
        <button *ngIf="isShowNextRecordBtn && !isViewOnly" class="dynamic-to-top-right" (click)="goToRecordSong(enumActionRecord.RECORD_NEXT)"
          pTooltip="Click to go next" tooltipPosition="bottom">
          <i class="fa fa-chevron-right"></i>
        </button>
        <div class="tc">

          <label ID="literalVideo"></label>

          <span *ngIf="isShowZoom" class="tc" >
            <p-slider [(ngModel)]="this.chantdesperanceService.sessionFontSize" home="13" end="100" styleClass="w-14rem" />
          </span>
          <div [class]="alignPosition" [style]="fontSize" [innerHtml]="dataCE.contenu"></div>

          <br />
          <div>
          Lyric add by :
          <div class="chip dropdown-toggle" ngbDropdownToggle class="">
            <img *ngIf="dataCE.user" [src]="dataCE.user.photoPathUrl" class="user_img" width="32" height="32" alt="User">
            <b *ngIf="dataCE.user"> {{dataCE.user.nomComplet}}</b>
          </div>
          </div>
          <br /><br />
            <button id="LinkButtonRetour3" (click)="retour()" class="btn btnview btn-3 btn-3e icon-arrow-left" style="color: white;">
              Retour
            </button>
            <span *ngIf="!isViewOnly"><br /><br /> <br /><br /> <br /><br /> <br /><br /></span>
        </div>

      </div>
    </section>
  </div>
</div>
