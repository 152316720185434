import { OFFSET } from "@shared/tools/constants/constants";
import { AlbumGroupeModel } from "./ce/album_groupe-model";
import { GroupeChantantDto, GroupeChantantModel } from "./ce/groupe-chantant-model";
import { CategoryCeModel } from "./ce/category-ce-model";
import { LangueModel } from "./langue.model";
import { GenreMusicalDto, GenreMusicalModel, StyleMusicalDto, StyleMusicalModel } from "./ce/chant-genre-style-model";
import { ArtistModel } from "./cu/artist-model";

export class FilterPublicSimple
{
  searchStr?: string;
  offset: number = OFFSET;
  page: number = 1;
}

export class ChantFilterModel  extends FilterPublicSimple
{
  userName?: string;
  albumGroupeId?: string;
  albumGroupeSelected?: any;

  groupeChantantId?: string;
  groupeChantantSelected?: any;

  categorieChantId?: string;
  categorieChantSelected?: any;

  typeChant?: string;
  typeChantSelected?: any;

  isChantChoeur?: string;
  genreMusicalIds?: string[];
  styleMusicalIds?: string[];
  artistIds?: string[];
}
export class ChantFilterObjDto extends FilterPublicSimple
{
  userName?: string;
  isChantChoeur?: string;

  typeChantSelected?: LangueModel;
  artistListSelected?: ArtistModel[];
  albumGroupeSelected?: AlbumGroupeModel;
  categorieChantSelected?: CategoryCeModel;
  groupeChantantSelected?: GroupeChantantModel;
  genreMusicalListSelected?: GenreMusicalModel[];
  styleMusicalListSelected?: StyleMusicalModel[];
}

export class FilterPublicModel extends FilterPublicSimple
{
  groupeId?: string;
  groupeName?: string;
  genreMusicalIds?: string[];
  genreMusicalNameDisplay?: string[];
  styleMusicalIds?: string[];
  styleMusicalNameDisplay?: string[];
  artistIds?: string[];
  artistNameDisplay?: string[];
}
