<nav #navbar class="myNavbar navbar active {{transparent}}">
  <div class="container">
    <div class="navbar-header {{transparent}}">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="/">
        <img [src]="enumImages.LOGO_LYRICS_620x529" style="height: 38px;" alt="" />
        <span class="logo-name">Lyrics</span>
      </a>
    </div>
    <div class="collapse navbar-collapse navbar" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <app-menu-top [isBO]="false" [isTransparent]="isTransparent"></app-menu-top>
    </div>
  </div>
</nav>
