export const environment = {
  production: true,
  name: 'Production',
  serverPathApi: 'https://api.ccg.grafritz.com/',
  serverPathWeb: 'https://lyrics.rezo509.com/',
  apiUrl: '',
  firebaseConfig: {
    apiKey: "AIzaSyBav7q-UIyndJbWs4QmJBRmGa6NQ8qLmGs",
    authDomain: "chant-chorale-et-groupe.firebaseapp.com",
    databaseURL: "https://chant-chorale-et-groupe.firebaseio.com",
    projectId: "chant-chorale-et-groupe",
    storageBucket: "chant-chorale-et-groupe.appspot.com",
    messagingSenderId: "836061544395",
    appId: "1:836061544395:web:3d5c36e9149f403bcb004e"
  }
};
