import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { delay } from 'bluebird';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { Severity } from '@shared/tools/enums/severities';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendMailService extends UnsubscribeOnDestroyAdapter {
  public callRunning=false

  constructor(private apiService: ApiService, private messageService: MessageService) { super();}

  SendMail( data: any ) : Observable<any>{
    return this.apiService.postData(ApiUrl.SendMessage, data, );
  }

}
