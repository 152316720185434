import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { GlobalService } from '@core/service/global.service';
import { AppInfo } from '@shared/tools/enums/AppInfo';
import { MenuLink } from '@shared/tools/enums/enum-router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterLink,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit{

  appInfo = AppInfo;
  menuLink = MenuLink;
  AnneeNow= `${new Date().getFullYear()}`;

  constructor(
    public globalService: GlobalService, public activatedRoute: ActivatedRoute
  ) {

  }
  ngOnInit() {
}

}
