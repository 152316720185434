import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-validator-error',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    InputTextModule,
    MatIconModule,
    MatInputModule,
  ],
  templateUrl: './validator-error.component.html',
  styleUrl: './validator-error.component.scss'
})
export class ValidatorErrorComponent {

  public enumText= EnumText;
  @Input() control: any;

}
