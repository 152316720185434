import { CommonModule, PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Event, Router, NavigationStart, NavigationEnd, RouterModule } from '@angular/router';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { ToastModule } from 'primeng/toast';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, FilterMatchMode, MessageService, PrimeNGConfig } from 'primeng/api';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { InfoService } from '@core';
import { TranslateService } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppInfo } from '@shared/tools/enums/AppInfo';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterModule, PageLoaderComponent, ToastModule,
  ],
  providers: [ ConfirmationService, DynamicDialogRef,DialogService,MessageService,  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {

  title = AppInfo.AppName;
  env = environment;
  currentUrl!: string;

  constructor(
    public _router: Router,
    location: PlatformLocation,
    private primengConfig: PrimeNGConfig,
    private spinner: NgxSpinnerService,
    private infoService: InfoService,
    public translateService: TranslateService
  ) {
    //translateService.addLangs(['en', 'es', 'fr', 'ht']);
    //translateService.setDefaultLang('en');
    //const browserLang = translateService.getBrowserLang();
    //translateService.use(browserLang?.match(/en|es|fr|ht/) ? browserLang : 'en');

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();

        location.onPopState(() => {
          window.location.reload();
        });

        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });
  }

  async ngOnInit(): Promise<void> {
    this.primengConfig.ripple = true;
    this.primengConfig.filterMatchModeOptions = {
      text: [FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
      numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN, FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
      date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER]
    };
    await this.infoService.autoAuthenticate();
    this.infoService.getApiInfoVersion();
  }

}
