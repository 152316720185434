import { Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@Component({
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss'],
    standalone: true,
    imports: [
      LoadingBarModule, NgxSpinnerModule,
      ProgressSpinnerModule,
      //RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
      //NgxSpinnerModule.forRoot({ type: 'square-jelly-box' }),
    ],
})
export class PageLoaderComponent {
  constructor() {
    //constructor
  }
}
