import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ImportALlModule } from '@shared/imports';

@Component({
  selector: 'app-song-items-carousel',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './song-items-carousel.component.html',
  styleUrl: './song-items-carousel.component.scss'
})
export class SongItemsCarouselComponent {

  @Input() dataList: any[]=[];
  @Input() bannerPathUrl: string='';
  @Input() title: string='';
  @Input() stitle: string='';
  @Input() link: string='';

  @Input() numVisible: number=5;
  @Input() numScroll: number=1;

  constructor( private router: Router) {}

  goToPage( id: any ){
    this.router.navigate([this.link, id ])
  }
}
