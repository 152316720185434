import { DOCUMENT, NgClass } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ConfigService } from '@config';
import { InConfiguration, AuthService, RightSidebarService, InfoService } from '@core';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { GS_DEV } from '@shared/tools/constants/constants';
import { SessionAuthService } from '@core/service/session-auth/session-auth.service';
import { MatIcon } from '@angular/material/icon';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ImportALlModule } from '@shared/imports';
import { MenuTopComponent } from '../menu-top/menu-top.component';
import { SearchFieldComponent } from '../search-field/search-field.component';

interface Notifications {
  message: string;
  time: string;
  icon: string;
  color: string;
  status: string;
}

@Component({
  selector: 'app-header-bo',
  templateUrl: './header-bo.component.html',
  styleUrls: ['./header-bo.component.scss'],
  standalone: true,
  imports: [
    ImportALlModule,
    MenuTopComponent,
    SearchFieldComponent,
  ],
  providers: [RightSidebarService]
})
export class HeaderBOComponent  extends UnsubscribeOnDestroyAdapter implements OnInit {
  public config!: InConfiguration;
  isNavbarCollapsed = true;
  isOpenSidebar?: boolean;
  isOpenSettingSidebar?: boolean;
  docElement?: HTMLElement;
  isFullScreen = false;
  public apiInfo='';
  isTransparent:boolean=false;

  public get transparent() :string{
    return this.isTransparent?'transparent':'';
  }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public infoService: InfoService,
    public sessionAuthService: SessionAuthService,
  ) { super(); }

  get isBrainDevUser(): boolean{
    return ( this.sessionAuthService.userConnected.role==GS_DEV)
  }

  notifications: Notifications[] = [
    {
      message: 'Please check your mail',
      time: '14 mins ago',
      icon: 'mail',
      color: 'nfc-green',
      status: 'msg-unread',
    },
    {
      message: 'New Patient Added..',
      time: '22 mins ago',
      icon: 'person_add',
      color: 'nfc-blue',
      status: 'msg-read',
    },
    {
      message: 'Your leave is approved!! ',
      time: '3 hours ago',
      icon: 'event_available',
      color: 'nfc-orange',
      status: 'msg-read',
    },
    {
      message: 'Lets break for lunch...',
      time: '5 hours ago',
      icon: 'lunch_dining',
      color: 'nfc-blue',
      status: 'msg-read',
    },
    {
      message: 'Patient report generated',
      time: '14 mins ago',
      icon: 'description',
      color: 'nfc-green',
      status: 'msg-read',
    },
    {
      message: 'Please check your mail',
      time: '22 mins ago',
      icon: 'mail',
      color: 'nfc-red',
      status: 'msg-read',
    },
    {
      message: 'Salary credited...',
      time: '3 hours ago',
      icon: 'paid',
      color: 'nfc-purple',
      status: 'msg-read',
    },
  ];
  ngOnInit() {
    this.config = this.configService.configData;
    this.docElement = document.documentElement;

    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSettingSidebar = isRunning;
      }
    );

    this.infoService.getApiInfoVersion();
  }

  goTo(page: string){
    this.router.navigate([page]);
  }
  callFullscreen() {
    if (!this.isFullScreen) {
      if (this.docElement?.requestFullscreen != null) {
        this.docElement?.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
  }
  toggleRightSidebar(): void {
    console.log(`toggle Right Sidebar :: this.isOpenSettingSidebar=: ${!this.isOpenSettingSidebar}`);
    this.rightSidebarService.setRightSidebar(!this.isOpenSettingSidebar);
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate([MenuLink.Login]);
      }
    });
  }
}
