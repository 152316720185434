<ul class="nav navbar-nav navbar-right {{transparent}}">

  <li *ngIf="!showSearch">
    <button mat-button class="nav-notification-icons" (click)="showSearch=!showSearch">
      <i class="material-icons-outlined icon-color">
      search
      </i>
    </button>
  </li>
  <li *ngIf="showSearch">
    <app-search-field></app-search-field>
  </li>
  <li>
   <button mat-button class="homelink" (click)="goTo('/')" >
     Home
   </button>
 </li>
 <li>
   <button mat-button class="homelink" (click)="goTo('/artist')">
       <span>Artist</span>
   </button>
 </li>
 <li>
   <button mat-button  class="homelink" (click)="goTo('/genre')">
     <span>Genre</span>
   </button>
 </li>
 <li>
   <button mat-button  class="homelink" (click)="goTo('/contact')">
     Contact
   </button>
 </li>

 <li *ngIf="!sessionAuthService.userConnected.isConnected">
   <button mat-button class="homelink" (click)="goTo(menuLink.Login)">
       <span>Sign In</span>
   </button>
 </li>

 <li *ngIf="!sessionAuthService.userConnected.isConnected">
   <button mat-button  class="homelink" (click)="goTo(menuLink.Login)">
       <span>Create Account</span>
   </button>
 </li>
 @if(isBO){
   <li class="fullscreen">
     <button mat-button class="nav-notification-icons" (click)="callFullscreen()"> <i
         class="material-icons-outlined icon-color">
         fullscreen
       </i></button>
   </li>
   <!-- <li>
     <button mat-button [matMenuTriggerFor]="notificationMenu" class="nav-notification-icons">
       <i class="material-icons-outlined icon-color">
         notifications_active
       </i>
     </button>
     <mat-menu #notificationMenu="matMenu" class="nfc-menu">
       <div class="nfc-header">
         <h5 class="mb-0">Notitications</h5>
         <a class="nfc-mark-as-read">Mark all as read</a>
       </div>
       <div class="nfc-dropdown">
         <ng-scrollbar style="height: 350px" visibility="hover">
           <div class="noti-list header-menu">
             <div class="menu">
               <div>
                 @for (notification of notifications; track notification) {
                 <button mat-menu-item onClick="return false;" [ngClass]="[notification.status]">
                   <span class="table-img msg-user ">
                     <i class="material-icons-outlined nfc-type-icon"
                       [ngClass]="[notification.color]">{{notification.icon}}</i>
                   </span>
                   <span class="menu-info">
                     <span class="menu-title">{{notification.message}}</span>
                     <span class="menu-desc mt-2">
                       <i class="material-icons">access_time</i> {{notification.time}}
                     </span>
                   </span>
                   <span class="nfc-close">
                     <app-feather-icons [icon]="'x'" [class]="'user-menu-icons'"></app-feather-icons>
                   </span>
                 </button>
                 }
               </div>
             </div>
           </div>
         </ng-scrollbar>
       </div>
       <div class="nfc-footer">
         <a class="nfc-read-all">Read
           All Notifications</a>
       </div>
     </mat-menu>
   </li> -->
   <!-- #END# Notifications-->
 }
 <li *ngIf="sessionAuthService.userConnected.isConnected" class="nav-item user_profile">
   <button mat-button [matMenuTriggerFor]="profilemenu" class="homelink">
     <div class="chip dropdown-toggle" ngbDropdownToggle class="">
       <img [src]="sessionAuthService.userConnected.photoPathUrl" class="user_img" width="32" height="32" alt="User">
     </div>
   </button>
   <mat-menu #profilemenu="matMenu" class="profile-menu">
     <div class="noti-list">
       <div class="menu ">
         <div class="user_dw_menu">
           <a mat-menu-item class="user-item-list" [routerLink]="[menuLink.Dashboard]">
             <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>{{'dashboard'| translate}}
           </a>
           <a mat-menu-item class="user-item-list">
             <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Profile
           </a>
           <!-- <a mat-menu-item class="user-item-list">
             <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
           </a> -->
           <button mat-menu-item (click)="logout()" class="user-item-list">
             <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
           </button>
         </div>
       </div>
     </div>
   </mat-menu>
 </li>
 <!-- @if(isBrainDevUser){
   <li>
     <button mat-button (click)="toggleRightSidebar()" class="nav-notification-icons">
       <i class="material-icons-outlined icon-color">
         settings
       </i>
     </button>
   </li>
 } -->
 </ul>
