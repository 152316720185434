<h6 class="b-b p-10 text-strong">Search criteria</h6>
<div class="table-header tc" style="margin-bottom: 10px;">
  <p-iconField iconPosition="left" class="ml-auto" style="width: 99%;">
    <p-inputIcon>
        <i class="pi pi-search"></i>
    </p-inputIcon>
    <input pInputText [(ngModel)]="chantFilter.searchStr" id="searchStr"
    (keyup.enter)="search()" type="text" placeholder="Search keyword"
    style="width: 99%;" />
  </p-iconField>
  &nbsp;
  <button (click)="search()" type="button" class="btn btn-primary"
    pTooltip="{{enumText.SearchAdvanced}}" tooltipPosition="top">
    <i class="pi pi-search"></i>
  </button>

  <!-- <button *ngIf="initSearch" (click)="clear(0)" type="button" class="btn btn-outline"
  pTooltip="{{enumText.ClearSearch}}" tooltipPosition="top">
    <i class="pi pi-filter-slash"></i>
  </button> -->
</div>

<div>
  <button (click)="showFormSearchAdvanced()" type="button" class="btn btn-default"
  pTooltip="{{enumText.ClearSearch}}" tooltipPosition="top" style="width: 100%;">
    <i class="pi pi-filter-slash"></i> Search Advance
  </button>
</div>

<div style="padding-top: 10px;">
  <div *ngIf="chantFilter.searchStr && chantFilter.searchStr!==''">
    <p-chip [label]="chantFilter.searchStr" [removable]="true" (click)="clear(enumFields.SEARCH_STR, '')" />
  </div>
  <!-- <div *ngIf="chantFilter.groupeId && chantFilter.groupeChantantId!==''">
    <h6 class="b-b p-10 text-strong">Album/Group</h6>
    <p-chip [label]="chantFilter.groupeName" [removable]="true" (click)="clear(enumFields.GROUPE_ID, '')" />
  </div>

  <div class="clearfix" *ngIf="chantFilter.genreMusicalNameDisplay">
    <h6 class="b-b p-10 text-strong">Genre</h6>
    @for (genre of chantFilter.genreMusicalNameDisplay; track genre) {
      <div class="chip"><p-chip [label]="genre" [removable]="true" (click)="clear(enumFields.GENRE_MUSICAL_ID, genre)" /></div>
    }
  </div>

  <div class="clearfix" *ngIf="chantFilter.styleMusicalNameDisplay">
    <h6 class="b-b p-10 text-strong">Style</h6>
    @for (style of chantFilter.styleMusicalNameDisplay; track style) {
      <div class="chip"><p-chip [label]="style" [removable]="true" (click)="clear(enumFields.STYLE_MUSICAL_ID, style)" /></div>
    }
  </div> -->

  <div class="clearfix" *ngIf="chantFilterObjDto.genreMusicalListSelected && chantFilterObjDto.genreMusicalListSelected?.length">
    <h6 class="b-b p-10 text-strong">Genre</h6>
    @for (genre of genreMusicalService.genreMusicalDataListSelected; track genre) {
      <span class="chip"><p-chip [label]="genre.name" [removable]="true" (click)="clear(enumFields.GENRE_MUSICAL_ID, genre)" /></span>
    }
  </div>

  <div class="clearfix" *ngIf="chantFilterObjDto.styleMusicalListSelected && chantFilterObjDto.styleMusicalListSelected?.length">
    <h6 class="b-b p-10 text-strong">Style</h6>
    @for (style of chantFilterObjDto.styleMusicalListSelected; track style) {
      <span class="chip"><p-chip [label]="style.name" [removable]="true" (click)="clear(enumFields.STYLE_MUSICAL_ID, style)" /></span>
    }
  </div>

  <!-- <p-chip *ngIf="filterPublic.styleMusicalId && filterPublic.styleMusicalId!==''" [label]="filterPublic.styleMusicalId" [removable]="true" (click)="clear(enumFields.STYLE_MUSICAL_ID)" /> -->
  <!-- <p-chip *ngIf="filterPublic.artistId && filterPublic.artistId!==''" [label]="filterPublic.artistId" [removable]="true" (click)="clear(enumFields.ARTIST_ID)" /> -->

</div>
