import { Component } from '@angular/core';
import { ImportALlModule } from '@shared/imports';

@Component({
  selector: 'app-guide',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './guide.component.html',
  styleUrl: './guide.component.scss'
})
export class GuideComponent {

}
