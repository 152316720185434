import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@config';
import { InConfiguration, AuthService, InfoService } from '@core';
import { SessionAuthService } from '@core/service/session-auth/session-auth.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ImportALlModule } from '@shared/imports';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { MenuItem } from 'primeng/api';
import { HomeComponent } from '../../../components/dashboard/home/home.component';
import { MenuTopComponent } from '../menu-top/menu-top.component';

interface Notifications {
  message: string;
  time: string;
  icon: string;
  color: string;
  status: string;
}
@Component({
  selector: 'app-header-home',
  standalone: true,
  imports: [
    ImportALlModule,
    MenuTopComponent,
  ],
  templateUrl: './header-home.component.html',
  styleUrl: './header-home.component.scss'
})
export class HeaderHomeComponent  extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() isTransparent:boolean=false;
  public config!: InConfiguration;
  isNavbarCollapsed = true;
  isOpenSidebar?: boolean;
  isOpenSettingSidebar?: boolean;
  docElement?: HTMLElement;
  isFullScreen = false;
  public apiInfo='';

  items: MenuItem[] =[];
  notifications: Notifications[] = [
    {
      message: 'Please check your mail',
      time: '14 mins ago',
      icon: 'mail',
      color: 'nfc-green',
      status: 'msg-unread',
    },
    {
      message: 'New Patient Added..',
      time: '22 mins ago',
      icon: 'person_add',
      color: 'nfc-blue',
      status: 'msg-read',
    },
  ];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    //private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public infoService: InfoService,
    public sessionAuthService: SessionAuthService,
  ) { super(); }

  ngOnInit() {
    this.config = this.configService.configData;
    this.docElement = document.documentElement;

    // this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
    //   (isRunning) => {
    //     this.isOpenSettingSidebar = isRunning;
    //   }
    // );

    this.infoService.getApiInfoVersion();
  }

  public get transparent() :string{
    return this.isTransparent?'transparent':'';
  }

  goTo(page: string){
    this.router.navigate([page]);
  }
  callFullscreen() {
    if (!this.isFullScreen) {
      if (this.docElement?.requestFullscreen != null) {
        this.docElement?.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }
  mobileMenuSidebarOpen(event: Event, className: string) {
    const hasClass = (event.target as HTMLInputElement).classList.contains(
      className
    );
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'false');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
      localStorage.setItem('collapsed_menu', 'true');
    }
  }
  toggleRightSidebar(): void {
    console.log(`toggle Right Sidebar :: this.isOpenSettingSidebar=: ${!this.isOpenSettingSidebar}`);
    //this.rightSidebarService.setRightSidebar(!this.isOpenSettingSidebar);
  }

  logout() {
    this.authService.logoutScreen();
  }

}
