<div *ngIf="popularChantUserList.length>0" class="PopularLyrics">
  <app-title-brand titleIcon="fa fa-star" title="Popular lyrics" [linkSeeMore]="menuLink.ChantUserPublic" ></app-title-brand>

  <app-song-items-carousel
  [dataList]="popularChantUserList"
  [numVisible]="5"
  [numScroll]="1"
  [link]="menuLink.ChantUserDetailsPublic"
  ></app-song-items-carousel>

</div>
