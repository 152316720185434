import { BidiModule } from '@angular/cdk/bidi';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarV2Component } from 'app/layout/sidebar/sidebar-v2/sidebar-v2.component';
import { RouterOutlet } from '@angular/router';
import { RightSidebarComponent } from 'app/layout/right-sidebar/right-sidebar.component';
import { SidebarComponent } from 'app/layout/sidebar/sidebar.component';
import { HeaderPublicComponent } from 'app/layout/header/header-public/header-public.component';
import { HeaderBOComponent } from 'app/layout/header/header-bo/header-bo.component';
import { LayoutWithOutHeaderComponent } from '../layout-without-header/layout-without-header.component';
import { HeaderHomeComponent } from 'app/layout/header/header-home/header-home.component';
import { ImportALlModule } from '@shared/imports';
import { FooterComponent } from 'app/layout/footer/footer.component';

@Component({
  selector: 'app-main-public-layout',
  standalone: true,
  imports: [
    HeaderBOComponent,
    HeaderPublicComponent,
    HeaderHomeComponent,
    LayoutWithOutHeaderComponent,
    SidebarComponent,
    SidebarV2Component,
    RightSidebarComponent,
    BidiModule,
    RouterOutlet,
    CommonModule,
    ImportALlModule,
    FooterComponent,
  ],
  templateUrl: './main-public-layout.component.html',
  styleUrl: './main-public-layout.component.scss'
})
export class MainPublicLayoutComponent {

}
