import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ImportALlModule } from '@shared/imports';
import { ID, S } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';

@Component({
  selector: 'app-search-field',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss'
})
export class SearchFieldComponent  extends UnsubscribeOnDestroyAdapter implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public translateService: TranslateService) {super();}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params[ID.toLowerCase()]){
        this.chantFilter.searchStr = params[ID.toLowerCase()];
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if ( params[S.toLowerCase()] ) {
        this.chantFilter.searchStr = params[S];
      }
    });
  }
  searchData(){
    const queryParams = {
      s: this.chantFilter.searchStr,
    };
    this.router.navigate([MenuLink.SearchPagePublic ], { queryParams });
  }

}
