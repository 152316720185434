<!-- <ngx-loading-bar color="#3173D6"></ngx-loading-bar> -->
<!-- <ngx-spinner bdColor="rgb(63,69,95)" size="medium" color="#0af0d5" type="ball-scale-pulse" [fullScreen]="true">
  <p style="color: white"> </p>
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
</ngx-spinner> -->

<ngx-spinner
  bdColor = "rgba(0, 0, 0, 0.8)"
  size = "medium"
  color = "#e66969"
  type = "ball-running-dots"
  [fullScreen] = "true">
  <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
  <p style="color: white" > Loading... </p>
</ngx-spinner>
<!-- https://napster2210.github.io/ngx-spinner/ -->
