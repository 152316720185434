<p-carousel
    [value]="dataList"
    [numVisible]="numVisible"
    [numScroll]="numScroll">
    <ng-template let-obj pTemplate="item">
      <mat-card style="width: 195px;" class="cp" (click)="goToPage(obj.id)">
        <div class="inner divparent" style="
          background-image: url({{obj.albumGroupe?.bannerPathUrl}});
          background-position: center;
          background-size: cover;">
        </div>
          <div class="text-container">
            <div class="font-title tl">
                {{ obj.titre }}
            </div>
            <div class="font-stitle tl">
                {{ obj.albumGroupe?.nom }}
            </div>
          </div>
        <mat-divider></mat-divider>
      </mat-card>
    </ng-template>
  </p-carousel>
