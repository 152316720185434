import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ContactModel, ContactTextField } from '@core/models/Info/info-model';
import { CommonService } from '@core/service/common/common.service';
import { GlobalService } from '@core/service/global.service';
import { SendMailService } from '@core/service/mail/send-mail.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { DisplayHorizontalAdsResponsiveComponent } from '@shared/ads/display-horizontal-ads-responsive/display-horizontal-ads-responsive.component';
import { BreadcrumbButtonTopComponent } from '@shared/components/breadcrumb-button-top/breadcrumb-button-top.component';
import { BreadcrumbV2Component } from '@shared/components/breadcrumb-v2/breadcrumb-v2.component';
import { FeatherIconsComponent } from '@shared/components/feather-icons/feather-icons.component';
import { ToolsButtonSaveComponent } from '@shared/components/tools-button-save/tools-button-save.component';
import { ValidatorErrorComponent } from '@shared/components/validator-error/validator-error.component';
import { ImportALlModule } from '@shared/imports';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { Severity } from '@shared/tools/enums/severities';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { IconFieldModule } from 'primeng/iconfield';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    ImportALlModule,
    ToolsButtonSaveComponent,
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent extends UnsubscribeOnDestroyAdapter implements OnInit
{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //public Editor: any = ClassicEditor;
  public contact: ContactModel = new ContactModel();
  textField = ContactTextField;

  constructor(
    private fb: FormBuilder,
    public globalService: GlobalService,
    public sendMailService: SendMailService,
    // public router: Router,
    // public commonService: CommonService,
    public messageService: MessageService,
    // public activatedRoute: ActivatedRoute,
    // public confirmationService: ConfirmationService,
  ) {
     super();
  }

  ngOnInit(): void {
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   console.log(`params:`, params);
    //   this.id = params['id'];
    // });
    //this.loadAllData();
    this.initForm();
  }

  initForm(){
    this.formGroupPageName = this.fb.group({
      name: this.fb.control(this.contact.name, [Validators.required]),
      email: this.fb.control(this.contact.email, [Validators.required]),
      subject: this.fb.control(this.contact.subject, [Validators.required]),
      message: this.fb.control(this.contact.message, [Validators.required]),
      infoDevice: this.fb.control(this.contact.infoDevice, []),
    });
  }

  onSubmit() {
    console.log('formGroupPageName:', this.formGroupPageName.value);
    console.log('formGroupPageName.invalid:', this.formGroupPageName.invalid);
    this.clear();
    if (this.formGroupPageName.invalid) {
      return;
    }
    this.globalService.showLoader();
    let obj = this.formGroupPageName.value;
    let message = `${obj.message}`;
    message += `<br />From App Web Angular`;
    obj.message = message;
    this.subs.sink = this.sendMailService.SendMail( obj ).subscribe({
      next: (result: any) => {
        console.log(`result:`,result);
        this.messageService.add({severity:Severity.success, summary: 'Message', detail: EnumText.InformationSendWithSuccess});
        this.globalService.showLoader(false);
        //this.onClose();
      },
      error: (ex: any) => {
        console.log(`ERROR.::.`,ex);
        this.globalService.showLoader(false);
        //this.error = ex;
        this.messageService.add({severity:Severity.error, summary: EnumText.error, detail: EnumText.msg_TraitementImpossible});
      },
      complete: () => {
        //console.log(`COMPLETE.::.`);
        this.globalService.showLoader(false);
      }
    });
  }
  clear() {
    this.messageService.clear();
    this.contact.name='';
    this.contact.email='';
    this.contact.subject='';
    this.contact.message='';
  }

  onClose() {
    this.messageService.clear();
  }
}
