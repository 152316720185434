<div class="tc pt-10 pb-10">
  <img *ngIf="showLogo" src="assets/images/lyrics_chant_620x529_exp.png" width="70%" />
  <!-- DisplayAds_Vertical_Responsive -->
  <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-5335107298173193"
      data-ad-slot="3713777744"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
  <script>
      (adsbygoogle = window.adsbygoogle || []).push({});
  </script>
</div>

<div class="clearfix pt-10 pb-10" *ngIf="true">
  <app-title-brand titleIcon="fa fa-star" title="Popular Lyrics" classDiv="b-b" [linkSeeMore]="menuLink.ChantUserPublic" ></app-title-brand>
  <div class="latest-lyrics-list margint10">
    <ul>
      @for (item of popularChantUserList; track item) {
        <li class="cp" (click)="navigateToCUDetailsPublic(item)">
          <div class="lyric-content">
            <h5>
              <a [routerLink]="[menuLink.ChantUserDetailsPublic, item.id]">{{item.titre}}</a>
            </h5>
            <i><a [routerLink]="[menuLink.ChantUserDetailsPublic, item.id]">{{item.auteurChant}}</a></i>
          </div>
        </li>
      }
    </ul>
  </div>
</div>

<div class="clearfix pt-10 pb-10" *ngIf="true">
  <app-title-brand titleIcon="fa fa-clock" title="Latest Lyrics" classDiv="b-b" [linkSeeMore]="menuLink.ChantUserPublic" ></app-title-brand>
  <div class="latest-lyrics-list margint10">
    <ul>
      @for (item of latestChantUserList; track item) {
        <li class="cp" (click)="navigateToCUDetailsPublic(item)">
          <div class="lyric-content">
              <h5>
                <a [routerLink]="[menuLink.ChantUserDetailsPublic, item.id]">{{item.titre}}</a>
              </h5>
              <i><a [routerLink]="[menuLink.ChantUserDetailsPublic, item.id]">{{item.auteurChant}}</a></i>
          </div>
        </li>
      }
    </ul>
  </div>
</div>
<div class="clearfix pt-10 pb-10" *ngIf="false">
  <app-title-brand titleIcon="fa fa-star" title="Popular Artists" classDiv="b-b" [linkSeeMore]="menuLink.ChantUserPublic" ></app-title-brand>

</div>
