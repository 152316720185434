import { Injectable, OnDestroy } from '@angular/core';
import { SubSink } from './sub-sink';
import { EnumText } from './tools/enums/enum-Text';
import { MyPageEvent, PaginationModel } from '@core/models/pagination-model';
import { OFFSET, EnumActionRecord, EnumImages } from './tools/constants/constants';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EnumAction, EnumFields, EnumStatus, EnumStatusPublish } from './tools/enums/enum-status.enum';
import { EnumExtensionFile } from './tools/enums/extension-file.enum';
import { FormGroup } from '@angular/forms';
import { EnumRouterLink, MenuLink } from './tools/enums/enum-router';
import { MenuItem } from 'primeng/api';
import { FilterPublicModel, ChantFilterModel, FilterPublicSimple, ChantFilterObjDto } from '@core/models/filter-search';
import { AlbumGroupeModel } from '@core/models/ce/album_groupe-model';
import { GroupeChantantModel } from '@core/models/ce/groupe-chantant-model';

/**
 * A class that automatically unsubscribes all observables when the object gets destroyed
 */
@Injectable()
export class UnsubscribeOnDestroyAdapter implements OnDestroy {

  public Editor: any = ClassicEditor;
  public menuLink=MenuLink;
  public enumImages=EnumImages;
  public enumRouterLink=EnumRouterLink;
  public enumActionRecord=EnumActionRecord;
  public enumFields=EnumFields;
  public callRunning1=false;
  public callRunning2=false
  public callRunning3=false;


  public id: string|number='';
  public title='';
  public redirectTo='';
  public enumText= EnumText;
  public enumExtensionFile=EnumExtensionFile;
  public enumAction = EnumAction;
  public enumStatus = EnumStatus;
  public enumStatusPublish = EnumStatusPublish;

  public pageEvent: MyPageEvent = new MyPageEvent();
  public pagination: PaginationModel = new PaginationModel();
  public page?: number=1;
  public rows?: number=OFFSET;
  public filterPublicSimple: FilterPublicSimple = new FilterPublicSimple();
  public chantFilterObjDto: ChantFilterObjDto = new ChantFilterObjDto();
  public chantFilter: ChantFilterModel = new ChantFilterModel();
  //public filterPublic: FilterPublicModel = new FilterPublicModel();

  public menuItemBreadcrumb: MenuItem[] = [{ icon: 'pi pi-home', route: '/' }];

  public loadfirst=true;
  public isNew: boolean = true;
  public isLoading:boolean=true;
  public loading: boolean = false;
  public manageListChantDesperance:boolean = false;
  public addButton:boolean = false;
  public editButton:boolean = false;
  public deleteButton:boolean = false;
  public addOrEditButton:boolean = false;

  public message!: any[];
  public searchValue: string | undefined;
  public dateToday: any = Date.now();
  public ddlStatus = EnumStatus;
  public formGroupPageName!: FormGroup;

  /**
   * The subscription sink object that stores all subscriptions
   */
  subs = new SubSink();

  public responseObservable: any;
  /**
   * The lifecycle hook that unsubscribes all subscriptions when the component / object gets destroyed
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.responseObservable?.unsubscribe();
    console.log(`UnsubscribeOnDestroyAdapter .:: ngOnDestroy :.: `);

  }
}
