import { EMPTY_GUID } from "@shared/tools/constants/constants";
import { ArtistModel } from "../cu/artist-model";
import { ChantDesperanceModel } from "./chant-desperance-model";

export class ChantGenreMusicalModel {
  id: string='';
  chantEsperanceId: number=0;
  genreMusicalId: string='';

  genreMusical: GenreMusicalModel | any = new GenreMusicalModel();
  chantEsperance: ChantDesperanceModel | any = new ChantDesperanceModel();
}

export class ChantStyleMusicalModel {
  id: string='';
  chantEsperanceId: string='';
  styleMusicalId: string='';

  styleMusicalModel: StyleMusicalModel | any = new StyleMusicalModel();
  chantEsperance: ChantDesperanceModel | any = new ChantDesperanceModel();
}

export class ChantArtistModel {
  id: string='';
  chantEsperanceId: string='';
  artistId: string='';

  artist: ArtistModel | any = new ArtistModel();
  chantEsperance: ChantDesperanceModel | any = new ChantDesperanceModel();
}

export interface GenreMusicalDto {
  label: string;
  value: string;
  items: StyleMusicalDto[];
}
export interface StyleMusicalDto {
  label: string;
  value: string;
}

export class GenreMusicalModel {
  id: string=EMPTY_GUID;
  name: string='';
  descriptions: string='';
  styleMusicals: StyleMusicalModel[]=[];

  createdBy: string='';
  dateCreated: string='';
  modifBy: string='';
  dateModif: string='';
}

export class StyleMusicalModel extends GenreMusicalModel{
  genreMusicalId: string='';
  objGenre: GenreMusicalModel=new GenreMusicalModel();
}

export enum GenreEtStyleMusicalTextField {
  title='Genre / Style Musical',
  titleGenre='Genre Musical',
  titleStyleMusical='Style Musical',
  name='Name',
  descriptions='Descriptions',
}

export const genreOuStyleMusicalVIDE = {
  id: '',
  name: '- All -',
  descriptions: '',
  createdBy: '',
  modifBy: '',
  dateCreated: '',
  dateModif: '',
}
