
export enum EnumController{
  EnumData= "EnumData",
  v1= "v1",
  v2= "v2",

  ApiInfo= "ApiInfo",

  UserV1= `${v1}/User`,
  DeviceTokenV1 = `${v1}/DeviceToken`,

  Role= `${v1}/Role`,
  Permission= `${v1}/Permission`,
  GroupPermission= `${v1}/GroupPermission`,
  Firebase=`${v1}/Firebase`,

  Artist = `${v1}/Artist`,
  StyleMusical = `${v1}/StyleMusical`,
  GenreMusical = `${v1}/GenreMusical`,

  CategoryCE = `${v1}/CategorieChant`,
  GroupeChantant = `${v1}/GroupeChantant`,
  ChantDesperance = `${v1}/ChantDesperance`,
  ChantDesperanceV2 = `${v2}/ChantDesperance`,

  ChantDesperanceLike = `${v1}/ChantDesperanceLike`,
  ChantHasKnow = `${v1}/ChantHasKnow`,

  ChantFavorisCE = `${v1}/ChantFavoris`,

  ChantUser = `${v1}/ChantUser`,
  ChantUserPublic = `${v1}/ChantUserPublic`,
  ChantUserShare = `${v1}/ChantUserShare`,
  ChantUserLike = `${v1}/ChantUserLike`,
  GroupeCU = `${v1}/GroupeUser`,

  Settings = `${v1}/Settings`,
  Notifications = `${v1}/Notifications`,
//endregion
}

export class ApiUrl {

  public static ApiInfo= `${EnumController.ApiInfo}`;
  public static ApiInfoVersion= `${EnumController.ApiInfo}/version`;
  public static ApiInfoPermission(roleName: string):string { return `${EnumController.ApiInfo}/${roleName}`; }

  public static Artist = `${EnumController.Artist}`;
  public static StyleMusical = `${EnumController.StyleMusical}`;
  public static GenreMusical = `${EnumController.GenreMusical}`;

  public static CategoryCE = `${EnumController.CategoryCE}`;
  public static GroupeChantant = `${EnumController.GroupeChantant}`;
  public static ChantDesperance = `${EnumController.ChantDesperance}`;
  public static ChantDesperanceV2 = `${EnumController.ChantDesperanceV2}`;

  public static ChantDesperanceLike = `${EnumController.ChantDesperanceLike}`;
  public static ChantHasKnow = `${EnumController.ChantHasKnow}`;

  public static ChantFavorisCE = `${EnumController.ChantFavorisCE}`;

  public static GroupeEtTypechantV2(data: any):string { return `${EnumController.ChantDesperanceV2}/groupe-et-typechant/${data.groupId}/${data.typeChant}/${data.isChoeur}`;}

  public static GroupeCU = `${EnumController.GroupeCU}`;
 public static GroupeCUPagination(username: string):string { return `${EnumController.GroupeCU}/paginate/${username}`;}

  public static ChantUser = `${EnumController.ChantUser}`;
  public static ChantUserPublic = `${EnumController.ChantUserPublic}`;
  public static ChantUserShare = `${EnumController.ChantUserShare}`;
  public static ChantUserLike = `${EnumController.ChantUserLike}`;

  public static Settings = `${EnumController.Settings}`;

  //region [ Urls User ]
  public static DeviceAppVersion(packageName: string):string { return `${EnumController.v1}/DeviceAppVersion/${packageName}`; }

  public static DeviceToken = `${EnumController.DeviceTokenV1}`;
  public static SetDeviceToken = `${EnumController.DeviceTokenV1}}/setDeviceToken`;

  public static Firebase_SignInWithEmailAndPassword=`${EnumController.Firebase}/SignInWithEmailAndPassword`;
  public static Firebase_SignInWithOAuth=`${EnumController.Firebase}/SignInWithOAuth`;

  public static User = `${EnumController.UserV1}`;
  public static Authenticate = `${EnumController.UserV1}/authenticate`;
  public static AutoAuthenticate = `${EnumController.UserV1}/auto-authenticate`;
  public static LoginProvider = `${EnumController.UserV1}/login-provider`;
  public static LoginRegisterProvider = `${EnumController.UserV1}/login-register-provider`;
  public static Register = `${EnumController.UserV1}/register`;

  public static UserByUserName(userName: string):string { return `${EnumController.UserV1}/${userName}`;}

  public static DeleteAllDataUser= `${EnumController.UserV1}/deletealldata`;

  public static SendMessage = `${EnumController.Notifications}/send-message`;


  public static Role = `${EnumController.Role}`;
  public static Permission = `${EnumController.Permission}`;
  public static GroupPermission = `${EnumController.GroupPermission}`;

  public static AddRolePermission= `${EnumController.Permission}/AddRolePermission`;
  public static RemoveRolePermission= `${EnumController.Permission}/RemoveRolePermission`;
  public static PermissionInRolePermission= `${EnumController.Permission}/InRolePermission`;

  GroupPermissionUrl= "GroupPermission";

}
