import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MenuLink } from '@shared/tools/enums/enum-router';

@Component({
  selector: 'app-page-data',
  standalone: true,
  templateUrl: './page-data.component.html',
  styleUrl: './page-data.component.scss',
  imports: [
      FormsModule,
      MatButtonModule,
      RouterLink,
      PageDataComponent,
  ],
})
export class PageDataComponent {

  @Input() imageLeft: String="assets/images/pages/bg-04.png";
  @Input() codeError: String="404";
  @Input() message: String="The Page You Are Looking For Not Available!";

  public menuLink = MenuLink;

}
