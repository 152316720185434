import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { PopularLyricsComponent } from '@shared/components/popular-lyrics/popular-lyrics.component';
import { ImportALlModule } from '@shared/imports';
import { PanelBarVerticaleOneComponent } from '../bar-verticale/panel-bar-verticale-one/panel-bar-verticale-one.component';
import { PTableCUItemsComponent } from '../p-table-cu-items/p-table-cu-items.component';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { InfoService } from '@core';
import { TabRowsDataDto } from '@core/models/ce/row-data-dto';
import { ChantUserTextField, ChantUserModel } from '@core/models/cu/chant-user-model';
import { ChantFilterModel, FilterPublicModel } from '@core/models/filter-search';
import { CommonService } from '@core/service/common/common.service';
import { ChantUserService } from '@core/service/cu/chantuser/chantuser.service';
import { GenreMusicalService } from '@core/service/genre-musical/genre-musical.service';
import { GlobalService } from '@core/service/global.service';
import { MediaPlayerService } from '@core/service/media-player/media-player.service';
import { ID, OFFSET, S } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { EnumFields } from '@shared/tools/enums/enum-status.enum';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { PaginatorState } from 'primeng/paginator';
import { TabView } from 'primeng/tabview';
import { PanelSearchBarLateraleComponent } from '../bar-verticale/panel-search-bar-laterale/panel-search-bar-laterale.component';

@Component({
  selector: 'app-search-page',
  standalone: true,
  imports: [
    ImportALlModule,
    PTableCUItemsComponent,
    PopularLyricsComponent,
    PanelBarVerticaleOneComponent,
    PanelSearchBarLateraleComponent,
  ],
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss'
})
export class SearchPageComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  public textField=ChantUserTextField;
  //public enumFields=EnumFields;
  selectedIndex: number=0;
  groupeId:number=12;
  public tabRowsDataDto: TabRowsDataDto[] = [];
  @ViewChild(TabView) tabView!: TabView;
  //public chantUserList: ChantUserModel[] = [];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public globalService: GlobalService,
    public commonService: CommonService,
    public chantUserService: ChantUserService,
    public infoService: InfoService,
    public elementRef: ElementRef,
    public matDialog: MatDialog,
    public mediaPlayerService: MediaPlayerService,
    public genreMusicalService: GenreMusicalService,
  ){
    super(); //console.log(`constructor`);
    this.chantFilter=new ChantFilterModel();
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params[ID.toLowerCase()]){
        this.chantFilter.searchStr = params[ID.toLowerCase()];
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if ( params[S.toLowerCase()] ) {
        this.chantFilter.searchStr = params[S];
      }
    });
    //console.log('SearchPageComponent :: load.Data.PublicBySearchCritere() :: 01-ngOnInit.:.loadfirst:',this.loadfirst);
    this.loadData();
  }

  public loadData(){
    this.LaodAllPublicBySearchCritere();//this.filterPublic);
  }
  public loadDataSearch(filter: any){
    this.chantFilter = filter.data;
    this.chantFilterObjDto = filter.selected;

    console.log('SearchPageComponent :: loadDataSearch():',filter);
    this.LaodAllPublicBySearchCritere();
  }

  async LaodAllPublicBySearchCritere()  {
    //this.isChantUserPublicLoad=true;
    //console.log('SearchPageComponent :: LaodAll.PublicBySearchCritere() :: 01-loadfirst:',this.loadfirst);
    this.isLoading=true;
    this.globalService.isLoading=true;
    //this.globalService.showLoader();
    await this.infoService.autoAuthenticate();
    this.subs.sink = await this.chantUserService.getAllBySearchCritere(this.chantFilter).subscribe({
        next: (result) => {
          this.pagination = result;
          //console.log('SearchPageComponent :: LaodAll.PublicBySearchCritere() :: next-loadfirst:',this.loadfirst);
          if(this.loadfirst){
            this.pageEvent=this.pagination.pageEvent;
            this.loadfirst=false;
          }
          this.chantUserService.chantUserPublicList = this.pagination.data;
          //console.log('SearchPageComponent :: LaodAll.PublicBySearchCritere() :: next-pagination', this.pagination);
          //console.log('SearchPageComponent :: LaodAll.PublicBySearchCritere() :: next-chantUserPublicList', this.chantUserService.chantUserPublicList);
        },
        error: (ex) => {
          console.log(ex);
          this.globalService.isLoading=false;
          this.globalService.showLoader(false);
        },
        complete: () => {
          this.isLoading=false;
          this.globalService.isLoading=false;
          this.globalService.showLoader(false);
        }
      });
  }
  get chantUserList(){ return this.chantUserService.chantUserPublicList; }

  onPageChange(paginatorState: PaginatorState) {
    this.loadfirst = (this.chantFilter.offset != paginatorState.rows);
    this.chantFilter.page = ((paginatorState.page??0)+1);
    this.chantFilter.offset = paginatorState.rows??OFFSET;
    //console.log('SearchPageComponent :: load.Data :: onPageChange.:.loadfirst:',this.loadfirst);
    this.loadData();
  }

  onChangeTabView(tab: any){
    this.selectedIndex = tab.index;
  }
  public retour(){
    let title = '';//!this.groupeCeService.isShowCEEBV? CE : CEEBV
    //console.log(`retour: ${MenuLink.GroupeCEPublic} || ${this.groupeId} || ${title}`);
    this.router.navigate([ MenuLink.GroupeCEPublic, title ]);
  }

  navigateToCEDetailsPublic(row: ChantUserModel) {
    this.chantUserService.sessionChantUserSelected=row;
    this.chantUserService.sessionChantUserCurrentList=this.chantUserList;
    //this.chantUserService.sessionTypeChant=row.styleMusicalId;
    this.chantUserService.sessionGroupeId=row.groupeChantantId;
    //this.router.navigate([ MenuLink.ChantDesperanceDetailsPublic, row.id ], queryParams:{'?ID=206&tc=FR&ri=1&IDG=12'});
    let index = this.chantUserList.findIndex((x)=> x.id===row.id).toFixed();
    const queryParams = {
      //tc: row.styleMusicalId,
      ri: index,
      IDG: row.groupeChantantId,
    };
    this.router.navigate([MenuLink.ChantUserDetailsPublic, row.id], { queryParams });
  }

}
