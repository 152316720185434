import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValueModel } from '@core/key-value-model';
import { DiagrammeNoteComponent } from '@shared/components/diagramme-note/diagramme-note.component';
import { AppInfo } from '@shared/tools/enums/AppInfo';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { EnumRouterLink } from '@shared/tools/enums/enum-router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  listeners: any[] = [];
  public appInfo=AppInfo;
  public enumText=EnumText;
  public routerLink=EnumRouterLink;
  public isSideBarOpen: string = "isSideBarOpen"
  public isLoading:boolean=true;

  constructor(private spinner: NgxSpinnerService){ }


  subscribe(eventType: string, callback: (data: any) => void ){
    return this.listeners.push({ eventType, callback }) - 1;
  }

  unsubscribe( subscriptionId: number ){
    this.listeners = this.listeners.filter((_, i ) => i !== subscriptionId );
  }

  notify( eventType: string, data: any ){
    this.listeners.filter((v) => v.eventType === eventType ).forEach(v => v.callback( data ));
  }

  showLogoWithText(data: boolean = true){
    this.notify(this.isSideBarOpen, data);
  }

  showLoader(value: boolean=true) {
    this.isLoading = value;
    if( value )
      this.spinner.show();
    else
      this.spinner.hide();
  }

  errorHandleMsg( error: any )
  {
    switch (error.status) {
      case HttpStatusCode.BadRequest:
        return "Some fields are mandatory, Something went wrong when the record";
      case HttpStatusCode.NotFound:
        return "This information does not exist";
      case HttpStatusCode.Conflict:
        return "This information already exists!";
      case HttpStatusCode.InternalServerError:
        return "Something went wrong when saving the record";
    //case HttpStatusCode.Created, HttpStatusCode.NoContent)
    //    return ;
      default:
        return this.errorHandleMsg2(error);
    }
  }

  public errorHandleMsg2(error: any ){
    let msg = '';
    if( typeof error === 'string'){
      msg = error;
    }else if( typeof error === 'object' && error && error.message && typeof error.message === 'string' ){
      msg = error.message;
    }
    console.log(msg);
    return msg;
  }

  public onError(error: any){
    console.log( error );
  }

}
