import { Injectable } from '@angular/core';
import { CategoryCeModel, CategoryCeTextField, categoryCeVIDE } from '@core/models/ce/category-ce-model';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { ApiService } from '../../api/api.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { Observable } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';

@Injectable({
  providedIn: 'root'
})
export class CategoryCeService  extends UnsubscribeOnDestroyAdapter {

  endpoint: string = ApiUrl.CategoryCE;
  public textField=CategoryCeTextField;
  //public enumText= EnumText;
  public loadingCategoryDataList=false;
  public categoryDataList:CategoryCeModel[] = [];

  constructor( private apiService: ApiService) {super();}

  getAll() : Observable<any[]>{
    return this.apiService.getData(`${this.endpoint}` );
  }

  getOne( id: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }

  delete( id: string ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` );
  }

  async loadDataCategory()  {
    if(this.categoryDataList?.length<=0){
      this.loadingCategoryDataList=true;
      this.subs.sink = await this.getAll().subscribe({
        next: (result) => {
          this.categoryDataList = result;
          this.categoryDataList.unshift(categoryCeVIDE);
        },
        error: (ex) => {
          console.log(ex);
          this.loadingCategoryDataList=false;
        },
        complete: () => {
          this.loadingCategoryDataList=false;
        }
      });
    }
  }
}
