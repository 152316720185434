import { Injectable } from '@angular/core';
import { MediaPlayerModel, StreamStateModel } from '@core/models/media-player-model';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { of } from 'rxjs';
import { EnumActionRecord } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { Router } from '@angular/router';
import { ChantDesperanceModel, ChantDesperanceTextField } from '@core/models/ce/chant-desperance-model';
import { ChantUserModel } from '@core/models/cu/chant-user-model';
import { KeyValueModel } from '@core/key-value-model';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class MediaPlayerService {

  public countRowIndex:number = 0;
  public currentAudioFile: any={};
  public isFirstPlay: boolean = true;

  public mediaPlayerList: ChantDesperanceModel[]=[];
  public mediaPlayer: ChantDesperanceModel = new ChantDesperanceModel();

  private mediaPlayerSubject = new Subject<ChantDesperanceModel>();
  public streamState: StreamStateModel = new StreamStateModel();

  constructor(public router: Router, public commonService: CommonService) {
    //console.log(`constructor : MediaPlayerService`);
  }

  notify(): void {
    this.mediaPlayerSubject.next( this.mediaPlayer );
  }

/// ------- BEGIN -------- ///

  private stop$ = new Subject();
  private audioObj = new Audio();

  audioEvents = [
    'ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'
  ];

  private state: StreamStateModel = {
    playing: false,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    canplay: false,
    error: false,
  };

  private streamObservable(url: string ) {
    return new Observable(observer => {
      // Play audio
      this.audioObj.src = url;
      this.audioObj.load();
      this.audioObj.play();

      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };

      this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(this.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };
    });
  }
  private addEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj: any, events: any, handler: any) {
    events.forEach((event: any) => {
      obj.removeEventListener(event, handler);
    });
  }

  playStream(url: string) {
    //console.log(`playStream:`, url);
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }


  playStreamAudio(url:any) {
    this.playStream(url).subscribe(events => {
      //console.log(`playStream:`,events);
      // listening for fun here
    });
  }
  openFileAudio(fileMedia: ChantDesperanceModel, index:any) {
    this.currentAudioFile = { index, fileMedia };
    this.stop();
    this.playStreamAudio(fileMedia.audioPathUrl);
  }
  openFileAudioLocal(fileMedia: MediaPlayerModel, index:any) {
    this.currentAudioFile = { index, fileMedia };
    this.stop();
    this.playStreamAudio(fileMedia.url);
  }

  play() {
    // if( this.isFirstPlay ){
    //   // console.log(`play()`);
    //   // let index = 0;
    //   // let file = this.files[index];
    //   if( this.currentAudioFile.index==undefined || this.currentAudioFile.index>=0 ){
    //     this.openFileAudio(this.currentAudioFile, 0);
    //   }
    // }
    this.audioObj.play();
  }

  pause() {
    this.audioObj.pause();
  }

  mute() {
    this.audioObj.muted=!this.audioObj.muted;
  }
  get muteIcon(): string {
    return this.audioObj.muted?'volume':'volume-1';
  }

  stop() {
    this.stop$.next('');
  }

  goToRecordSong(ACTION_RECORD: string){
    //await this.getDataChantByGroupeByType();
    if ( this.mediaPlayerList.length > 0 ) {
      if (ACTION_RECORD==EnumActionRecord.RECORD_PRECEDENT) {
        this.countRowIndex--;
      }
      if (ACTION_RECORD==EnumActionRecord.RECORD_NEXT) {
        this.countRowIndex++;
      }
      //console.log(`goToRecordSong(${ACTION_RECORD}): AAFTER [ +=1 ] this.countRowIndex:: `, this.countRowIndex);
      //txt_countRowIndex.Text = countRowIndex
      let item = this.mediaPlayerList[this.countRowIndex];

      //this.chantdesperanceService.sessionChantdesperanceSelected = item
      //this.setDataChant(this.chantdesperanceService.sessionChantdesperanceCurrentList);
      //console.log(`goToRecordSong(${ACTION_RECORD}): this.countRowIndex:: `, this.countRowIndex);
      //console.log(`goToRecordSong(${ACTION_RECORD}): item:: `, item);
      //this.chantId=this.chantdesperanceService.sessionChantdesperanceSelected .id;
      //this.updateCEView();

      const queryParams = {
        tc: item.typeChant,
        ri: this.countRowIndex,
        IDG: item.groupeChantantId,
      };
      this.openFileAudio(item, this.countRowIndex.toString());
      //await this.getChantDesperanceById();
      this.router.navigate([MenuLink.ChantDesperanceDetailsPublic, item.id ], { queryParams });
    }
  }
  previous() {
    this.goToRecordSong(EnumActionRecord.RECORD_PRECEDENT);
  }
  next() {
    this.goToRecordSong(EnumActionRecord.RECORD_NEXT);
  }

  seekTo(seconds: any) {
    console.log(`seekTo:`,seconds);
    this.audioObj.currentTime = seconds;
  }

  formatTime(time: any, format: string = 'HH:mm:ss') {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }
  private stateChange: BehaviorSubject<StreamStateModel> = new BehaviorSubject(this.state);

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case 'canplay':
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case 'playing':
        this.state.playing = true;
        break;
      case 'pause':
        this.state.playing = false;
        break;
      case 'timeupdate':
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        break;
      case 'error':
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  private resetState() {
    this.state = {
      playing: false,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      canplay: false,
      error: false
    };
  }

  getState(): Observable<StreamStateModel> {
    return this.stateChange.asObservable();
  }

  //////////////////////
  // public mediaPlayerList: MediaPlayerModel[] = [
  //   // tslint:disable-next-line: max-line-length
  //       { url: 'https://ia801504.us.archive.org/3/items/EdSheeranPerfectOfficialMusicVideoListenVid.com/Ed_Sheeran_-_Perfect_Official_Music_Video%5BListenVid.com%5D.mp3',
  //         id: '1',
  //         name: 'Perfect',
  //         artist: ' Ed Sheeran'
  //       },
  //       {
  //   // tslint:disable-next-line: max-line-length
  //         url: 'https://ia801609.us.archive.org/16/items/nusratcollection_20170414_0953/Man%20Atkiya%20Beparwah%20De%20Naal%20Nusrat%20Fateh%20Ali%20Khan.mp3',
  //         id: '2',
  //         name: 'Man Atkeya Beparwah',
  //         artist: 'Nusrat Fateh Ali Khan'
  //       },
  //       { url: 'https://ia801503.us.archive.org/15/items/TheBeatlesPennyLane_201805/The%20Beatles%20-%20Penny%20Lane.mp3',
  //         id: '3',
  //         name: 'Penny Lane',
  //         artist: 'The Beatles'
  //       }
  //     ];

  getFiles() {
    return of(this.mediaPlayerList);
  }
  /// ------- END -------- ///


  public textField=ChantDesperanceTextField;
  public enumText= EnumText;
  public showDiagrammeNote(row: any){
    let keyValue = new KeyValueModel();
    keyValue.title = row.titre!==''?row.titre: this.textField.diagrammeNotePath;
    keyValue.pathUrl = row.diagrammeNotePathUrl;
    console.log('showDiagrammeNote: keyValue:', keyValue);
    this.commonService.showPopUpFile(keyValue);
  }

  public playAudio(row: ChantDesperanceModel, chantDesperanceList?:ChantDesperanceModel[]){
    console.log(`playAudio:`, row);

    if (row.audioPathUrl && row.audioPathUrl !== '') {
      if( this.currentAudioFile?.fileMedia?.id != row.id.toString() ){
        this.openFileAudio( row, row.id.toString());
      }
      this.play();

      if( chantDesperanceList ){
        let audioList = chantDesperanceList.filter(x => x.audioPathUrl !== '');
        if( audioList.length >0 ){
          // get the indexAudio
          this.countRowIndex = audioList.findIndex((x)=> x.id==row.id);
        }
        this.mediaPlayerList = audioList;
        console.log(`audioList:`, audioList);
      }
    }
  }

  // public playAudioCU_TO_DELETE(row: ChantUserModel, chantUserList?:ChantUserModel[]){
  //   console.log(`playAudio:`, row);

  //   if (row.audioPathUrl && row.audioPathUrl !== '') {
  //    let audioFile: MediaPlayerModel = {
  //       id: row.id.toString(),
  //       url:row.audioPathUrl,
  //       name: `${row.titre}`,
  //       artist: `${row.auteurChant}`,
  //       position: `${row.position}`,
  //       titre: `${row.titre}`,
  //       auteurChant: `${row.auteurChant}`,
  //       typeChant: `${row.typeChant}`,
  //       groupeChantantId: row.groupeChantantId,
  //     };
  //     if( this.currentAudioFile?.fileMedia?.id != row.id.toString() ){
  //       this.openFileAudio( row, row.id);
  //     }
  //     this.play();
  //     let audioList:MediaPlayerModel[] =[];
  //     if( chantUserList ){
  //       chantUserList.forEach((x) => {
  //         if( x.audioPathUrl !== ''){
  //           audioList.push({
  //             id: x.id.toString(),
  //             url:x.audioPathUrl,
  //             name: `${x.titre}`,
  //             artist: `${x.auteurChant}`,
  //             position: `${x.position}`,
  //             titre: `${x.titre}`,
  //             auteurChant: `${x.auteurChant}`,
  //             typeChant: `${x.typeChant}`,
  //             groupeChantantId: x.groupeChantantId,
  //           });
  //         }
  //       });
  //       if( audioList.length >0 ){
  //         // get the indexAudio
  //         this.countRowIndex = audioList.findIndex((x)=> x.id==row.id.toString());
  //       }
  //       this.mediaPlayerList = audioList;
  //       console.log(`audioList:`, audioList);
  //     }
  //   }
  // }

}
