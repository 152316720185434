<div class="row">
  <br style="clear: both;" />
  <div class="hr-line-dashed"></div>
  <div class="form-group">
      <div class="col-sm-12 {{class}}">
          <button *ngIf="showBtnCancel" type="button" (click)="onClose()" class="btn btn-danger"><i class="fa fa-reply"></i> {{ enumText.Cancel }}</button>&nbsp;&nbsp;
          <button *ngIf="addOrEditButton" type="submit" [disabled]="disabledBtnSaveChanges()"
          class="btn btn-primary" ><i [class]="iconBtnSaveChanges"></i> {{textBtnSaveChanges}}</button>
      </div>
  </div>
</div>
