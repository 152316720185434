import { Component, OnInit } from '@angular/core';
import { ProductMockService } from '@core/service/mock-service/product-mock.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { GroupeChantantModel } from '@core/models/ce/groupe-chantant-model';
import { GroupeChantantService } from '@core/service/ce/groupChantant/groupe-chantant.service';
import { ImportALlModule } from '@shared/imports';
import { Router } from '@angular/router';
import { ChantUserService } from '@core/service/cu/chantuser/chantuser.service';
import { ChantUserModel } from '@core/models/cu/chant-user-model';
import { SongItemsCarouselComponent } from '@shared/components/song-items-carousel/song-items-carousel.component';
import { SongItemsColumns3Component } from '@shared/components/song-items-columns-3/song-items-columns-3.component';
import { SongItemsSliderComponent } from '@shared/components/song-items-slider/song-items-slider.component';
import { PopularLyricsComponent } from '@shared/components/popular-lyrics/popular-lyrics.component';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '@core/service/seo/seo.service';

export class Product {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  price?: number;
  quantity?: number;
  inventoryStatus: string='';
  category?: string;
  image?: string;
  rating?: number;
}

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    ImportALlModule,
    SongItemsColumns3Component,
    SongItemsSliderComponent,
    PopularLyricsComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',

  providers: [ProductMockService]
})
export class HomeComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  layout: string = 'list';
  products: Product[]=[];

  responsiveOptions: any[]=[];

  constructor(
    private productService: ProductMockService,
    private groupeCeService: GroupeChantantService,
    private chantUserService: ChantUserService,
    private router: Router,
    public translateService: TranslateService,
    public seoService: SeoService
  ) {
    super();
    this.seoService.setTitleTag('');
    this.seoService.setMetaTags();
  }

  ngOnInit() {
    this.groupeCeService.loadGroupeCeHomeData();

    //this.chantUserService.loadPopularLyricsData();
    this.chantUserService.loadLatestLyricsData();

    this.productService.getProductsSmall().then((products) => {
        this.products = products;
    });

    this.responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 6,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 2,
            numScroll: 1
        }
    ];
  }

  get groupeDataList(): GroupeChantantModel[]{
    return this.groupeCeService.groupeDataList;
  }
  goToGroupeCE(code: string){
    this.router.navigate([this.menuLink.ChantDesperancePublic, code ])
  }
  get popularChantUserList(): ChantUserModel[]{
    return this.chantUserService.popularChantUserList;
  }
  get latestChantUserList(): ChantUserModel[]{
    return this.chantUserService.latestChantUserList;
  }
  goToChantUser(cu: ChantUserModel){
    this.router.navigate([this.menuLink.ChantUserDetailsPublic, cu.id ])
  }

  searchData(){
    const queryParams = {
      s: this.chantFilter.searchStr,
    };
    this.router.navigate([MenuLink.SearchPagePublic ], { queryParams });
    //this.router.navigate([this.menuLink.SearchPagePublic, this.filterPublic.searchStr ])
  }

  getSeverity(status: string): string {
    switch (status) {
      case 'INSTOCK':
        return 'success';
      case 'LOWSTOCK':
        return 'warning';
      case 'OUTOFSTOCK':
        return 'danger';
      default:
        return 'danger';
    }
  }

  navigateToCUDetailsPublic(row: ChantUserModel) {
    this.router.navigate([MenuLink.ChantUserDetailsPublic, row.id]);//, { queryParams });
  }

}
