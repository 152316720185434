import { Injectable } from '@angular/core';
import { delay } from "bluebird";
import { Observable, Subject } from 'rxjs';
import { InfoModel } from '@core/models/Info/info-model';
import { SessionAuthService } from '@core/service/session-auth/session-auth.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  public infoModel!:InfoModel;
  public callRunning=false;
  public callRunning2=false
  public callRunning3=false;

  public jwtToken: string ='';
  public apiVersion: string='';

  infoSubject = new Subject<InfoModel>();
  jwtTokenSubject = new Subject<string>();

  constructor(
    public apiService: ApiService ,
    public sessionAuthService: SessionAuthService
    ) { }

  public async getInfoPermission(): Promise<InfoModel>{
    const role = this.sessionAuthService.userConnected?.role;
    //console.log('001-IN getInfo role:'+role);
    if( !this.infoModel ){
      if( !this.callRunning ){
        this.callRunning=true;
        this.infoModel = await this.apiService.getToPromise( ApiUrl.ApiInfoPermission(role));
        //console.log('002-IN getInfo result');
        //console.log(this.infoModel);
        this.notify();
        this.callRunning=false;
        //await this.apiService.getData(ApiUrl.ApiInfoPermission(role)).subscribe((result) =>{});
      }
      while(this.callRunning){
        await delay(100);
      }
    }
    //console.log('003-IN getInfo result');
    return Promise.resolve( this.infoModel );
  }

  public async autoAuthenticate(){
    if( !this.jwtToken || this.jwtToken==='' ){
      if( !this.callRunning2 ){
        this.callRunning2=true;
        let result = await this.apiService.postData( ApiUrl.AutoAuthenticate, {packageName: 'net.braindev.chantchoraleetgroupe'});
        result.subscribe({
          next: (result) => {
            this.jwtToken = result.jwtToken;
            //console.log(`autoAuthenticate.:.next`, result.jwtToken);
            this.notifyJwtToken();
            this.callRunning2=false;
          },
          complete:()=>{
            //console.log(`autoAuthenticate.:.complete`);
            this.callRunning2=false;
          },
          error: (ex) => {
            //console.log(`autoAuthenticate.:.error`, ex);
            this.callRunning2=false;
          },
        });
      }
      while(this.callRunning2){
        //console.log(`autoAuthenticate :: delay(100)::001`);
        await delay(100);
        //console.log(`autoAuthenticate :: delay()::002`);
      }
    }
    //return Promise.resolve( this.jwtToken );
  }
  //
  public async getApiInfoVersion(){
    if( !this.apiVersion || this.apiVersion==='' ){
      if( !this.callRunning3 ){
        this.callRunning3=true;
        let result = await this.apiService.getApiInfoVersion( ApiUrl.ApiInfoVersion);
        result.subscribe({
          next: (result) => {
            this.apiVersion = `API ${result.version}`;
            this.callRunning3=false;
          },
          complete:()=>{
            this.callRunning3=false;
          },
          error: (ex) => {
            console.log(ex);
            this.callRunning3=false;
          },
        });
      }
      while(this.callRunning3){
        await delay(100);
      }
    }
    //return Promise.resolve( this.jwtToken );
  }
  getApiInfoVersion2() : Observable<any> {
    return this.apiService.getData(ApiUrl.ApiInfoVersion );
  }

  notify(): void {
    this.infoSubject.next( this.infoModel );
  }

  notifyJwtToken(): void {
    this.sessionAuthService.setJwtToken(this.jwtToken);
    this.jwtTokenSubject.next( this.jwtToken );
  }

}

