import { OFFSET } from "@shared/tools/constants/constants";

export class PaginationModel {
  currentPage: number =0;
  firstPageUrl: string ='';
  from: number =0;
  lastPage: number =0;
  lastPageUrl: string ='';
  links: LinksModel[] =[];
  nextPageUrl: string ='';
  path: string ='';
  perPage: string ='';
  prevPageUrl: string ='';
  to: number =0; //
  total: number =0; // Nbr total of rows
  pageEvent: MyPageEvent = new MyPageEvent();
  data: any[] =[];
}

export class LinksModel {
  url: string ='';
  label: any ='';
  active: boolean =false;
}

export class MyPageEvent {
  first: number =0;
  rows: number =OFFSET;
  page: number =1; // the Current page
  pageCount: number =60;
}
