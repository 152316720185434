import { Injectable } from '@angular/core';
import { CategoryCeTextField } from '@core/models/ce/category-ce-model';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { GenreEtStyleMusicalTextField, GenreMusicalDto, GenreMusicalModel, StyleMusicalModel } from '@core/models/ce/chant-genre-style-model';
import { UnsubscribeOnDestroyAdapter } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class GenreMusicalService extends UnsubscribeOnDestroyAdapter {

  endpoint: string = ApiUrl.GenreMusical;
  endpointStyleMusical: string = ApiUrl.StyleMusical;
  public textField=GenreEtStyleMusicalTextField;
  //public enumText= EnumText;

  public genreMusicalDataList:GenreMusicalModel[] = [];
  public genreMusicalDataDtoList:GenreMusicalDto[] = [];
  public genreMusicalDataListSelected: GenreMusicalModel[]=[];

  public styleMusicalDataList:StyleMusicalModel[] = [];
  public styleMusicalDataListSelected: StyleMusicalModel[]=[];

  constructor( private apiService: ApiService) {super();}

  getAll() : Observable<any[]>{
    return this.apiService.getData(`${this.endpoint}` );
  }

  getAllPaginate( data: any ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/paginate`, data );
  }

  getOne( id: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }
  saves(  data: any, isFormGenre:boolean ) : Observable<any>{
    if( isFormGenre ){ // Genre
      return this.apiService.postData(`${this.endpoint}`, data, );
    }// Style
    return this.apiService.postData(`${this.endpointStyleMusical}`, data, );
  }

  delete( id: string ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` );
  }

  async loadDataGenreMusical()  {
    if(this.genreMusicalDataList?.length<=0){
      this.subs.sink = this.getAll().subscribe({
        next: (result) => {
          this.genreMusicalDataList = result;
          //this.genreMusicalDataList.unshift(genreOuStyleMusicalVIDE);
          //console.log(`this.genreMusicalDataList:`, this.genreMusicalDataList);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
        }
      });
    }
  }

}
