import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-title-brand',
  standalone: true,
  imports: [
    RouterLink,

  ],
  templateUrl: './title-brand.component.html',
  styleUrl: './title-brand.component.scss'
})
export class TitleBrandComponent {
  @Input() title: string = '';
  @Input() titleIcon: string = 'fa fa-star';
  @Input() classDiv: string = '';

  @Input() linkSeeMore: string = '';
  @Input() textSeeMore: string = 'View All';
}
