import { Injectable, OnDestroy } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AuthorizationService } from '../authorization/authorization.service';
import { KeyValueModel } from '@core/key-value-model';
import { DiagrammeNoteComponent } from '@shared/components/diagramme-note/diagramme-note.component';
import { LangueModel } from '@core/models/langue.model';
import { EnumStatusPublish } from '@shared/tools/enums/enum-status.enum';

@Injectable({
  providedIn: 'root'
})
export class CommonService implements OnDestroy {

  public langues: LangueModel[]=[];

  constructor(
    public confirmationService: ConfirmationService,
    public dynamicDialogRef: DynamicDialogRef,
    public dialogService: DialogService,
    public messageService: MessageService,
    public authorizationService: AuthorizationService,
  ) { }

  ngOnDestroy(): void {
      this.dynamicDialogRef?.close();
  }

  showPopUpFile(keyValue: KeyValueModel){
    if (keyValue.pathUrl!=='') {
      let data = {
        keyValue: keyValue,
      };
      const ref = this.dialogService.open(DiagrammeNoteComponent, {
          header: keyValue.title,
          contentStyle: {"overflow":"auto"},
          //baseZIndex: 10000,
          width: '100%',
          height: '100%',
          data: data
      });

      ref.onClose.subscribe(( result ) => {
          if (result) {
            if( result.isReloadPage ){
              //this.loadData();
            }
          }
      });
    }
  }
  extractVideoId(url: string): string | null {
    const regex = /[?&]v=([^&]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  fetch(linkData:string, cb: (i: any[]) => void) {
    const req = new XMLHttpRequest();
    req.open('GET', linkData);
    req.onload = () => {
      const data = JSON.parse(req.response);
      cb(data);
    };
    req.send();
  }

  onPlayerReady(event: any) {
    console.log('Player is ready', event);
  }

  onStateChange(event: any) {
    console.log('Player state changed', event);
  }

  onError(event: any) {
    console.log('Player encountered an error', event);
  }

  check(data: boolean){
    return data?'fas fa-check-circle':'far fa-times-circle';
  }
  badgeCS(data: boolean){
    return data?'badge bg-green cp':'badge bg-red cp';
  }
  iconStatus(data: number){
    switch (data) {
      case EnumStatusPublish.Public:
        return 'fa fa-globe';
      case EnumStatusPublish.Private:
        return 'fa fa-lock';
      default:
        return 'fa fa-lock';
    }
  }
  labelStatus(data: number){
    switch (data) {
      case EnumStatusPublish.Public:
        return 'VISIBLE_TO_ANYONE';
      case EnumStatusPublish.Private:
        return 'VISIBLE_TO_ONLY_ME';
      default:
        return 'VISIBLE_TO_ONLY_ME';
    }
  }
  badgeStatus(data: number){
    switch (data) {
      case EnumStatusPublish.Public:
        return 'badge bg-green cp';
      case EnumStatusPublish.Private:
        return 'badge bg-orange cp ';
      default:
        return 'badge bg-orange cp';
    }
  }
}
