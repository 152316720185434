import { EMPTY_GUID } from "@shared/tools/constants/constants";
import { ChantDesperanceModel } from '@core/models/ce/chant-desperance-model';

export class AlbumGroupeModel {
  id: string= EMPTY_GUID;
  userId: string='';
  nom: string='All Albums';
  annee: string='';
  performedBy: string='';
  descriptions: string='';
  bannerName: string='';
  extention: string='';
  contentType: string='';
  size: string='';

  bannerPath: string='./assets/images/no_image_available.png';
  bannerOrNoImagePathUrl: string='./assets/images/no_image_available.png';
  bannerPathUrl: string='';

  nbrChant: number=0;
  nbrChantV2: number=0;
  dateCreatedStr: string='';
  dateModifStr: string='';

  chantDesperances: ChantDesperanceModel[] =[];
}

export enum AlbumGroupeTextField {
  title= 'Group/Album\'s',
  id= 'Code',
  userId= 'User',
  nom= 'Album\'s Name',
  annee= 'Year',
  performedBy= 'Performed By',
  descriptions= 'Descriptions',
  bannerName='Banner Name',
  extention='',
  contentType='',
  size='',

  bannerPath='Album\'s picture',
}

// export const albumGroupeVIDEOLD = {
//   id: '',
//   nom: 'All Albums',
//   userId: '',
//   annee: '',
//   performedBy: '',
//   descriptions: '',
//   bannerName: '',
//   extention: '',
//   contentType: '',
//   size: '',
//   bannerPath: './assets/images/no_image_available.png',

//   bannerOrNoImagePathUrl: './assets/images/no_image_available.png',
//   bannerPathUrl: '',

//   nbrChant: 0,
//   nbrChantV2: 0,
//   dateCreatedStr: '',
//   dateModifStr: '',
// }
