import { MenuLink } from '@shared/tools/enums/enum-router';
import { PermissionCode } from '@shared/tools/enums/permission';
import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: '-- Main',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    submenu: [],
    permission: ''//PermissionCode.NoCheck,
  },
  {
    path: '/dashboard/main',
    title: 'Home',
    moduleName: '',
    iconType: 'material-icons-outlined',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    permission: PermissionCode.NoCheck,
  },
  {
    path: MenuLink.GroupeCURoot,
    title: 'Group / Albums',
    moduleName: 'security',
    iconType: 'material-icons-outlined',
    icon: 'streetview',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    permission: PermissionCode.ManageListGroupeCU,
  },
  {
    path: MenuLink.ChantUserRoot,
    title: 'Song / Lyrics',
    moduleName: '',
    iconType: 'material-icons-outlined',
    icon: 'class',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    permission: PermissionCode.ManageListChantUser,
  },
  {
    path: MenuLink.ChantSharedWithMeRoot,
    title: 'Shared with me',
    moduleName: '',
    iconType: 'material-icons-outlined',
    icon: 'group',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    permission: PermissionCode.ManageListChantUser,
  },
  {
    path: '',
    title: 'Admin Lyrics',
    moduleName: 'security',
    iconType: 'material-icons-two-tone',
    icon: 'security',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    permission: [
      PermissionCode.ManageListChantDesperance,
      PermissionCode.ManageListCategorieCE,
      PermissionCode.ManageListGroupeCE,
      PermissionCode.ManageListCorrectionCE,
    ],
    submenu: [
      {
        path: MenuLink.ChantDesperanceRoot,
        title: 'Chant d\'Esperance',
        moduleName: 'ChantDesperance',
        iconType: '',
        icon: 'supervised_user_circle',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageListChantDesperance,
      },
      {
        path: MenuLink.GroupeCEList,
        title: 'Groupe',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageListGroupeCE,
      },
      {
        path: MenuLink.CategoryCUList,
        title: 'Category',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageListCategorieCU,
      },
      {
        path: MenuLink.CategoryCEList,
        title: 'Category',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageListCategorieCE,
      },
      {
        path: MenuLink.CorrectionCEList,
        title: 'Correction',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageListCorrectionCE,
      },

  {
    path: MenuLink.ArtistList,
    title: 'Artist',
    moduleName: '',
    iconType: 'material-icons-outlined',
    icon: 'security',
    class: 'ml-menu',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    permission: PermissionCode.ManageListChantUserAdmin,
  },
  {
    path: MenuLink.GenreMusicalList,
    title: 'Genre Musical',
    moduleName: '',
    iconType: 'material-icons-outlined',
    icon: 'security',
    class: 'ml-menu',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    permission: PermissionCode.ManageListChantUserAdmin,
  },
    ],
  },
  {
    path: '',
    title: 'Security',
    moduleName: 'security',
    iconType: 'material-icons-two-tone',
    icon: 'security',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-orange sidebar-badge float-end',
    permission: [
      PermissionCode.ManageUsers,
      PermissionCode.ManageRoles,
      PermissionCode.ManageGroupPermissions,
      PermissionCode.ManagePermissions,
      PermissionCode.ManageRolesPermissions,
    ],
    submenu: [
      {
        path: MenuLink.Users,
        title: 'Users',
        moduleName: 'security',
        iconType: '',
        icon: 'supervised_user_circle',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageUsers,
      },
      /*{
        path: MenuLink.UsersConnected,
        title: 'Users Connected',
        moduleName: 'security',
        iconType: '',
        icon: 'supervised_user_circle',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageUsersConnected,
      },*/
      {
        path: MenuLink.Roles,
        title: 'Roles',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageRoles,
      },
      {
        path: MenuLink.GroupPermissions,
        title: 'Group Permissions',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageGroupPermissions,
      },
      {
        path: MenuLink.Permissions,
        title: 'Permissions',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManagePermissions,
      },
      {
        path: MenuLink.RolesPermissions,
        title: 'RolePermissions',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.ManageRolesPermissions,
      },
      {
        path: MenuLink.Settings,
        title: 'Settings',
        moduleName: 'security',
        iconType: '',
        icon: 'security',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        submenu: [],
        permission: PermissionCode.MenuSettings,
      },
    ],
  },
];
