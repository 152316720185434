export class CategoryCeModel {
  public code: string='';
  public nom: string='';

}
export enum CategoryCeTextField {
  title= 'Category CE',
  code= 'Code',
  nom= 'Name',
}

export const categoryCeVIDE = {
  code: '',
  nom: 'All Category',
}
