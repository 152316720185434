import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { GlobalService } from '../global.service';
import { SessionAuthService } from '../session-auth/session-auth.service';
import { delay } from 'bluebird';
import { InfoService } from '../info/info.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  log: string = '';
  httpHeadersOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    }),
    params: new HttpParams()
  };

  constructor(
    public httpClient: HttpClient,
  ) {}

  public getApiPath() : string{
    return `${environment.serverPathApi}api/`;
  }

  getApiInfoVersion(endPoint: string, params?: HttpParams): Observable<any>  {
    let url = `${this.getApiPath()}${endPoint}`
    if(params!=null){
      this.httpHeadersOptions.params = params;
      this.log = `PARAMS: ${JSON.stringify(params)}`;
    }
    console.log(`URL: [GET]:.::. ${url} ${this.log}`);
    return this.httpClient.get<any>(`${url}`, this.httpHeadersOptions);
  }

  getData(endPoint: string, params?: HttpParams): Observable<any>  {
    let url = `${this.getApiPath()}${endPoint}`
    if(params!=null){
      this.httpHeadersOptions.params = params;
      this.log = `PARAMS: ${JSON.stringify(params)}`;
    }
    console.log(`URL: [GET]:.::. ${url} ${this.log}`);
    //console.log(`getData :: BEGIN ..::.. GET`);
    return this.httpClient.get<any>(`${url}`, this.httpHeadersOptions);
  }

  postData(endPoint: string, data: any=null): Observable<any>  {
    let url = `${this.getApiPath()}${endPoint}`
      console.log(`URL: [POST]:.::. ${url}`);
    if( data == null ){
      return this.httpClient.post<any>(`${url}`, this.httpHeadersOptions);
    }
    return this.httpClient.post<any>(`${url}`, data, this.httpHeadersOptions);
  }

  patchData(endPoint: string, data: any=null): Observable<any>  {
    let url = `${this.getApiPath()}${endPoint}`
    console.log(`URL: [PATCH]:.::. ${url}`);
    if( data == null ){
      return this.httpClient.patch<any>(`${url}`, this.httpHeadersOptions);
    }
    return this.httpClient.patch<any>(`${url}`, data, this.httpHeadersOptions);
  }

  putData(endPoint: string, data: any=null): Observable<any>  {
    let url = `${this.getApiPath()}${endPoint}`
    console.log(`URL: [PUT]:.::. ${url}`);
    if( data == null ){
      return this.httpClient.put<any>(`${url}`, this.httpHeadersOptions);
    }
    return this.httpClient.put<any>(`${url}`, data, this.httpHeadersOptions);
  }

  deleteData(endPoint: string): Observable<any>  {
    let url = `${this.getApiPath()}${endPoint}`
    console.log(`URL: [DELETE]:.::. ${url}`);
    return this.httpClient.delete<any>(`${url}`, this.httpHeadersOptions);
  }

  getToPromise(endPoint: string): Promise<any> {
    let url = `${this.getApiPath()}${endPoint}`
    console.log(`URL: [GET]:.::. (getToPromise) ${url}`);
    return this.httpClient.get<any>(`${url}`).toPromise();
  }

}
