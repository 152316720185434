<div class="form-section">
  <div class="auth-wrapper">
    <div class="tc">
      <img src="assets/images/lyrics_chant_620x529_exp.png" style="width: 125px;" />
    </div>
    <h4 class="welcome-msg tc"> Welcome to {{ 'app_name' | translate }}</h4>
    <!-- <p class="auth-signup-text text-muted">Need an account?<a (click)="signup()"
      class="sign-up-link cp">Sign Up
    </a></p> -->
    <!-- <h2 class="login-title">Sign in</h2> -->
      @if (error) {
        <div class="alert alert-danger">{{error}}</div>
      }
    <form class="validate-form" [formGroup]="authForm" (ngSubmit)="onSubmit()"  >
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" />
            <mat-icon matSuffix class="face-icon">face</mat-icon>
            @if (authForm.get('username')?.hasError('required')) {
              <mat-error>
                Username is required
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12col-lg-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
              <a class="show-pwd-icon" href="#" onClick="return false;" matSuffix (click)="hide = !hide"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </a>
              @if (authForm.get('password')?.hasError('required')) {
                <mat-error>
                  Password is required
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" value=""> Remember me
                <span class="form-check-sign">
                  <span class="check"></span>
                </span>
              </label>
            </div>
            <a class="txt1" routerLink="/authentication/forgot-password">Forgot Password?</a>
          </div>
          <div class="container-auth-form-btn">
            <div style="text-align:center">
              <button mat-raised-button color="primary" class="auth-form-btn" [disabled]="!authForm.valid "
              type="submit">Login</button>
            </div>
          </div>
        </form>
        <h6 class="social-login-title">OR</h6>
        <ul class="list-unstyled social-icon mb-0 mt-3">
          <li class="list-inline-item">
            <button mat-stroked-button formnovalidate (click)="loginWithGoogleTS()" class="stroked-btn"><i class="fab fa-google"></i> Connect with Google</button>
          </li>
          <!-- <li class="list-inline-item">
            <button mat-stroked-button (click)="loginWithGoogleTS()" class="stroked-btn"><i class="fa fa-email"></i> Connect with Email</button>
          </li> -->
          <!-- <li class="list-inline-item"><a href="javascript:void(0)" class="rounded flex-c-m">
            <i class="fab fa-facebook-f"></i>
          </a></li>
          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
            <i class="fab fa-twitter"></i>
          </a></li>
          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
            <i class="fab fa-linkedin-in"></i>
          </a></li> -->
        </ul>
        <div class="tc mt-3">
          <a mat-stroked-button href="/" class="stroked-btn"><i class="fa fa-home"></i> Home</a>
        </div>
        <div class="text-center p-t-10 p-b-20">
          <span style="font-size: 10px;">Ver. {{globalService.appInfo.PackageVersion}}</span> |
          <span style="font-size: 10px;">{{infoService.apiVersion}}</span>
        </div>
  </div>
</div>
