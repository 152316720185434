import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbV2Component } from '../breadcrumb-v2/breadcrumb-v2.component';
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component';
import { ValidatorErrorComponent } from '../validator-error/validator-error.component';

@Component({
  selector: 'app-tools-button-save',
  standalone: true,
  imports: [
    BreadcrumbV2Component, ValidatorErrorComponent, MessagesModule, ConfirmDialogModule, ToastModule, TabViewModule,TableModule,
    MatTableModule,
    IconFieldModule, InputTextModule, InputIconModule, MultiSelectModule, DropdownModule,
    FeatherIconsComponent,
    MatButtonModule,
    MatIconModule,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatDialogClose,
    InputGroupModule,
    InputGroupAddonModule,
    CommonModule,
    TooltipModule,
  ],
  templateUrl: './tools-button-save.component.html',
  styleUrl: './tools-button-save.component.scss'
})
export class ToolsButtonSaveComponent {
  enumText = EnumText;
  @Input() class: String="tl";

  @Input() showBtnCancel: boolean=false;

  @Input() addOrEditButton: boolean=false;
  @Input() textBtnSaveChanges: String=EnumText.SaveChanges;
  @Input() iconBtnSaveChanges: String='fa fa-save';

  @Output() eventOnCloseEmitter = new EventEmitter<any>();

  @Input() formGroupPageName: any;

  disabledBtnSaveChanges() : boolean {
    return this.formGroupPageName?.invalid ?? true;
  }
  onClose(){
    this.eventOnCloseEmitter.emit();
  }
}
