import { Injectable } from '@angular/core';
import { GroupeChantantDto, GroupeChantantModel, GroupeChantantTextField, groupeChantantVIDE } from '@core/models/ce/groupe-chantant-model';
import { ApiService } from '@core/service/api/api.service';
import { GlobalService } from '@core/service/global.service';
import { InfoService } from '@core/service/info/info.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupeChantantService extends UnsubscribeOnDestroyAdapter {

  endpoint: string = ApiUrl.GroupeChantant;
  public textField=GroupeChantantTextField;
  public groupeCEdto = new GroupeChantantDto();
  public isShowCEEBV:boolean=false;
  public groupeDataList: GroupeChantantModel[] = [];

  public loadingGroupeChantantDataList=false;
  public groupeChantantDataList: GroupeChantantModel[] | any[]  = [];

  constructor(
    private apiService: ApiService,
    public globalService: GlobalService,
    public infoService: InfoService
  ) {super();}

  // notifyGroupeCEdto(): void {
  //   this.groupeCESubject.next( this.groupeCEdto );
  // }

  getAll() : Observable<any[]>{
    return this.apiService.getData(`${this.endpoint}` );
  }
  getAllPublic() : Observable<any>{
    return this.apiService.getData(`${this.endpoint}/public` );
  }

  getOne( id: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }

  delete( id: string ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` );
  }

  async loadDataGroupeChantant()  {
    if(this.groupeChantantDataList.length>0){
      //console.log('Allready loadDataGroupeChantant:', this.groupeChantantDataList );
      //this.globalService.showLoader(false);
      return;
    }
    if(this.groupeChantantDataList?.length<=0){
      this.loadingGroupeChantantDataList=true;
      this.subs.sink = await this.getAll().subscribe({
        next: (result) => {
          //this.groupeDataList.push(groupeCeVIDE)
          this.groupeChantantDataList = result;
          this.groupeChantantDataList.unshift(groupeChantantVIDE);
          this.loadingGroupeChantantDataList=false;
        },
        error: (ex) => {
          console.log(ex);
          this.loadingGroupeChantantDataList=false;
        },
        complete: () => {
          this.loadingGroupeChantantDataList=false;
        }
      });
    }
  }

  async loadGroupeCeHomeData()  {
    console.log('loadGroupeCeHomeData :: DEBUT' );
    this.isLoading=true;
    this.loadingGroupeChantantDataList=true;
    //this.globalService.showLoader();
    if(this.groupeDataList.length>0){
      console.log('Allready loadGroupeCeHomeData:', this.groupeDataList );
      //this.globalService.showLoader(false);
      return;
    }
    await this.infoService.autoAuthenticate();
    console.log('loadGroupeCeHomeData :: AFTER - autoAuthenticate :' );
    this.subs.sink = await this.getAll().subscribe({
        next: (result) => {
          this.groupeDataList = result.filter((x)=> x.isShow==true);
          this.loadingGroupeChantantDataList=false;
        },
        error: (ex) => {
          console.log('loadGroupeCeHomeData :: error', ex);
          this.loadingGroupeChantantDataList=false;
          //this.globalService.showLoader(false);
        },
        complete: () => {
          console.log('loadGroupeCeHomeData :: complete');
          this.loadingGroupeChantantDataList=false;
          //this.globalService.showLoader(false);
        }
      });
  }

  async loadGroupeCEPublicData()  {
    //console.log('loadGroupeCEPublicData :: DEBUT - autoAuthenticate :' );
    this.isLoading=true;
    this.globalService.showLoader();
    if(this.groupeCEdto.groupeCEEBVList.length>0){
      //console.log('Allready loadGroupeCEData:', this.groupeCEdto );
      this.globalService.showLoader(false);
      return;
    }

    await this.infoService.autoAuthenticate();
    //console.log('loadGroupeCEPublicData :: AFTER - autoAuthenticate :' );

    this.subs.sink = await this.getAllPublic().subscribe({
        next: (result) => {
          this.groupeCEdto = result;
          // this.notifyGroupeCEdto();
          //console.log(`loadGroupeCEPublicData :: next`,this.groupeCEdto);
          this.globalService.showLoader(false);
        },
        error: (ex) => {
          this.isLoading=false;
          this.globalService.showLoader(false);
          //console.log(`oadGroupeCEPublicData :: error`, ex);
        },
        complete: () => {
          this.isLoading=false;
          this.globalService.showLoader(false);
          //console.log(`oadGroupeCEPublicData :: complete`);
        }
    });
    //console.log('loadGroupeCEPublicData :: FIN' );
    this.globalService.showLoader(false);
  }

}
