<ul class="breadcrumb breadcrumb-style ">
  <li class="breadcrumb-item">
    <h4 class="page-title">{{titlePage}} <span class="badge bg-orange">{{count}}</span></h4>
  </li>
  @for (item of menuItems; track $index; ) {
    <li class="breadcrumb-item bcrumb-1">
      <a [routerLink]="($index===0)? home.routerLink : item.routerLink">
        @if( $index===0){
          <i class="fas fa-home"></i>
        } &nbsp;
        <span>{{ item.label }}</span>
      </a>
    </li>
  }
</ul>
