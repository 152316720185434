import { DatePipe } from "@angular/common";

//var date = new Date();
// var transformDate = new DatePipe().transform(date, 'yyyy-HHdd-mms');
export enum AppInfo {
  Sigle= 'CCG',
  AppName= 'Chant Lyrics',
  AppLogoPath= './assets/images/gstock.png',
  AppLogoSiglePath= './assets/images/gstock_lg.png',
  CLientLogoPath= './assets/images/logo.png',
  CLientName= 'National Windows and Doors',
  CLientAdress= 'Delmas 19, Rue Anselmne #3',
  CLientPhone= '+50948052293',
  CLientEmail= 'nwd.info@gmail.com',
  Pays= 'Haiti',
  Ville= 'Port-au-Prince',
  CodePostal= 'Ht6110',
  Copyright= '',
  PackageVersion= '1.0.0',
  Annee= `2015 - `,
  //Annee2 = '2015 - ' + new Date().getFullYear().toString(),
  BrainDevWebSite= 'https://www.brain-dev.net',
}
