import { Injectable } from '@angular/core';
import { CategoryCeTextField } from '@core/models/ce/category-ce-model';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { GenreEtStyleMusicalTextField } from '@core/models/ce/chant-genre-style-model';

@Injectable({
  providedIn: 'root'
})
export class StyleMusicalService {

  endpoint: string = ApiUrl.StyleMusical;
  public textField=GenreEtStyleMusicalTextField;
  public enumText= EnumText;

  constructor( private apiService: ApiService) {}

  getAll() : Observable<any[]>{
    return this.apiService.getData(`${this.endpoint}` );
  }

  getAllPaginate( data: any ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/paginate`, data );
  }

  getAllByGenreMusical( genreMusicalId: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/genre/${genreMusicalId}` );
  }

  getOne( id: string ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }

  delete( id: string ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` );
  }
}
