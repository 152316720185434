import { EnumStatus, EnumStatusPublish } from "@shared/tools/enums/enum-status.enum";
import { ArtistModel } from "./artist-model";
import { ChantArtistModel, ChantGenreMusicalModel, ChantStyleMusicalModel, GenreMusicalModel, StyleMusicalDto, StyleMusicalModel } from "../ce/chant-genre-style-model";
import { AlbumGroupeModel } from "../ce/album_groupe-model";
import { ChantDesperanceModel, ChantDesperanceTextField } from "../ce/chant-desperance-model";

export class ChantUserModel extends ChantDesperanceModel {
}

export enum ChantUserTextField {
  title='Chant/Lyric',
  title1='Chant/Lyric',
  position='#track',
  titre ='title' ,
  contenu ='contenu' ,
  auteurChant ='auteur' ,
  typeChant ="Langue/Version/Type" ,
  groupeChantUserId ='groupe' ,
  albumGroupeId ='Album/Groupe' ,
  categorieChantId ='Categorie' ,
  isChantChoeur = 'isChoeur' ,
  audioPath ='audioPath' ,
  contentTypeAudio ='contentTypeAudio' ,
  sizeAudio ='sizeAudio' ,
  artistAudio ='artist Audio' ,
  albumAudio ='album Audio' ,
  genreAudio ='genre Audio' ,
  dureeAudio ='duree Audio' ,
  isHaveOtherAudio = 'isHaveOtherAudio' ,
  audioInstrumentPath ='audioInstrumentPath' ,
  contentTypeAudioInstrument ='contentTypeAudioInstrument' ,
  sizeAudioInstrument ='sizeAudioInstrument' ,
  dureeAudioInstrument ='dureeAudioInstrument' ,
  diagrammeNotePath ='File / Link Music Theory' ,
  contentTypeDiagrammeNote ='contentTypeDiagrammeNote' ,
  sizeDiagrammeNote ='sizeDiagrammeNote' ,
  videoLink ='label_linkVideo' ,

  id ='id' ,
  // ... //
  versionCode ='ver' ,
  nbrOfViews ='nbrOfViews' ,
  nbrOfLikes ='nbrOfLikes' ,
  nbrOfFavoris ='nbrOfFavoris' ,
  isUpdate= 'isUpdate' ,
  // ... //
  createdBy ='createdBy' ,
  dateCreated ='dateCreated' ,
  dateModif ='dateModif' ,

  contenuUnicode ='contenuUnicode' ,
  contenuHtml ='contenuHtml' ,

  isUpdateLocale = 'isUpdateLocale' ,
  isAudioLocal = 'isAudioLocal' ,
  isAudioInstrumentLocal = 'isAudioInstrumentLocal' ,
  isDiagrammeNoteLocal = 'isDiagrammeNoteLocal' ,
  isSyncToServerOnline = 'isSyncToServerOnline' ,

  categorieChant= 'category' ,
  groupeChantant= 'groupe' ,

  nbrLike ='nbrLike' ,
  nbrDisLike ='nbrDisLike' ,
  nbrAudio ='nbrAudio' ,
  isHasAudio='isHasAudio' ,
  nbrDiagrammeNote ='nbrDiagrammeNote' ,
  isHasDiagrammeNote='isHasDiagrammeNote' ,
  dateCreatedStr ='dateCreated' ,
  declareateModifStr ='declareateModif' ,
  audioPathUrl ='audioPathUrl' ,
  audioInstrumentPathUrl ='audioInstrumentPathUrl' ,

  allAudioPathUrl='allAudioPathUrl',
  diagrammeNotePathUrl ='diagrammeNotePathUrl' ,
  allDiagrammeNotePathUrl ='allDiagrammeNotePathUrl',
  descriptions ='descriptions' ,
}
