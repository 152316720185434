import { Injectable, OnDestroy } from '@angular/core';
import { ChantDesperanceTextField } from '@core/models/ce/chant-desperance-model';
import { ChantUserModel } from '@core/models/cu/chant-user-model';
import { ChantFilterModel } from '@core/models/filter-search';
import { ApiService } from '@core/service/api/api.service';
import { GlobalService } from '@core/service/global.service';
import { InfoService } from '@core/service/info/info.service';
import { SessionAuthService } from '@core/service/session-auth/session-auth.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { GLOBAL_SESSION_ALIGN_POSITION, GLOBAL_SESSION_CHANT_LIST, GLOBAL_SESSION_CHANT_SELECTED, GLOBAL_SESSION_CHANT_U_LIST, GLOBAL_SESSION_CHANT_U_SELECTED, GLOBAL_SESSION_FONT_SIZE, GLOBAL_SESSION_GROUPE_ID, GLOBAL_SESSION_GROUPE_U_ID, GLOBAL_SESSION_TYPE_CHANT, GLOBAL_SESSION_TYPE_U_CHANT } from '@shared/tools/constants/constants';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { EnumAction } from '@shared/tools/enums/enum-status.enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChantUserService extends UnsubscribeOnDestroyAdapter {


  endpoint: string = ApiUrl.ChantUser;
  endpointPublic: string = ApiUrl.ChantUserPublic;
  endpointChantUserShare: string = ApiUrl.ChantUserShare;
  public textField=ChantDesperanceTextField;
  isPopularLyricsDataLoad=true;
  popularChantUserList: ChantUserModel[]=[];
  popularChantUserListBL: ChantUserModel[]=[];
  isLatestLyricsDataLoad=true;
  latestChantUserList: ChantUserModel[]=[];
  latestChantUserListBL: ChantUserModel[]=[];
  isChantUserPublicLoad=true;
  chantUserPublicList: ChantUserModel[]=[];
  //public enumText= EnumText;

  constructor( private apiService: ApiService, private infoService: InfoService,
    public globalService: GlobalService,) { super(); }

  //region [ SESSION ]
  get sessionFontSize(): number {
    let size = this.infoService.sessionAuthService.getItemVar(GLOBAL_SESSION_FONT_SIZE);
    return size==''?15:size;
  }
  set sessionFontSize(value: number) {
    this.infoService.sessionAuthService.setItem(GLOBAL_SESSION_FONT_SIZE, value);
  }

  get sessionAlignPosition(): string {
    let al = this.infoService.sessionAuthService.getItemVar(GLOBAL_SESSION_ALIGN_POSITION);
    return al==''?'tc':al;
  }
  set sessionAlignPosition(value: any) {
    this.infoService.sessionAuthService.setItem(GLOBAL_SESSION_ALIGN_POSITION, value);
  }

  get sessionChantUserSelected(): ChantUserModel {
    return this.infoService.sessionAuthService.getItem(GLOBAL_SESSION_CHANT_U_SELECTED)?? new ChantUserModel();
  }
  set sessionChantUserSelected(value: ChantUserModel) {
    this.infoService.sessionAuthService.setItem(GLOBAL_SESSION_CHANT_U_SELECTED, JSON.stringify(value));
  }

  removeSessionUserSelected() {
    this.infoService.sessionAuthService.removeItem(GLOBAL_SESSION_CHANT_U_SELECTED);
  }

  get sessionChantUserCurrentList(): ChantUserModel[] {
    return this.infoService.sessionAuthService.getItem(GLOBAL_SESSION_CHANT_U_LIST)??[];
  }
  set sessionChantUserCurrentList(value: ChantUserModel[]) {
    this.infoService.sessionAuthService.setItem(GLOBAL_SESSION_CHANT_U_LIST, JSON.stringify(value));
  }
  removeSessionChantUserCurrentList() {
    this.infoService.sessionAuthService.removeItem(GLOBAL_SESSION_CHANT_U_LIST);
  }

  get sessionGroupeId(): string | number{
    return this.infoService.sessionAuthService.getItemVar(GLOBAL_SESSION_GROUPE_U_ID)??0;
  }
  set sessionGroupeId(value: string|number) {
    this.infoService.sessionAuthService.setItem(GLOBAL_SESSION_GROUPE_U_ID, String(value));
  }
  removeSessionGroupeId() {
    this.infoService.sessionAuthService.removeItem(GLOBAL_SESSION_GROUPE_U_ID);
  }

  get sessionTypeChant(): string {
    return this.infoService.sessionAuthService.getItemVar(GLOBAL_SESSION_TYPE_U_CHANT)??'';
  }
  set sessionTypeChant(value: string) {
    this.infoService.sessionAuthService.setItem(GLOBAL_SESSION_TYPE_U_CHANT, value);
  }
  removeSessionTypeChant() {
    this.infoService.sessionAuthService.removeItem(GLOBAL_SESSION_TYPE_U_CHANT);
  }
  //endregion

  getAll(data?: any) : Observable<any>{
    return this.apiService.getData(`${this.endpoint}`, data );
  }
  getAllByCritere(data?: any) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}/paginate`, data );
  }
  getAllBySearchCritere(data?: any) : Observable<any>{
    return this.apiService.postData(`${this.endpointPublic}/paginate`, data );
  }
  getAllPopularLyrics(data?: any) : Observable<any>{
    return this.apiService.getData(`${this.endpointPublic}/popular-lyrics`, data );
  }
  getAllLatestLyrics(data?: any) : Observable<any>{
    return this.apiService.getData(`${this.endpointPublic}/latest-lyrics`, data );
  }
  getLastId(groupeId?: any) : Observable<any>{
    return this.apiService.getData(`${this.endpoint}/lastId/${groupeId}` );
  }

  getShareWithMePaginate(username: String, nbrRecordPerPage: number, page: number) : Observable<any>{
    return this.apiService.getData(`${this.endpointChantUserShare}/share-with-me/${username}/${nbrRecordPerPage}${page > 0 ? `?page=${page}` : ``}` );
  }

  getOne( id: any ) : Observable<any> {
    return this.apiService.getData(`${this.endpoint}/${id}` );
  }

  insert( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }

  updateCUView(  id: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/UpdateCEView/${id}`, );
  }
  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }
  save( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}`, data, );
  }
  saves( data: any ) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}/saves`, data, );
  }
  saveData( isNew: boolean, data: any ) : Observable<any>{
    console.log(`saves:`,data);
    if( isNew ){
      return this.insert(data);
    }
    return this.update(data);
  }

  delete( id: any ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}` );
  }
  deleteByUser( id: any, username: any ) : Observable<any>{
    return this.apiService.deleteData(`${this.endpoint}/${id}/${username}` );
  }

  deleteFile( id: any, enumAction: EnumAction ) : Observable<any>{
    if( EnumAction.AUDIO==enumAction ){
      return this.apiService.patchData(`${this.endpoint}?id=${id}&isAudio=true` );
    }
    return this.apiService.patchData(`${this.endpoint}?id=${id}&isAudio=false` );
  }

  validate(username: String, noChant: number) : Observable<any>{
    return this.apiService.patchData(`${this.endpointChantUserShare}/validate/${username}/${noChant}` );
  }
  reject(username: String, noChant: number) : Observable<any>{
    return this.apiService.patchData(`${this.endpointChantUserShare}/reject/${username}/${noChant}` );
  }
  remove(username: String, noChant: number, userIdDestination: any) : Observable<any>{
    return this.apiService.deleteData(`${this.endpointChantUserShare}/remove/${noChant}/${username}/${userIdDestination}` );
  }

  async LaodAllPublicBySearchCritere111(filterPublic: any)  {
    this.isChantUserPublicLoad=true;
    this.isLoading=true;
    this.globalService.isLoading=true;
    this.globalService.showLoader();
    // if(this.chantUserPublicList.length>0){
    //   this.isChantUserPublicLoad=false;
    //   return;
    // }
    await this.infoService.autoAuthenticate();
    this.subs.sink = await this.getAllBySearchCritere(filterPublic).subscribe({
        next: (result) => {
          this.pagination = result;
          if(this.loadfirst){
            this.pageEvent=this.pagination.pageEvent;
            this.loadfirst=false;
          }
          this.chantUserPublicList = this.pagination.data;
          console.log(this.pagination);
          console.log(this.chantUserPublicList);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
          this.isLoading=false;
          this.globalService.isLoading=false;
          this.globalService.showLoader(false);
        }
      });
  }

  async loadPopularLyricsData(offset: number=10, isFromBarLaterale:boolean=false)  {
    this.isPopularLyricsDataLoad=true;
    if( isFromBarLaterale ){
      if(this.popularChantUserListBL.length>0){
        this.isPopularLyricsDataLoad=false;
        return;
      }
    }else{
      if(this.popularChantUserList.length>0){
        this.isPopularLyricsDataLoad=false;
        return;
      }
    }
    await this.infoService.autoAuthenticate();
    this.subs.sink = await this.getAllPopularLyrics({ offset: offset, page: 1 }).subscribe({
      next: (result) => {
        if( isFromBarLaterale ){
          this.popularChantUserListBL = result.data;
        //console.log('loadPopularLyricsData :: popularChantUserListBL', this.popularChantUserListBL);
        }else{
          this.popularChantUserList = result.data;
          //console.log('loadPopularLyricsData :: popularChantUserList', this.popularChantUserList);
        }
        this.isPopularLyricsDataLoad=false;
      },
      error: (ex) => {
        console.log('loadPopularLyricsData :: error', ex);
        this.isPopularLyricsDataLoad=false;
      },
      complete: () => {
        this.isPopularLyricsDataLoad=false;
      }
    });
  }

  async loadLatestLyricsData(offset: number=21, isFromBarLaterale:boolean=false)  {
    this.isLatestLyricsDataLoad=true;
    if( isFromBarLaterale ){
      if(this.latestChantUserListBL.length>0){
        this.isLatestLyricsDataLoad=false;
        return;
      }
    }else{
      if(this.latestChantUserList.length>0){
        this.isLatestLyricsDataLoad=false;
        return;
      }
    }
    await this.infoService.autoAuthenticate();
    this.subs.sink = await this.getAllLatestLyrics({ offset: offset, page: 1 }).subscribe({
      next: (result) => {
        if( isFromBarLaterale ){
          this.latestChantUserListBL = result.data;
        //console.log('loadLatestLyricsData :: popularChantUserListBL', this.latestChantUserListBL);
        }else{
          this.latestChantUserList = result.data;
          //console.log('loadLatestLyricsData :: popularChantUserList', this.latestChantUserList);
        }
        this.isLatestLyricsDataLoad=false;
      },
      error: (ex) => {
        console.log('loadLatestLyricsData :: error', ex);
        this.isLatestLyricsDataLoad=false;
      },
      complete: () => {
        this.isLatestLyricsDataLoad=false;
      }
    });
  }

}
