import { PermissionCode } from "@shared/tools/enums/permission";

export class InfoModel {
  public apiVersion!: string;
  public roleCode!: string;
  public permissions!: PermissionCode[];
  public jwtToken: string='';

}

export class ContactModel {
  public name: string='';
  public email: string='';
  public subject: string='';
  public message: string='';
  public infoDevice: string='';
}
export enum ContactTextField {
  name= 'Name',
  email= 'Email',
  subject= 'Subject',
  message= 'Message',
}
