export class SettingsModel
{
  id: string='';
  fcmKey: string='';
  privacyPolicy: string='';
  termsAndConditions: string='';
  isAutoApprove: boolean= false;
}


export enum SettingsTextField {
  title= 'Settings',
  fcmKey= 'FireBase Cloud Msg',
  privacyPolicy= 'Privacy Policy',
  termsAndConditions= 'Terms and Conditions',
  isAutoApprove= 'Auto Approve',
}
