import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { InfoService } from '@core';
import { StyleMusicalModel } from '@core/models/ce/chant-genre-style-model';
import { ChantUserTextField } from '@core/models/cu/chant-user-model';
import { ChantFilterModel } from '@core/models/filter-search';
import { CommonService } from '@core/service/common/common.service';
import { ChantUserService } from '@core/service/cu/chantuser/chantuser.service';
import { GenreMusicalService } from '@core/service/genre-musical/genre-musical.service';
import { GlobalService } from '@core/service/global.service';
import { MediaPlayerService } from '@core/service/media-player/media-player.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { SearchAdvanceDialogItemComponent } from '@shared/components/search-advance-dialog/search-advance-dialog-item.component';
import { ImportALlModule } from '@shared/imports';
import { ID, S } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { EnumFields } from '@shared/tools/enums/enum-status.enum';
import { EnumText } from '@shared/tools/enums/enum-Text';

@Component({
  selector: 'app-panel-search-bar-laterale',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './panel-search-bar-laterale.component.html',
  styleUrl: './panel-search-bar-laterale.component.scss'
})
export class PanelSearchBarLateraleComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  public textField=ChantUserTextField;
  public resultSearch: any;
  //public enumFields=EnumFields;
  @Output() onReloadSearchEventEmitter = new EventEmitter<any>();

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public globalService: GlobalService,
    public commonService: CommonService,
    public chantUserService: ChantUserService,
    public infoService: InfoService,
    public elementRef: ElementRef,
    public matDialog: MatDialog,
    public mediaPlayerService: MediaPlayerService,
    public genreMusicalService: GenreMusicalService,
  ){
    super();
    this.chantFilter=new ChantFilterModel();
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params[ID.toLowerCase()]){
        this.chantFilter.searchStr = params[ID.toLowerCase()];
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if ( params[S.toLowerCase()] ) {
        this.chantFilter.searchStr = params[S];
      }
    });
    //this.loadData();
  }

  public loadData(){
    this.onReloadSearchEventEmitter.emit(this.resultSearch);
  }

  showFormSearchAdvanced() {
    let data = {
      isCU: true,
      isCUPublic: true,
      chantFilter: this.chantFilter,
      //groupeDataList: this.groupeDataList,
      //categoryDataList: this.categoryDataList,

      showKeywordSearch: true,
      showLangue: true,
      showGroupeChantant: false,
      showIsChantChoeur: false,
      showAlbumGroupe: false,
      showCategorieChant: false,
      showGenreEtStyleMusical: true,
    };
    const ref = this.commonService.dialogService.open(SearchAdvanceDialogItemComponent, {
        header: EnumText.SearchCritere,
        contentStyle: {"overflow":"auto"},
        //baseZIndex: 10000,
        width: '60%',
        data: data
    });

    ref.onClose.subscribe(( result ) => {
      this.onResult(result);
    });
  }

  onResult(result: any){
    console.log('result', result);
    if (result) {
      if( result.isReloadPage ){
        this.resultSearch = result;
        this.chantFilterObjDto =result.selected;

        this.chantFilter.searchStr=result.data.searchStr;
        this.chantFilter.typeChant=result.data.typeChant;
        this.chantFilter.isChantChoeur=result.data.isChantChoeur;

        this.chantFilter.albumGroupeId=result.data.albumGroupeId;
        this.chantFilter.groupeChantantId=result.data.groupeChantantId;
        this.chantFilter.categorieChantId=result.data.categorieChantId;

        this.chantFilter.genreMusicalIds=result.data.genreMusicalIds;
        this.chantFilter.styleMusicalIds=result.data.styleMusicalIds;

        this.loadfirst=true;

        //this.onReloadSearchEventEmitter.emit( result );
        this.loadData();
      }
      //if(result.list.categoryDataList){ this.categoryCeService.categoryDataList=result.list.categoryDataList; }
      //if(result.list.groupeDataList){ this.groupeDataList=result.list.groupeDataList; }
    }
  }

  clear(data: number, row: any) {
    switch (data) {
      case EnumFields.SEARCH_STR:
        this.chantFilter.searchStr='';
        break;
      case EnumFields.TYPE_CHANT:
        this.chantFilter.typeChant ='';
        this.chantFilterObjDto.typeChantSelected = undefined;
        break;
      case EnumFields.IS_CHOEUR:
        this.chantFilter.isChantChoeur ='';
        break;
      case EnumFields.GROUPE_CHANTANT:
        this.chantFilter.groupeChantantId ='';
        this.chantFilterObjDto.groupeChantantSelected = undefined;
        break;
      case EnumFields.ALBUM_GROUPE:
        this.chantFilter.albumGroupeId ='';
        this.chantFilterObjDto.albumGroupeSelected = undefined;
        break;
      case EnumFields.CATEGORY:
        this.chantFilter.categorieChantId ='';
        this.chantFilterObjDto.categorieChantSelected = undefined;
        break;
      case EnumFields.GENRE_MUSICAL_ID:
        let index = this.chantFilterObjDto.genreMusicalListSelected?.findIndex(objet => objet.id === row.id)??-1;
        if (index >= 0) {
          this.chantFilterObjDto.genreMusicalListSelected?.splice(index, 1);
        }
        console.log('REMOVE GENRE:', row);
        const nouvelleListe = this.chantFilterObjDto.styleMusicalListSelected?.reduce((acc, objet) => {
          if (objet.genreMusicalId !== row.id) {
              acc.push(objet);
          }
          return acc;
        }, [] as StyleMusicalModel[]);
        this.chantFilterObjDto.styleMusicalListSelected =nouvelleListe;

        this.chantFilter.genreMusicalIds=[];
        this.chantFilter.genreMusicalIds=[];
        this.chantFilterObjDto.genreMusicalListSelected?.forEach((item: any) => {
          this.chantFilter.genreMusicalIds?.push(item.id);
          this.chantFilter.genreMusicalIds?.push(item.id);
        });
        this.chantFilter.styleMusicalIds=[];
        this.chantFilter.styleMusicalIds=[];
        this.chantFilterObjDto.styleMusicalListSelected?.forEach((item: any) => {
          this.chantFilter.styleMusicalIds?.push(item.id);
          this.chantFilter.styleMusicalIds?.push(item.id);
        });

        this.genreMusicalService.genreMusicalDataListSelected=this.chantFilterObjDto.genreMusicalListSelected ?? [];
        this.genreMusicalService.styleMusicalDataListSelected=this.chantFilterObjDto.styleMusicalListSelected ?? [];
        break;
      case EnumFields.STYLE_MUSICAL_ID:
        let index1 = this.chantFilterObjDto.styleMusicalListSelected?.findIndex(objet => objet.id === row.id)??-1;
        if (index1 >= 0) {
          this.chantFilterObjDto.styleMusicalListSelected?.splice(index1, 1);
        }
        this.genreMusicalService.styleMusicalDataListSelected=this.chantFilterObjDto.styleMusicalListSelected ?? [];
        break;
      default:
        this.searchValue =''
        this.chantFilter.searchStr='';
        this.chantFilter.albumGroupeId ='';
        this.chantFilter.categorieChantId ='';
        this.chantFilter.groupeChantantId ='';
        this.chantFilter.isChantChoeur ='';
        this.chantFilter.typeChant ='';

        this.chantFilter.genreMusicalIds=undefined;
        this.chantFilter.styleMusicalIds=undefined;

        this.chantFilterObjDto.typeChantSelected = undefined;
        this.chantFilterObjDto.groupeChantantSelected = undefined;
        this.chantFilterObjDto.albumGroupeSelected = undefined;
        this.chantFilterObjDto.categorieChantSelected = undefined;
        this.chantFilterObjDto.genreMusicalListSelected=undefined;
        this.chantFilterObjDto.styleMusicalListSelected=undefined;

        console.log('CLEAR:', this.chantFilter);
        this.genreMusicalService.genreMusicalDataListSelected=[];
        this.genreMusicalService.styleMusicalDataListSelected=[];
        break;
    }
    this.loadfirst=true;
    this.loadData();
  }

  clear2(field: number, data: string) {
    switch (field) {
      case this.enumFields.SEARCH_STR:
        this.chantFilter.searchStr='';
        break;
      case this.enumFields.GROUPE_ID:
        this.chantFilter.groupeChantantId ='';
        break;
      case this.enumFields.STYLE_MUSICAL_ID:
        this.chantFilter.styleMusicalIds =[];
        //this.chantFilter.styleMusicalNameDisplay =[];
        break;
      case this.enumFields.GENRE_MUSICAL_ID:
        this.chantFilter.genreMusicalIds =[];
        //this.chantFilter.genreMusicalNameDisplay =[];
        break;
      case this.enumFields.ARTIST_ID:
        this.chantFilter.artistIds =[];
        break;
      case this.enumFields.ARTIST_NAME:
        //this.chantFilter.artistNameDisplay =[];
        break;
      default:
        this.searchValue =''
        this.chantFilter.searchStr='';
        this.chantFilter.groupeChantantId ='';
        this.chantFilter.styleMusicalIds =[];
        // this.chantFilter.styleMusicalNameDisplay =[];
        this.chantFilter.genreMusicalIds =[];
        // this.chantFilter.genreMusicalNameDisplay =[];
        this.chantFilter.artistIds =[];
        // this.chantFilter.artistNameDisplay =[];
        break;
    }
    this.loadData();
    const queryParams = { s: this.chantFilter.searchStr,};
    this.router.navigate([MenuLink.SearchPagePublic ], { queryParams });
  }
  public get initSearch(): boolean{
    return (this.chantFilter.searchStr!=='' && this.chantFilter.searchStr!= undefined)
    || (this.chantFilter.groupeChantantId!=='' && this.chantFilter.groupeChantantId!= undefined)
    || (!this.chantFilter.styleMusicalIds && this.chantFilter.styleMusicalIds!= undefined)
    || (!this.chantFilter.genreMusicalIds && this.chantFilter.genreMusicalIds!= undefined)
    || (!this.chantFilter.artistIds && this.chantFilter.artistIds!= undefined);
  }
  public search(){
    this.loadfirst=true;
    //this.resultSearch = result;

    this.loadData();

    const queryParams = { s: this.chantFilter.searchStr,};
    this.router.navigate([MenuLink.SearchPagePublic ], { queryParams });
  }

}
