export enum PermissionCode {

  MenuSale= 'MenuSale',
  MenuSupply= 'MenuSupply',
  MenuSecurity= 'MenuSecurity',
  MenuSettings= 'MenuSettings',

  NoCheck= 'NoCheck',

  //region [ CATEGORY CE ]
  ManageListCategorieCE= "ManageListCategorieCE",
  AddCategorieCE = "AddCategorieCE",
  EditCategorieCE = "EditCategorieCE",
  DeleteCategorieCE = "DeleteCategorieCE",
  //endregion
// Groupe CE
ManageListGroupeCE = "ManageListGroupeCE",
AddGroupeCE = "AddGroupeCE",
EditGroupeCE = "EditGroupeCE",
DeleteGroupeCE = "DeleteGroupeCE",

// CHANT DESPERANCE
ManageListChantDesperance = "ManageListChantDesperance",
AddChantDesperance = "AddChantDesperance",
EditChantDesperance = "EditChantDesperance",
DeleteChantDesperance = "DeleteChantDesperance",
A4_PRIVILEGE_04_CHOEUR_ADORATION = "4_PRIVILEGE_04_CHOEUR_ADORATION",
A5_PRIVILEGE_05_CHORALE_CENTRALE = "5_PRIVILEGE_05_CHORALE_CENTRALE",
A6_PRIVILEGE_06_CHORALE_SICHEM = "6_PRIVILEGE_06_CHORALE_SICHEM",
A7_PRIVILEGE_07_GROUPE_DES_DAME = "7_PRIVILEGE_07_GROUPE_DES_DAME",
A8_PRIVILEGE_08_GROUPE_LES_SARMENTS = "8_PRIVILEGE_08_GROUPE_LES_SARMENTS",
A9_PRIVILEGE_09_GROUPE_PRAISE_HIM = "9_PRIVILEGE_09_GROUPE_PRAISE_HIM",
A10_PRIVILEGE_10_GROUPE_VOICE_OF_FAITH = "10_PRIVILEGE_10_GROUPE_VOICE_OF_FAITH",
A11_PRIVILEGE_11_GROUPE_GROUV = "11_PRIVILEGE_11_GROUPE_GROUV",
A12_PRIVILEGE_12_CE = "12_PRIVILEGE_12_CE",
A13_PRIVILEGE_13_MJ = "13_PRIVILEGE_13_MJ",
A14_PRIVILEGE_14_RL = "14_PRIVILEGE_14_RL",
A15_PRIVILEGE_15_VR = "15_PRIVILEGE_15_VR",
A16_PRIVILEGE_16_RN = "16_PRIVILEGE_16_RN",
A17_PRIVILEGE_17_EE = "17_PRIVILEGE_17_EE",
A18_PRIVILEGE_18_OR = "18_PRIVILEGE_18_OR",
A19_PRIVILEGE_19_GA = "19_PRIVILEGE_19_GA",
A20_PRIVILEGE_20_RNC = "20_PRIVILEGE_20_RNC",
A21_PRIVILEGE_21_CB = "21_PRIVILEGE_21_CB",
A22_PRIVILEGE_22_CS = "22_PRIVILEGE_22_CS",
A99_PRIVILEGE_99_ChantDelete = "99_PRIVILEGE_99_ChantDelete",

// CHANT DESPERANCE CORRECTION
ManageListCorrectionCE = "ManageListCorrectionCE",
AddCorrectionCE = "AddCorrectionCE",
ValidateCorrectionCE = "ValidateCorrectionCE",
AutoValidationCorrectionCE = "AutoValidationCorrectionCE",

// CATEGORY CU
ManageListCategorieCU = "ManageListCategorieCU",
AddCategorieCU = "AddCategorieCU",
EditCategorieCU = "EditCategorieCU",
DeleteCategorieCU = "DeleteCategorieCU",

// Groupe CU
ManageListGroupeCU = "ManageListGroupeCU",
AddGroupeCU = "AddGroupeCU",
EditGroupeCU = "EditGroupeCU",
DeleteGroupeCU = "DeleteGroupeCU",

// CHANT USER
ManageListChantUser = "ManageListChantUser",
AddChantUser = "AddChantUser",
EditChantUser = "EditChantUser",
DeleteChantUser = "DeleteChantUser",

// CHANT USER ADMIN
ManageListChantUserAdmin = "ManageListChantUserAdmin",
AddChantUserAdmin = "AddChantUserAdmin",
EditChantUserAdmin = "EditChantUserAdmin",
DeleteChantUserAdmin = "DeleteChantUserAdmin",

// USER
// ManageUsers = "ManageListUsers",
// AddUsers = "AddUsers",
// EditUsers = "EditUsers",
// DeleteUsers = "DeleteUsers",
// ManageRoles = "ManageListRoles",
// AddRoles = "AddRoles",
// EditRoles = "EditRoles",
// DeleteRoles = "DeleteRoles",
// ManageGroupPermissions = "ManageListGroupPermissions",
// AddGroupPermissions = "AddGroupPermissions",
// EditGroupPermissions = "EditGroupPermissions",
// DeleteGroupPermissions = "DeleteGroupPermissions",
// ManagePermissions = "ManageListPermissions",
// AddPermissions = "AddPermissions",
// EditPermissions = "EditPermissions",
// DeletePermissions = "DeletePermissions",
// ManageRolesPermissions = "ManageListRolesPermissions",
// AddRolesPermissions = "AddRolesPermissions",
// DeleteRolesPermissions = "DeleteRolesPermissions",
// ManageUsersConnected = "ManageUsersConnected",


  //region [ USER ]
  ManageUsers = "ManageUsers",
  AddUsers = "AddUsers",
  EditUsers =  "EditUsers",
  DeleteUsers =  "DeleteUsers",

  ManageRoles =  "ManageRoles",
  AddRoles = "AddRoles",
  EditRoles =  "EditRoles",
  DeleteRoles = "DeleteRoles",

  ManageGroupPermissions = "ManageGroupPermissions",
  AddGroupPermissions =  "AddGroupPermissions",
  EditGroupPermissions =  "EditGroupPermissions",
  DeleteGroupPermissions = "DeleteGroupPermissions",

  ManagePermissions =  "ManagePermissions",
  AddPermissions =  "AddPermissions",
  EditPermissions = "EditPermissions",
  DeletePermissions =  "DeletePermissions",

  ManageRolesPermissions =  "ManageRolesPermissions",
  AddRolesPermissions =  "AddRolesPermissions",
   //[Description("EditRolesPermissions")]
   //EditRolesPermissions = ,
  DeleteRolesPermissions = "DeleteRolesPermissions",

  ManageUsersConnected =  "ManageUsersConnected",
   //endregion

  ManageByDev= 'ManageByDev-Only',
}
