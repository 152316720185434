import { EnumStatus } from "@shared/tools/enums/enum-status.enum";
import { PermissionCode } from "@shared/tools/enums/permission";

export class UserModel {
  //public id: string;
  public userName: string='';
  public password: string='';
  public role: string='';
  public roleStr: string='';
  public computerName: string='';
  public lastLoginDate: string='';
  public isConnected: boolean = false;
  //public status: number = EnumStatus.Enable;//EnumStatus = EnumStatus.Enable;
  //public statusStr: string;
  public permissions: PermissionCode[] = [];

  public lastLogin: string='';
  public lastLoginDisplay: string='';
  public connecterYN: boolean = false;
  public lastIp: string='';
  public askPassword: string='';
  public isEmailVerified: boolean = false;
  public isForcedOut: boolean = false;
  public token: string='';
  public createdBy: string='';
  public dateCreated: string='';
  public dateCreatedDisplay: string='';
  public modifBy: string='';
  public dateModif: string='';
  public dateModifDisplay: string='';

  public returnUrl: string='';
  public groupE_STR: string='';
  public statusStr: string='';
  public statusClass: string='';
  public activeYN: boolean = false;
  public photoPath: string='';
  public photoPathUrl: string='';
  public nomComplet: string='';
  public idAppFireBase: string='';
  public idFirebaseToken: string='';
  public providerId: string='';

  public userCreated: string='';

  public groupeRole: string='';
  public groupeUserId: string='';
  public civilite: string='';
  public sexe: string='';
  public telephone: string='';
  public telephone2: string='';
  public dateDeNaissance: string='';
  public typeDeVoix: string='';
  public poste: string='';
  public iD_StatutSocial: Number= 0;
  public idpAys: Number= 0;
  public idCommune: Number= 0;
  public adresse: string='';
  public mustChangePassword: boolean = false;
  public connectFrom: string='';

}

export enum UserTextField {
  title= 'User',
  user= 'User',
  id= 'Id',
  photoPath= 'Picture',
  username= 'UserName',
  password= 'Password',
  completeName= 'CompleteName',
  role= 'Role',
  ipAddress= 'IP Address',
  lastLoginDate= 'Last Login Date',
  isConnected= 'Connected',
  status= 'Status',
  statusActiveYN= 'Active(Y/N)',
  token= 'Token',
  jwtToken= 'JWTToken',
  permissions= 'Permissions',
}
