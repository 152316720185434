import { Component, HostListener } from '@angular/core';
import { HeaderHomeComponent } from 'app/layout/header/header-home/header-home.component';
import { LayoutWithOutHeaderComponent } from '../layout-without-header/layout-without-header.component';
import { ImportALlModule } from '@shared/imports';
import { FooterComponent } from 'app/layout/footer/footer.component';

@Component({
  selector: 'app-layout-header-transparent.',
  standalone: true,
  imports: [HeaderHomeComponent, LayoutWithOutHeaderComponent,
    ImportALlModule,
    FooterComponent,
  ],
  templateUrl: './layout-header-transparent.component.html',
  styleUrl: './layout-header-transparent.component.scss'
})
export class LayoutHeaderTransparentComponent {

  public isTransparent = true;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isTransparent = window.scrollY <= 350;

  }
}
