<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover()" (mouseleave)="mouseOut()">
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <li class="sidebar-user-panel">
            <div class="user-panel">
              <div class=" image">
                <img [src]="sessionAuthService.userConnected.photoPathUrl" class="img-circle user-img-circle" alt="User Image" />
              </div>
            </div>
            <div class="profile-usertitle">
              <div class="sidebar-userpic-name">{{sessionAuthService.userConnected.nomComplet}}</div>
              <div class="profile-usertitle-job ">{{sessionAuthService.userConnected.roleStr}} </div>
            </div>
            <div class="sidebar-userpic-btn">
              <a mat-icon-button disabled routerLink="extra-pages/profile" matTooltip="Profile"
                [matTooltipPosition]="'above'">
                <i class="material-icons-outlined">account_circle</i>
              </a>
              <a mat-icon-button disabled routerLink="email/inbox" matTooltip="Email" [matTooltipPosition]="'above'">
                <i class="material-icons-outlined">mark_email_unread</i>
              </a>
              <a mat-icon-button disabled routerLink="calendar" matTooltip="Calendar" [matTooltipPosition]="'above'">
                <i class="material-icons-outlined">event</i>
              </a>
              <a mat-icon-button disabled routerLink="authentication/locked" matTooltip="Lock"
                [matTooltipPosition]="'above'">
                <i class="material-icons-outlined">lock</i>
              </a>
            </div>
          </li>
          <!-- Top Most level menu -->
          @for (sidebarItem of sidebarItems; track sidebarItem) {
            <li [routerLinkActive]="sidebarItem.submenu.length !== 0 ? 'active' : 'active-top'">
              @if (sidebarItem.groupTitle === true && checkPermision( sidebarItem.permission )) {
                <div class="header">{{sidebarItem.title}}</div>
              }
              @if (!sidebarItem.groupTitle) {
                <a *ngIf="checkPermision( sidebarItem.permission )" [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" [ngClass]="[sidebarItem.class]"
                  (click)="callToggleMenu($event, sidebarItem.submenu.length)" class="menu-top">
                  <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
                  <span class="hide-menu">{{sidebarItem.title}}
                  </span>
                  @if (sidebarItem.badge !== '' ) {
                  <span [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
                  }
                </a>
              }
              <!-- First level menu -->
              @if (sidebarItem.submenu.length > 0) {
                <ul class="ml-menu">
                  @for (sidebarSubItem1 of sidebarItem.submenu; track sidebarSubItem1) {
                    <li *ngIf="checkPermision( sidebarSubItem1.permission )" [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                      <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                        (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]">
                        {{sidebarSubItem1.title}}
                      </a>
                      <!-- Second level menu -->
                      @if (sidebarSubItem1.submenu.length > 0) {
                      <ul class="ml-menu-2">
                        @for (sidebarSubItem2 of sidebarSubItem1.submenu; track sidebarSubItem2) {
                          @if(checkPermision( sidebarSubItem2.permission )){
                            <li [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'">
                              <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                                (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)"
                                [ngClass]="[sidebarSubItem2.class]">
                                {{sidebarSubItem2.title}}
                              </a>
                              <!-- Third level menu -->
                              @if (sidebarSubItem2.submenu.length > 0) {
                              <ul class="ml-menu-3">
                                @for (sidebarSubItem3 of sidebarSubItem2.submenu; track sidebarSubItem3) {
                                <li [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                                  @if(checkPermision( sidebarSubItem3.permission )){
                                    <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                                      (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                                      [ngClass]="[sidebarSubItem3.class]">
                                      {{sidebarSubItem3.title}}
                                    </a>
                                  }
                                </li>
                                }
                              </ul>
                              }
                            </li>
                          }
                        }
                      </ul>
                      }
                    </li>
                  }
                </ul>
              }
            </li>
          }















        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
