import { ErrorHandler, Injectable } from '@angular/core';
import { GlobalService } from '@core/service/global.service';
import { Severity } from '@shared/tools/enums/severities';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private globalService: GlobalService,
    private messageService: MessageService,
    ) {}

  handleError(error: any) {
    console.log(`GlobalErrorHandlerService: handleError  -  ICI`);
    this.globalService.showLoader(false);
    let msg = this.globalService.errorHandleMsg(error);
    console.log(`handleError:`,error);
    console.error(error);
    //alert(msg);
    this.showMessage(msg);
  }

  showMessage(msg: string) {
    this.messageService.add({severity: Severity.error, summary:'ERROR:', detail: msg });
  }

}
