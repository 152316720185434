import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { KeyValueModel } from '@core/key-value-model';
import { ChantDesperanceModel, ChantDesperanceTextField } from '@core/models/ce/chant-desperance-model';
import { ChantdesperanceService } from '@core/service/ce/chantdesperance/chantdesperance.service';
import { CommonService } from '@core/service/common/common.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ImportALlModule } from '@shared/imports';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { ID, IDG, ROW_INDEX, TC } from '@shared/tools/constants/constants';
import { MediaPlayerService } from '@core/service/media-player/media-player.service';

@Component({
  selector: 'app-p-table-ce-items',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './p-table-ce-items.component.html',
  styleUrl: './p-table-ce-items.component.scss'
})
export class PTableCeItemsComponent  extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() chantDesperanceList: ChantDesperanceModel[]=[];
  public textField = ChantDesperanceTextField;

  constructor(
    public commonService: CommonService,
    public chantdesperanceService: ChantdesperanceService,
    public elementRef: ElementRef,
    public matDialog: MatDialog,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public mediaPlayerService: MediaPlayerService,
  ){super();}

  ngOnInit() {
    //this.chantFilter.groupeChantantId='99';
    this.activatedRoute.params.subscribe((params: Params) => {
      //console.log(`params:`, params);
      this.id = params['id'];
    });
    console.log(`ngOnInit : PTableCeItemsComponent`);

  }

  public get initSearch(): boolean{
    return (this.chantFilter.categorieChantId!=='' && this.chantFilter.categorieChantId!= undefined)
    || (this.chantFilter.groupeChantantId!=='' && this.chantFilter.groupeChantantId!= undefined)
    || (this.chantFilter.isChantChoeur!=='' && this.chantFilter.isChantChoeur!= undefined)
    || (this.chantFilter.searchStr!=='' && this.chantFilter.searchStr!= undefined)
    || (this.chantFilter.typeChant!=='' && this.chantFilter.typeChant!= undefined);
  }

  navigateToCEDetailsPublic(row: ChantDesperanceModel) {
    this.chantdesperanceService.sessionChantdesperanceSelected=row;
    this.chantdesperanceService.sessionChantdesperanceCurrentList=this.chantDesperanceList;
    this.chantdesperanceService.sessionTypeChant=row.typeChant;
    this.chantdesperanceService.sessionGroupeId=row.groupeChantantId;
    //this.router.navigate([ MenuLink.ChantDesperanceDetailsPublic, row.id ], queryParams:{'?ID=206&tc=FR&ri=1&IDG=12'});
    let index = this.chantDesperanceList.findIndex((x)=> x.id===row.id).toFixed();
    const queryParams = {
      tc: row.typeChant,
      ri: index,
      IDG: row.groupeChantantId,
    };
    this.router.navigate([MenuLink.ChantDesperanceDetailsPublic, row.id], { queryParams });
  }
}
