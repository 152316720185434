import { HttpInterceptorFn } from '@angular/common/http';
import { Inject, inject } from '@angular/core';
import { SessionAuthService } from '@core/service/session-auth/session-auth.service';

export const jwtInterceptor: HttpInterceptorFn = (request, next) => {
  let sessionAuthService = inject(SessionAuthService);
  //let currentUser: UserModel = sessionAuthService.userConnected;
  if (sessionAuthService.jwtToken) {
    //console.log(`sessionAuthService.jwtToken:`,sessionAuthService.jwtToken);
    request = request.clone({
      setHeaders: {
        Authorization: `${sessionAuthService.jwtToken}`,
      },
    });
  }
  return next(request);
};

