import { DecimalDigit } from "../enums/EnumStatusOrder.enum";

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const initialVideoId = "_oZ0oBBaZu8";
export const initialVideoLinkCCG = "https://www.youtube.com/watch?v=_oZ0oBBaZu8";

export const GS_DEV = 'GS_Dev';
export const GS_ADMIN = 'GS_Admin';
export const GS_VENTE = 'GS_Vente';
export const GS_CAISSE = 'GS_Caisse';
export const DECIMAL_DIGIT  =  DecimalDigit.Trois;
export const CUSTOMER = 'Customer';
export const OFFSET = 10;

export const ROW_INDEX = 'ri';
export const ID = 'ID';
export const S = 's';
export const TC = 'tc';
export const IDG = 'IDG';
export const CE = 'CE';
export const CEEBV = 'CEEBV';
export const REDIRECT_TO = 'rto';
export const GLOBAL_SESSION_CHANT_SELECTED = 'GLOBAL_SESSION_CHANT_SELECTED';
export const GLOBAL_SESSION_CHANT_LIST = 'GLOBAL_SESSION_CHANT_LIST';
export const GLOBAL_SESSION_GROUPE_ID = 'GLOBAL_SESSION_GROUPE_ID';
export const GLOBAL_SESSION_TYPE_CHANT = 'GLOBAL_SESSION_TYPE_CHANT';

export const GLOBAL_SESSION_ALIGN_POSITION = 'GLOBAL_SESSION_ALIGN_POSITION';
export const GLOBAL_SESSION_FONT_SIZE = 'GLOBAL_SESSION_FONT_SIZE';

export const GLOBAL_SESSION_CHANT_U_SELECTED = 'GLOBAL_SESSION_CHANT_U_SELECTED';
export const GLOBAL_SESSION_CHANT_U_LIST = 'GLOBAL_SESSION_CHANT_U_LIST';
export const GLOBAL_SESSION_GROUPE_U_ID = 'GLOBAL_SESSION_GROUPE_U_ID';
export const GLOBAL_SESSION_TYPE_U_CHANT = 'GLOBAL_SESSION_TYPE_U_CHANT';

export const layout = {
  normal: "normal",
  fullscreen: "fullscreen",
};

export enum EnumActionRecord {
  RECORD_PRECEDENT = 'RECORD_PRECEDENT',
  RECORD_NEXT = 'RECORD_NEXT'
}

export enum EnumImages {
  IC_SONG = './assets/images/ic_song.png',
  IC_SOLFEGE = './assets/images/ic_solfege.png',
  NO_IMAGE_AVAILABLE ='./assets/images/no_image_available.png',
  LOGO_LYRICS_620x529 ='assets/images/lyrics_chant_620x529_exp.png',
}
