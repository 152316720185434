<div class="row">
  @if (groupeChantantList != null && groupeChantantList.length >0)
  {
    @for ( gce of groupeChantantList; track gce) {
      <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 cp" style="margin-top: 15px;" (click)="getLink(gce)">
        <div class="small-box {{gce.cssClass}}">
          <div class="inner" style="text-align: center;">
            <img [src]="gce.imagePathUrl" alt="" style="width:110px;" />
          </div>
          <div class="textTitleOverflow small-box-footer" [style]="style(gce.code)">
            {{gce.nom}}
          </div>
        </div>
      </div>
    }
  }
</div>
