<div class="validator-error-label" [hidden]="!control || control.valid || !control.errors || !(control.dirty || control.touched)">

  <mat-error  *ngIf="control?.errors?.required">
    {{enumText.validatorTextRequired}}
  </mat-error>

  <label *ngIf="control?.errors?.maxlength">
    This field must have a maximum of {{ control.errors['maxlength'].requiredLength }} characters.
  </label>


  <label *ngIf="control?.errors?.minlength" >
    This field must have a minimum of {{control.errors['minLength'].requiredLength}} characters.
  </label>

  <label *ngIf="control?.errors?.email" >{{enumText.validatorEmail}}</label>

  <ng-content></ng-content>


</div>
