import { EMPTY_GUID, EnumImages } from "@shared/tools/constants/constants";

export class ArtistModel {
  id: string=EMPTY_GUID;
  name: string='';
  imagePath: string='';
  extention: string='';
  bannerTopPath: string='';
  extentionBanner: string='';
  descriptions: string='';
  bannerOrNoImagePathUrl: string=EnumImages.NO_IMAGE_AVAILABLE;
  //this.bannerOrNoImagePathUrl='./assets/images/no_image_available.png';

  createdByUser: string='';
  createdBy: string='';
  dateCreated: string='';
  modifBy: string='';
  dateModif: string='';
}


export enum ArtistTextField {
  title='Artist',
  name='Name',
  descriptions='Descriptions',
}
