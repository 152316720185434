import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@core/service/settings/settings.service';

@Component({
  selector: 'app-terms-conditions',
  standalone: true,
  imports: [],
  templateUrl: './terms-conditions.component.html',
  styleUrl: './terms-conditions.component.scss'
})
export class TermsConditionsComponent  implements OnInit
{
  constructor( public settingsService: SettingsService,) { }

  ngOnInit(): void {
    this.settingsService.loadData();
  }
}
