<p-table #dt2
  dataKey="id"
  [value]="chantDesperanceList"
  [paginator]="true"
  [rows]="30"
  styleClass="p-datatable-gridlines" >
  <ng-template pTemplate="body" let-row>
    <tr class="cp">
        <td class="w3px" (click)="navigateToCEDetailsPublic(row)">
          <div class="bullOrange">{{row.position}}</div>
        </td>
        <td>
          <span class="pull-left w80" (click)="navigateToCEDetailsPublic(row)">
              <span class="title">
                {{row.position}}- {{row.titre}}
              </span>
              <span class="displayb" *ngIf="row.auteurChant!=''">
                <i class="fa fa-user"></i>: {{row.auteurChant}}
              </span>
          </span>
          <span *ngIf="row.audioPath || row.diagrammeNotePath" class="pull-right tr">
            <img *ngIf="row.audioPath"id="imgBtnAudio" width="30" [src]="enumImages.IC_SONG" class="img-circle cp" (click)="mediaPlayerService.playAudio(row, chantDesperanceList)" />
            <img *ngIf="row.diagrammeNotePath" id="imgBtnDiagrammeNote" width="30" [src]="enumImages.IC_SOLFEGE" class="img-circle cp" (click)="mediaPlayerService.showDiagrammeNote(row)" />
          </span>
          <!-- <div [innerHTML]="row.audioSelected"></div> -->
        </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
        <td colspan="10">{{enumText.NoDatafound}}...</td>
    </tr>
  </ng-template>
</p-table>
