import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GroupeChantantModel } from '@core/models/ce/groupe-chantant-model';
import { ChantdesperanceService } from '@core/service/ce/chantdesperance/chantdesperance.service';
import { ImportALlModule } from '@shared/imports';
import { CE, CEEBV } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';

@Component({
  selector: 'app-p-groupe-ce-items',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './p-groupe-ce-items.component.html',
  styleUrl: './p-groupe-ce-items.component.scss'
})
export class PGroupeCeItemsComponent {

  @Input() isShowCEEBV: boolean = false;
  @Input() groupeChantantList: GroupeChantantModel[]=[];

  constructor(public router: Router, private chantdesperanceService: ChantdesperanceService){}


  public style(code: any): string
  {
    let col = (code == 23 || code == 24 || code == 0 || code == 888) ? "black" : "white";
    return ` color:${col};width:100%; text-align: center;vertical-align: middle;text-orientation: sideways;position: relative;display: inline-block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;`
  }
  public getLink( dto: GroupeChantantModel)
  {
      if (dto.code == '23' || dto.code == '0')// Chant Groupe EEBV
      {
        //NavigationManager.NavigateTo($"{CS.GroupeLivreChantV1((isShowCEEBV ? CS.CE : CS.CEEBV))}");
          this.isShowCEEBV = !this.isShowCEEBV;
          //updateTitle(isShowCEEBV);
          this.router.navigate([MenuLink.GroupeCEPublic, !this.isShowCEEBV ? CE : CEEBV]);
      }
      else if (dto.code == '24' || dto.code == '888')// Favoris
      {
        this.router.navigate([MenuLink.FavorisPublic]);
      }
      else
      {
        //BreadcrumbService.SetBreadcrumb(dto.Nom);
        //NavigationManager.NavigateTo($"{CS.MobileListChantV1(dto.Code)}");
        // this.router.navigate([MenuLink.ChantDesperancePublic], { queryParams: { IDP: dto.code } });
        this.chantdesperanceService.sessionGroupeId = Number.parseInt(dto.code);
        this.router.navigate([ MenuLink.ChantDesperancePublic, dto.code ]);
      }
  }
}
