import { Component } from '@angular/core';
import { ChantViewDetailsComponent } from '../chant-view-details.component';
import { StyleMusicalModel } from '@core/models/ce/chant-genre-style-model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChantDesperanceModel } from '@core/models/ce/chant-desperance-model';
import { ImportALlModule } from '@shared/imports';

@Component({
  selector: 'app-chant-view-details-dialog',
  standalone: true,
  imports: [
    ImportALlModule,
    ChantViewDetailsComponent,
  ],
  templateUrl: './chant-view-details-dialog.component.html',
  styleUrl: './chant-view-details-dialog.component.scss'
})
export class ChantViewDetailsDialogComponent {

  public isViewOnly: boolean = true;
  public chantDesperance: ChantDesperanceModel = new ChantDesperanceModel();
  public chantDesperanceDataList: ChantDesperanceModel[] = [];

  constructor(public dynamicDialogConfig: DynamicDialogConfig, public dynamicDialogRef: DynamicDialogRef)
  {
    if( dynamicDialogConfig && dynamicDialogConfig.data ){
      this.isViewOnly = dynamicDialogConfig.data.isViewOnly;

      if( dynamicDialogConfig.data.chantDesperance ){
        this.chantDesperance = dynamicDialogConfig.data.chantDesperance;
      }
    }
  }
}
