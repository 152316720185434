export const LANGUES_DATA = [
  {"name": "Haitian Creole", "value": "HT", "flag": "🇭🇹"},
  {"name": "Anglais", "value": "EN", "flag": "🇺🇸"},
  {"name": "Français", "value": "FR", "flag": "🇫🇷"},
  {"name": "Espagnol", "value": "ES", "flag": "🇪🇸"},
  {"name": "Allemand", "value": "DE", "flag": "🇩🇪"},
  {"name": "Italien", "value": "IT", "flag": "🇮🇹"},
  {"name": "Portugais", "value": "BR", "flag": "🇧🇷"},
  {"name": "Russe", "value": "RU", "flag": "🇷🇺"},
  {"name": "Chinois (Mandarin)", "value": "CN", "flag": "🇨🇳"},
  {"name": "Japonais", "value": "JP", "flag": "🇯🇵"},
  {"name": "Arabe", "value": "SA", "flag": "🇸🇦"},
  {"name": "Hindi", "value": "IN", "flag": "🇮🇳"},
  {"name": "Coréen", "value": "KR", "flag": "🇰🇷"},
  {"name": "Turc", "value": "TR", "flag": "🇹🇷"},
  {"name": "Néerlandais", "value": "NL", "flag": "🇳🇱"},
  {"name": "Suédois", "value": "SE", "flag": "🇸🇪"},
  {"name": "Danois", "value": "DK", "flag": "🇩🇰"},
  {"name": "Norvégien", "value": "NO", "flag": "🇳🇴"},
  {"name": "Polonais", "value": "PL", "flag": "🇵🇱"},
  {"name": "Tchèque", "value": "CZ", "flag": "🇨🇿"},
  {"name": "Hongrois", "value": "HU", "flag": "🇭🇺"},
  {"name": "Roumain", "value": "RO", "flag": "🇷🇴"},
  {"name": "Grec", "value": "GR", "flag": "🇬🇷"},
  {"name": "Hébreu", "value": "IL", "flag": "🇮🇱"},
  {"name": "Thaï", "value": "TH", "flag": "🇹🇭"},
  {"name": "Vietnamiens", "value": "VN", "flag": "🇻🇳"},
  {"name": "Swahili", "value": "KE", "flag": "🇰🇪"},
  {"name": "Bengali", "value": "BD", "flag": "🇧🇩"},
  {"name": "Malais", "value": "MY", "flag": "🇲🇾"},
  {"name": "Filipino", "value": "PH", "flag": "🇵🇭"},
  {"name": "Serbe", "value": "RS", "flag": "🇷🇸"},
  {"name": "Croate", "value": "HR", "flag": "🇭🇷"},
  {"name": "Slovaque", "value": "SK", "flag": "🇸🇰"},
  {"name": "Lituanien", "value": "LT", "flag": "🇱🇹"},
  {"name": "Letton", "value": "LV", "flag": "🇱🇻"},
  {"name": "Estonien", "value": "EE", "flag": "🇪🇪"},
  {"name": "Georgien", "value": "GE", "flag": "🇬🇪"},
  {"name": "Arménien", "value": "AM", "flag": "🇦🇲"},
  {"name": "Azerbaïdjanais", "value": "AZ", "flag": "🇦🇿"},
  {"name": "Kazakh", "value": "KZ", "flag": "🇰🇿"},
  {"name": "Ouzbek", "value": "UZ", "flag": "🇺🇿"},
  {"name": "Kyrgyz", "value": "KG", "flag": "🇰🇬"},
  {"name": "Tadjik", "value": "TJ", "flag": "🇹🇯"},
  {"name": "Pashto", "value": "AF", "flag": "🇦🇫"},
  {"name": "Persan", "value": "IR", "flag": "🇮🇷"},
  {"name": "Urdu", "value": "PK", "flag": "🇵🇰"},
  {"name": "Lao", "value": "LA", "flag": "🇱🇦"},
  {"name": "Birman", "value": "MM", "flag": "🇲🇲"},
  {"name": "Serbo-croate", "value": "BA", "flag": "🇧🇦"},
  {"name": "Albanais", "value": "AL", "flag": "🇦🇱"},
  {"name": "Slovène", "value": "SI", "flag": "🇸🇮"},
  {"name": "Macedonien", "value": "MK", "flag": "🇲🇰"},
  {"name": "Monténégrin", "value": "ME", "flag": "🇲🇪"},
  {"name": "Mongol", "value": "MN", "flag": "🇲🇳"},
  {"name": "Tibétain", "value": "CN", "flag": "🇨🇳"},
  {"name": "Cantonais", "value": "HK", "flag": "🇭🇰"},
  {"name": "Hmong", "value": "LA", "flag": "🇱🇦"},
  {"name": "Punjabi", "value": "IN", "flag": "🇮🇳"},
  {"name": "Sindhi", "value": "PK", "flag": "🇵🇰"},
  {"name": "Cebuano", "value": "PH", "flag": "🇵🇭"},
  {"name": "Maori", "value": "NZ", "flag": "🇳🇿"},
  {"name": "Hawaïen", "value": "US", "flag": "🇺🇸"},
  {"name": "Nepali", "value": "NP", "flag": "🇳🇵"},
  {"name": "Sinhala", "value": "LK", "flag": "🇱🇰"},
  {"name": "Amharique", "value": "ET", "flag": "🇪🇹"},
  {"name": "Somali", "value": "SO", "flag": "🇲🇲"},
  {"name": "Yoruba", "value": "NG", "flag": "🇳🇬"},
  {"name": "Igbo", "value": "NG", "flag": "🇳🇬"},
  {"name": "Zulu", "value": "ZA", "flag": "🇿🇦"},
  {"name": "Afrikaans", "value": "ZA", "flag": "🇿🇦"},
  {"name": "Malgache", "value": "MG", "flag": "🇲🇬"},
  {"name": "Kinyarwanda", "value": "RW", "flag": "🇷🇼"},
  {"name": "Kirmanjki", "value": "TR", "flag": "🇹🇷"},
  {"name": "Tigrinya", "value": "ER", "flag": "🇪🇷"},
  {"name": "Dari", "value": "AF", "flag": "🇦🇫"},
  {"name": "Javanais", "value": "ID", "flag": "🇮🇩"},
  {"name": "Sundanese", "value": "ID", "flag": "🇮🇩"},
  {"name": "Bassa", "value": "CM", "flag": "🇨🇲"},
  {"name": "Wolof", "value": "SN", "flag": "🇲🇱"},
  {"name": "Duala", "value": "CM", "flag": "🇨🇲"},
  {"name": "Luba-Katanga", "value": "CD", "flag": "🇨🇩"},
  {"name": "Chewa", "value": "MW", "flag": "🇲🇼"},
  {"name": "Shona", "value": "ZW", "flag": "🇿🇼"},
  {"name": "Tswana", "value": "BW", "flag": "🇧🇼"},
  {"name": "Sesotho", "value": "LS", "flag": "🇱🇸"},
  {"name": "Kamba", "value": "KE", "flag": "🇰🇪"},
  {"name": "Bemba", "value": "ZM", "flag": "🇿🇲"},
  {"name": "Kikuyu", "value": "KE", "flag": "🇰🇪"}
]
