
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5335107298173193"
     crossorigin="anonymous"></script>
<!-- Display-Horizontal-ads-Responsive -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-5335107298173193"
     data-ad-slot="4954037374"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
