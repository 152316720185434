import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { FeatherModule } from 'angular-feather';
import { MenuItem } from 'primeng/api';
import { Subscription, filter } from 'rxjs';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-breadcrumb-v2',
  standalone: true,
  imports: [
    RouterLink, FeatherModule
  ],
  templateUrl: './breadcrumb-v2.component.html',
  styleUrl: './breadcrumb-v2.component.scss'
})
export class BreadcrumbV2Component  implements OnInit, OnDestroy {

  @Input() titlePage: String="";
  @Input() count: String="";
  
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly ROUTE_DATA_BREADCRUMB_ARGS = 'breadcrumbArgs';
  static readonly ROUTE_DATA_BREADCRUMB_FORMAT = 'breadcrumbFormat';

  public menuItems!: MenuItem[];
  private routeChangeSubscription!: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  get home() : any {
    return { label: ` ${this.titlePage}`, icon: 'fas fa-home', routerLink: MenuLink.Dashboard  };
  }

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumb( this.activatedRoute.root );
    this.routeChangeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd) )
      .subscribe((routerEvent: any) =>
        this.menuItems = this.createBreadcrumb( this.activatedRoute.root ),
          (error: any) => { console.error(error); });
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd) ).subscribe((routerEvent: any) => {
    //   return this.menuItems = this.createBreadcrumb( this.activatedRoute.root );
    // }, (error: any) => { console.error(error); });
  }

  ngOnDestroy(): void {
   if( this.routeChangeSubscription )
    this.routeChangeSubscription.unsubscribe();
  }

  private createBreadcrumb( route: ActivatedRoute, url: string='', breadcrumbs: MenuItem[]=[]): MenuItem[] {
    const children: ActivatedRoute[] = route.children;
    if( children.length === 0 ){
      return breadcrumbs;
    }

    for( const child of children ){
      const routeURL: string = child.snapshot.url.map((x) => x.path).join('/');
      //console.log(routeURL);
      if(routeURL !== ''){
        url += `/${routeURL}`;
        //console.log(url);
      }
      const label = child.snapshot.data[BreadcrumbV2Component.ROUTE_DATA_BREADCRUMB];
      // console.log(`label:`);
      // console.log(label);
      if( label !== undefined /*|| label !== null*/ ){
        // console.log(`label !== undefined`);
        let args = { };
        if( child.snapshot.data[BreadcrumbV2Component.ROUTE_DATA_BREADCRUMB_ARGS] != null ){
          args =  child.snapshot.data[ child.snapshot.data[BreadcrumbV2Component.ROUTE_DATA_BREADCRUMB_ARGS]]
          if( child.snapshot.data[BreadcrumbV2Component.ROUTE_DATA_BREADCRUMB_FORMAT] ){
            args = child.snapshot.data[BreadcrumbV2Component.ROUTE_DATA_BREADCRUMB_FORMAT](args)
          }
          // console.log(`args:`);
          // console.log(args);
        }
        if( breadcrumbs.findIndex((c)=> c.label==label) < 0)
        breadcrumbs.push({ label: label, routerLink: [url] });
      }
      //console.log(child);
      return this.createBreadcrumb( child, url, breadcrumbs );
    }
    return [];
  }

}
