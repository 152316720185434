import { Component, OnInit } from '@angular/core';
import { GroupeChantantDto, GroupeChantantModel } from '@core/models/ce/groupe-chantant-model';
import { ImportALlModule } from '@shared/imports';
import { PGroupeCeItemsComponent } from '../p-groupe-ce-items/p-groupe-ce-items.component';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { GroupeChantantService } from '@core/service/ce/groupChantant/groupe-chantant.service';
import { GlobalService } from '@core/service/global.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CEEBV } from '@shared/tools/constants/constants';
import { MenuItem } from 'primeng/api';
import { MenuLink } from '@shared/tools/enums/enum-router';

@Component({
  selector: 'app-p-groupe-ce-list',
  standalone: true,
  imports: [
    ImportALlModule,
    PGroupeCeItemsComponent,
  ],
  templateUrl: './p-groupe-ce-list.component.html',
  styleUrl: './p-groupe-ce-list.component.scss'
})
export class PGroupeCeListComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  constructor(
    public groupeCeService: GroupeChantantService,
    private activatedRoute: ActivatedRoute
  ){ super(); }

  //items: MenuItem[] = [{ icon: 'pi pi-home', route: '/' }];
  home: MenuItem | undefined;

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['id']){
        this.groupeCeService.isShowCEEBV = params['id']==CEEBV;
      }
    });
    this.title = this.groupeCeService.isShowCEEBV?'CHANTS CHORALES & GROUPES EEBV':"CHANTS D'ESPÉRANCE";
    this.menuItemBreadcrumb.push({ icon: 'pi pi-book', label: `${this.title}`, route: `${MenuLink.GroupeCEPublic}` });

    this.groupeCeService.loadGroupeCEPublicData();
  }
}
