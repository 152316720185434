export class MediaPlayerModel {
  id: string ='';
  url: string ='';
  name: string ='';
  artist: string ='';
  position: string='';
  titre: string='';
  auteurChant: string='';
  typeChant: string="FR";
  groupeChantantId:string | number =0;
}
export class StreamStateModel {
  playing: boolean=false;
  readableCurrentTime: string='';
  readableDuration: string='';
  duration: number | undefined=0;
  currentTime: number | undefined;
  canplay: boolean=false;
  error: boolean=false;
}
