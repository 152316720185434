import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { UserModel, UserTextField } from '@core/models/user/user-model';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { PermissionCode } from '@shared/tools/enums/permission';
//import { any } from 'bluebird';

@Injectable({
  providedIn: 'root'
})

export class SessionAuthService {
  user: UserModel = new UserModel();
  public textField=UserTextField;
  public enumText= EnumText;

  public currentPermissions: PermissionCode[] = [];
  private permissionCodeSubject = new Subject<PermissionCode[]>();
  public roleName: string='';

  constructor( ) {  }

  notifyPermissionCode(): void {
    this.permissionCodeSubject.next( this.currentPermissions );
  }
  // Session
  get userConnected() : UserModel {
    let user = this.getItem( this.textField.user );
    return user??new UserModel();
  }
  // Session
  get jwtToken() : string {
    var jsonData = localStorage.getItem(this.textField.jwtToken);
    //console.log(`get jwtToken()`, jsonData);
    return jsonData??'';
  }
  setJwtToken(val: string) {
    //console.log(`set jwtToken()`, val);
    localStorage.setItem(this.textField.jwtToken, val);
  }

  getItem(key: string): any {
    var jsonData = localStorage.getItem(key);
    //console.log(`key[${key}]`, jsonData);
    if( jsonData != null ){
      try {
        let val = JSON.parse(jsonData);
        return val;
      } catch (e) {
          console.log(`The data for key[${key}] is not in JSON format.`);
          return null;
      }
      return null;
    }
    return null;
  }
  getItemVar(key: string): any {
    var jsonData = localStorage.getItem(key)??'';
    //console.log(`key[${key}]`, jsonData);
    return jsonData;
  }
  setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }
  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  get userName() : string {
    return this.userConnected?.userName;
  }
  get nomComplet() : string {
    return this.userConnected?.nomComplet;
  }
  get role() : string {
    return this.userConnected?.role;
  }
  get lastLoginDate() : string {
    return this.userConnected?.lastLoginDate;
  }
  get isConnected() : boolean {
    return this.userConnected?.isConnected??false;
  }
  get token() : string {
    return this.userConnected?.token;
  }
  get permissions() : PermissionCode[] {
    return this.userConnected?.permissions;
  }
}
