import { UserModel } from "../user/user-model";

export class ChantShareModel {
  id: string ='';
  userId: string ='';
  chantEsperanceId: number =0;
  userIdDestination: string ='';
  statutShared: number =0;

  isCanUpdate: boolean = false;
  isCanShare: boolean = false;
  isCanUpdateStr: string =''
  isCanShareStr: string =''

  isVisualiserChant: boolean = false;
  user: UserModel = new UserModel();

  userDestination: UserModel = new UserModel();

  DateCreatedStr: string ='';
  DateModifStr: string ='';
}
