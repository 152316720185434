export enum EnumText{
  //Title
  error = 'Error',
  NoDatafound = 'No Data found',

  // Message
  pasDeReste = 'No stay',
  accessDenied = 'Access Denied!',
  sauvegardeEffectuee = 'Registration Done with success',
  suppresionEffectuee = 'Deletion Successful',
  confirmDelete = '<p>Are you sure you want to Delete? <br /> You will not be able to restore the data! </p>',
  completeThePurchase = 'You must first complete the purchase',
  doYouWantcompleteThePurchase = 'Are you sure you want to complete the purchase?',
  UnableToCompleteThePurchase = 'Unable to complete purchase without product',
  confirmPayment = '<p>Are you sure you want to make this payment ?</p>',
  confirmPartialPayment = '<p>Are you sure you want to make this partial payment ?</p>',
  confirmValidateOder = '<p>Are you sure you want to validate this order ?</p>',
  confirmDelivery = '<p>Do you really want to confirm this delivery and complete the transaction?</p>',
  selectGroupAlbum = 'Select Album/Group',
  msg_TraitementImpossible = "Impossible de traiter les informations essayer à nouveau..",
  msg_An_error_occurred_during_process = "Une erreur est survenue lors du processus essayer à nouveau.",

  selectGroup = '- Select Group -',
  selectSomeProduct = 'Select some product',
  amountIsIncorrect = 'The amount is incorrect',

  // Validator
  validatorTextRequired = '* required',
  validatorFieldRequired = 'This field is required',
  validatorMaxlength='This field must have a maximum of {{maxLength}} characters.',
  validatorMinlength='This field must have a minimum of {{minLength}} characters.',
  validatorEmail='This field must have a valid email format.',


  //Text
  PowerBy = 'Power By',
  Version = 'Version',
  BrainDev = 'BrainDev',

  // Fields / ColumnName
  Actions='Actions',
  Searchkeyword='Search keyword',

  // Buttons
  Add='Add',
  AddAndClose='Add and Close',
  AddAndContinue='Add and Continue',
  New='New',
  AddColor='Add Color',
  AddNewOrder='Add New Order',
  addPayment= 'Add New Payment',

  Edit='Edit',
  EditOrder='Edit Order',
  Delete='Delete',
  Login='Login',
  LogOut='Log Out',
  SaveChanges='Save Changes',
  Close='Close',
  Cancel='Cancel',
  BackToList='Back to List',
  GotoNext='Go to Next',
  GotoDashboard='Go to Dashboard',
  View='View',
  PrintReceipt='Print Receipt',
  PrintInvoice='Print Invoice',
  PrintRepport='Print Repport',
  PrintGroupByStatus='Group by Status',
  PrintOrderOperationalRepport='Print Order Operational Repport',
  GoBack='Go Back',
  makeThePayment='Make the payment',
  makePartialPayment='Make Partial payment',
  validateOrder='validate Order',
  AddNewClient='Add New Client/Customer',
  Stay='Rest of products',
  RestOfProducts='Rest of products',
  Rest='Rest',
  PiedCarreSymbole='p²',
  UnDemiSymbole='½',
  TauxDuJour='Daily Rate',
  subTotal='Sub-Total',
  SaleReceipt='Sale Receipt',
  Back='Back',
  Valider='Valider',
  CompletePurchase='Complete purchase',
  SearchCritere='Search criteria',
  Search='search',
  Advanced='Advanced',
  SearchAdvanced='Advanced search',
  ClearSearch='Clear Search',
  StartSearch='Start search',
  Seller='Seller',
  ConfirmDelivery='Confirm Delivery',
  InformationSendWithSuccess='Information send with success'
}
