import { Injectable } from '@angular/core';
import { ChantDesperanceTextField } from '@core/models/ce/chant-desperance-model';
import { ApiService } from '@core/service/api/api.service';
import { CommonService } from '@core/service/common/common.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChantFavorisService {

  endpoint: string = ApiUrl.ChantFavorisCE;

  constructor( private apiService: ApiService) {}

  checkCE(data: any) : Observable<any>{
    return this.apiService.getData(`${this.endpoint}/Check`, data, );
  }

  markCE(data: any) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}/Mark`, data, );
  }

}
