import { Injectable } from '@angular/core';
import { ApiService } from '@core/service/api/api.service';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChantdesperanceLikeService {

  endpoint: string = ApiUrl.ChantDesperanceLike;

  constructor( private apiService: ApiService) {}

  checkCE(data: any) : Observable<any>{
    return this.apiService.getData(`${this.endpoint}/Check`, data, );
  }

  markCE(data: any) : Observable<any>{
    return this.apiService.postData(`${this.endpoint}/Mark`, data, );
  }
}
