import { Injectable } from '@angular/core';
import { SettingsModel, SettingsTextField } from '@core/models/ce/settings.model';
import { ApiUrl } from '@shared/tools/constants/api.endpoints';
import { EnumText } from '@shared/tools/enums/enum-Text';
import { EnumAction } from '@shared/tools/enums/enum-status.enum';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { GlobalService } from '../global.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class SettingsService  extends UnsubscribeOnDestroyAdapter {

  endpoint: string = ApiUrl.Settings;
  public textField=SettingsTextField;
  //public enumText= EnumText;
  public settings: SettingsModel = new SettingsModel();

  constructor( private apiService: ApiService, private globalService: GlobalService) { super(); }

  getOne() : Observable<any> {
    return this.apiService.getData(`${this.endpoint}` );
  }

  update(  data: any ) : Observable<any>{
    return this.apiService.putData(`${this.endpoint}/${data.id}`, data, );
  }



  async loadData() {
    if( this.settings.id===''){
      this.globalService.showLoader();
      this.subs.sink = await this.getOne().subscribe({
        next: (result) => {
          this.settings = result;
          console.log(`settings`, this.settings);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
          this.isLoading=false;
          this.globalService.showLoader(false);
        }
      });
    }
  }

}
