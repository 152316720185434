import { Component } from '@angular/core';

@Component({
  selector: 'app-display-horizontal-ads-responsive',
  standalone: true,
  imports: [],
  templateUrl: './display-horizontal-ads-responsive.component.html',
  styleUrl: './display-horizontal-ads-responsive.component.scss'
})
export class DisplayHorizontalAdsResponsiveComponent {

}
