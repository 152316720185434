<div class="divMPlayControls" *ngIf="mediaPlayerService.streamState.canplay">
  <div class="container">
    <div class="d-flex">
      <div class="flex-grow-1">
        <span class="vew-mail-header pull-left cp">
          <button class="btn" pTooltip="Skip back" tooltipPosition="bottom" [disabled]="isFirstPlaying()" (click)="mediaPlayerService.previous()" >
            <app-feather-icons [icon]="'skip-back'" [class]="'user-menu-icons'" ></app-feather-icons>
          </button>

          <button class="btn" pTooltip="Play" tooltipPosition="bottom" (click)="play()" [disabled]="mediaPlayerService.streamState.error" *ngIf="!mediaPlayerService.streamState?.playing">
            <app-feather-icons [icon]="'play'" [class]="'user-menu-icons'" ></app-feather-icons>
          </button>
          <button class="btn" pTooltip="Pause" tooltipPosition="bottom" (click)="pause()" *ngIf="mediaPlayerService.streamState?.playing" >
            <app-feather-icons [icon]="'pause'" [class]="'user-menu-icons'" ></app-feather-icons>
          </button>

          <button class="btn" pTooltip="Skip forward" tooltipPosition="bottom" [disabled]="isLastPlaying()" (click)="mediaPlayerService.next()" >
            <app-feather-icons [icon]="'skip-forward'" [class]="'user-menu-icons'" ></app-feather-icons>
          </button>

          {{ mediaPlayerService.streamState.readableCurrentTime }}
          <mat-slider
               min="0"
               [max]="mediaPlayerService.streamState.duration" step="0.5"
               [disabled]="mediaPlayerService.streamState.error || mediaPlayerService.currentAudioFile.index === undefined" >
           <input matSliderThumb [(ngModel)]="mediaPlayerService.streamState.currentTime"
               (input)="onSliderChangeEnd($event)">
         </mat-slider>
          {{ mediaPlayerService.streamState.readableDuration }}

          <button class="btn" pTooltip="Skip forward" tooltipPosition="bottom" *ngIf="mediaPlayerService.streamState?.playing" (click)="mediaPlayerService.mute()" >
            <app-feather-icons [icon]="mediaPlayerService.muteIcon" [class]="'user-menu-icons'" ></app-feather-icons>
          </button>
        </span>

        <span class="date float-end cp">
          <!-- <div [innerHTML]="mediaPlayerService.mediaPlayer.sourcePath"></div> -->
        </span>
      </div>
    </div>
  </div>
</div>
