<!-- footer -->
<div class="footer">
  <div class="container">
      <div class="w3ls_footer_grid">
          <div class="w3ls_footer_grid_left">
              <div class="w3ls_footer_grid_left1">
                  <h2>Suivez nous...</h2>
                  <div class="w3ls_footer_grid_left1_pos">
                      <ul>
                          <li><a target="_blank" href="http://www.facebook.com/Rezo509" class="facebook"><i class="fa fa-facebook" aria-hidden="true"></i>Rezo509</a></li>
                          <li><a target="_blank" href="http://www.twitter.com/Rezo509Haiti" class="twitter"><i class="fa fa-twitter" aria-hidden="true"></i>Rezo509Haiti</a></li>
                          <li><a target="_blank" href="http://www.google.com/+Rezo509" class="google"><i class="fa fa-google-plus" aria-hidden="true"></i>Rezo509</a></li>
                          <li><a target="_blank" href="http://www.rezo509.com/Institution.aspx?ID=1&page=tab_FicheOffresEmploi" class="facebook"><i class="fa fa-bullseye" aria-hidden="true"></i>Rezo509</a></li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="w3ls_footer_grid_right">
              <ul class="agileits_w3layouts_footer">
                  <li><a [routerLink]="menuLink.Contact">Contact</a></li>
                  <li><a [routerLink]="menuLink.PrivacyPolicy">Privacy</a></li>
                  <li><a [routerLink]="menuLink.TermsConditions">Terms</a></li>
                  <li><a [routerLink]="menuLink.Guide">GU</a></li>
              </ul>
          </div>
          <div class="clearfix"></div>
      </div>
      <div class="w3_agile_copyright">
          <p class="copyright">
              Copyright © {{appInfo.Annee}} {{AnneeNow}} {{appInfo.AppName}}. All Rights Reserved
          <br /><a id="ctl00_FooterSite_linkPrivacy" [routerLink]="menuLink.PrivacyPolicy">Privacy Policy</a>
          | <a id="ctl00_FooterSite_HyperLink1" [routerLink]="menuLink.TermsConditions">Terms &amp; Conditions</a>
          | Created by <a [href]="appInfo.BrainDevWebSite" target="_blank">Jean Fritz DUVERSEAU</a></p>
          <p>&copy; {{globalService.appInfo.Annee}} {{AnneeNow}} <a target="_blank" [href]="appInfo.BrainDevWebSite" class="facebook">Brain Development</a>.</p>
      </div>
  </div>
</div>
<!-- //footer -->
