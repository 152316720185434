import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from '@config';
import { RightSidebarService, AuthService, InfoService, InConfiguration } from '@core';
import { SessionAuthService } from '@core/service/session-auth/session-auth.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';
import { ImportALlModule } from '@shared/imports';
import { GS_DEV } from '@shared/tools/constants/constants';
import { MenuLink } from '@shared/tools/enums/enum-router';
import { SearchFieldComponent } from '../search-field/search-field.component';

@Component({
  selector: 'app-menu-top',
  standalone: true,
  imports: [
    ImportALlModule,
    SearchFieldComponent,
  ],
  templateUrl: './menu-top.component.html',
  styleUrl: './menu-top.component.scss'
})
export class MenuTopComponent  extends UnsubscribeOnDestroyAdapter implements OnInit {
  public config!: InConfiguration;
  isNavbarCollapsed = true;
  showSearch = false;
  isOpenSidebar?: boolean;
  isOpenSettingSidebar?: boolean;
  docElement?: HTMLElement;
  isFullScreen = false;
  public apiInfo='';
  @Input() isTransparent:boolean=false;
  @Input() isBO:boolean=false;

  constructor(
    //@Inject(DOCUMENT) private document: Document,
    //private renderer: Renderer2,
    //public elementRef: ElementRef,
    //private rightSidebarService: RightSidebarService,
    //private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public infoService: InfoService,
    public sessionAuthService: SessionAuthService,
  ) { super(); }

  ngOnInit() {
    // this.config = this.configService.configData;
    // this.docElement = document.documentElement;
    // this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
    //   (isRunning) => {
    //     this.isOpenSettingSidebar = isRunning;
    //   }
    // );
    // this.infoService.getApiInfoVersion();
  }

  get isBrainDevUser(): boolean{
    return ( this.sessionAuthService.userConnected.role==GS_DEV)
  }
  public get transparent() :string{
    return this.isTransparent?'transparent':'';
  }
  goTo(page: string){
    this.router.navigate([page]);
  }
  callFullscreen() {
    if (!this.isFullScreen) {
      if (this.docElement?.requestFullscreen != null) {
        this.docElement?.requestFullscreen();
      }
    } else {
      document.exitFullscreen();
    }
    this.isFullScreen = !this.isFullScreen;
  }

  // mobileMenuSidebarOpen(event: Event, className: string) {
  //   const hasClass = (event.target as HTMLInputElement).classList.contains(
  //     className
  //   );
  //   if (hasClass) {
  //     this.renderer.removeClass(this.document.body, className);
  //   } else {
  //     this.renderer.addClass(this.document.body, className);
  //   }
  // }

  // callSidemenuCollapse() {
  //   const hasClass = this.document.body.classList.contains('side-closed');
  //   if (hasClass) {
  //     this.renderer.removeClass(this.document.body, 'side-closed');
  //     this.renderer.removeClass(this.document.body, 'submenu-closed');
  //     localStorage.setItem('collapsed_menu', 'false');
  //   } else {
  //     this.renderer.addClass(this.document.body, 'side-closed');
  //     this.renderer.addClass(this.document.body, 'submenu-closed');
  //     localStorage.setItem('collapsed_menu', 'true');
  //   }
  // }
  // toggleRightSidebar(): void {
  //   console.log(`toggle Right Sidebar :: this.isOpenSettingSidebar=: ${!this.isOpenSettingSidebar}`);
  //   this.rightSidebarService.setRightSidebar(!this.isOpenSettingSidebar);
  // }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate([MenuLink.Login]);
      }
    });
  }

}
