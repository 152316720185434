export enum EnumExtensionFile{
  Extention_AllDocumentFile = ".jpg,.png,.bmp,.jpeg,.gif,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx,.mp4,.mp3,.vlc,flv,.ogg,.acc,.wmp,.avi,.mid",
  Extention_DocumentFile = ".jpg,.png,.bmp,.jpeg,.gif,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx",

  Extention_DocumentFileSolfege = ".jpg,.png,.bmp,.jpeg,.gif,.pdf",
  Extention_AudioFile = ".mp4,.m4a,.mp3,.mpeg,.vlc,flv,.ogg,.acc,.wmp,.avi,.mid",
  Extention_VideoFile = ".mp4,.mp3,.vlc,flv,.ogg,.acc,.wmp,.avi,.mid",
  Extention_ImageFile = ".jpg,.png,.bmp,.jpeg,.gif",
  TypeAudioFile = "audio",
}

export class ExtensionFile
{
    private static CheckExtensionFile( extensionList: string, extension: string):boolean
    {
        var fields1 = extensionList.split(",");
        var fields2 = extensionList.replaceAll('.','').split(",");
        return fields1.includes(extension.toLowerCase()) || fields2.includes(extension.toLowerCase());
    }
    public static CheckAudioFile(extension: string): boolean { return this.CheckExtensionFile(EnumExtensionFile.Extention_AudioFile, extension.toLowerCase());}

    public static CheckDocumentFileSolfege(extension: string): boolean { return this.CheckExtensionFile(EnumExtensionFile.Extention_DocumentFileSolfege, extension.toLowerCase());}

    public static CheckImageFile(extension: string): boolean { return this.CheckExtensionFile(EnumExtensionFile.Extention_ImageFile, extension.toLowerCase());}

    public static CheckVideoFile(extension: string): boolean { return this.CheckExtensionFile(EnumExtensionFile.Extention_VideoFile, extension.toLowerCase());}

}
