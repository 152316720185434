<app-header-bo></app-header-bo>
<app-sidebar-v2></app-sidebar-v2>
<!-- <app-right-sidebar></app-right-sidebar> -->
<div [dir]="direction">
  <div style="margin-bottom: 80px;"></div>
  <router-outlet></router-outlet>
<!-- DisplayAds-Horizontal-Responsive -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-5335107298173193"
     data-ad-slot="4954037374"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
  <!-- <app-footer></app-footer> -->
  <app-media-player></app-media-player>
</div>
