import { Component } from '@angular/core';
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component';
import { MediaPlayerService } from '@core/service/media-player/media-player.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';
// https://github.com/imsingh/auth0-audio/

@Component({
  selector: 'app-media-player',
  standalone: true,
  imports: [
    FeatherIconsComponent,

    CommonModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatSliderModule,


    SliderModule,
    FormsModule,
    // ReactiveFormsModule,
  ],
  templateUrl: './media-player.component.html',
  styleUrl: './media-player.component.scss'
})
export class MediaPlayerComponent {
  sliderValue: number = 0; // Initial value

  onSliderChange(event: any) {
    //console.log('onSliderChange:', event);
    //console.log('onSliderChange: state.currentTime:', this.mediaPlayerService.streamState.currentTime);
    this.sliderValue = event.value;
  }

  //files: Array<any> = [];

  constructor(public mediaPlayerService: MediaPlayerService){
    //console.log(`MediaPlayerComponent: constructor()`);
    // get media files
    // mediaPlayerService.getFiles().subscribe(files => {
    //   console.log(`getFiles:`,files);
    //   this.files = files;
    // });

    // listen to stream state
    this.mediaPlayerService.getState()
    .subscribe(state => {
      //console.log(`getState:`,state);
      this.mediaPlayerService.streamState = state;
    });
  }

  playStreamAudio(url:any) {
    this.mediaPlayerService.playStream(url)
    .subscribe(events => {
      //console.log(`playStream:`,events);
      // listening for fun here
    });
  }

  openFileAudio(file:any, index:number) {
    this.mediaPlayerService.currentAudioFile = { index, file };
    this.mediaPlayerService.stop();
    this.playStreamAudio(file.url);
  }

  pause() {
    this.mediaPlayerService.pause();
  }

  play() {
    this.mediaPlayerService.play();
  }

  stop() {
    this.mediaPlayerService.stop();
  }

  // next() {
  //   const index = this.mediaPlayerService.currentAudioFile.index!=undefined?this.mediaPlayerService.currentAudioFile.index++:0;
  //   const file = this.files[index];
  //   this.mediaPlayerService.openFileAudio(file, index);
  // }

  // previous() {
  //   const index = this.mediaPlayerService.currentAudioFile.index!=undefined?this.mediaPlayerService.currentAudioFile.index--:0;
  //   const file = this.files[index];
  //   this.mediaPlayerService.openFileAudio(file, index);
  // }

  isFirstPlaying() {
    return this.mediaPlayerService.currentAudioFile?.index === 0;
  }

  isLastPlaying() {
    return this.mediaPlayerService.currentAudioFile?.index === this.mediaPlayerService.mediaPlayerList.length - 1;
  }
  onInputChange(event: Event) {
    console.log("This is emitted as the thumb slides");
    console.log((event.target as HTMLInputElement).value);
  }
  onSliderChangeEnd(event: any) {
    let val = (event.target as HTMLInputElement).value;
    console.log(`onSliderChangeEnd: change.value:`,val);
    this.mediaPlayerService.seekTo(val);
  }

}
