<!-- <form [formGroup]="formGroupPageName" (ngSubmit)="onSubmit()" > -->
  <p-messages [(value)]="message"></p-messages>

<div class="row" style="margin-top: 15px;">

  <!-- @if( !this.isCUPublic ){ -->

  <div *ngIf="showKeywordSearch" class="col-xl-8 col-lg-8 col-md-8 col-sm-8">
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Keyword search</mat-label>
      <input [(ngModel)]="chantFilter.searchStr" matInput id="searchStr">
      <mat-icon matSuffix class="search-icon">search</mat-icon>
    </mat-form-field>
  </div>

  <div *ngIf="showLangue" class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
    <!-- <label for="inputtext">{{textField.typeChant}}</label> -->
    <p-dropdown [(ngModel)]="chantFilter.typeChant" [options]="typeChantList"  [itemSize]="10"
      [virtualScroll]="false" [filter]="false" optionValue="value" optionLabel="label"
      styleClass=""  placeholder="{{textField.typeChant}}" (onChange)="onChangeDropdown(enumFields.TYPE_CHANT)">
      <ng-template let-option  pTemplate="selectedItem">
        <div class="ofa" pTooltip="{{option.name}}" tooltipPosition="bottom">{{option.flag}} {{option.name}} </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span>{{option.flag}} {{option.name}} </span>
      </ng-template>
    </p-dropdown>
  </div>

  <div *ngIf="showGroupeChantant" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
    <label for="inputtext">{{textField.groupeChantant}}</label>
    <p-dropdown [options]="groupeChantantService.groupeChantantDataList" [(ngModel)]="chantFilter.groupeChantantId" [itemSize]="10"
        [virtualScroll]="false" [filter]="false" [checkmark]="true" optionValue="code" optionLabel="nom"
        styleClass="w100"  placeholder=" -Select {{textField.groupeChantant}} -" [loading]="groupeChantantService.loadingGroupeChantantDataList"
        [style]="{'width':'100%'}" (onChange)="onChangeDropdown(enumFields.GROUPE_CHANTANT)">
        <ng-template let-option  pTemplate="selectedItem">
          <div class="ofa" pTooltip="{{option.code}}-{{option.nom}}" tooltipPosition="bottom">
            <img width="25" [src]="option.imagePathUrl" class="img-circle" />&nbsp;{{option.nom}}
          </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
          <img width="25" [src]="option.imagePathUrl" class="iimg-circle" />&nbsp;
          <span> @if(option.code!=''){[{{option.code}}] : } {{option.nom}} </span>
        </ng-template>
    </p-dropdown>
  </div>

  <div *ngIf="showAlbumGroupe" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
    <label for="inputtext">{{textField.albumGroupeId}}</label>
    <p-dropdown [options]="albumGroupeService.albumGroupeDataList" [(ngModel)]="chantFilter.albumGroupeId" [itemSize]="10"
      [virtualScroll]="false" [filter]="false" [checkmark]="true" optionValue="id" optionLabel="nom"
      styleClass="w100"  placeholder=" -Select {{textField.albumGroupeId}} -" [loading]="albumGroupeService.loadingAlbumGroupeDataList"
      [style]="{'width':'100%'}" (onChange)="onChangeDropdown(enumFields.ALBUM_GROUPE)">
      <ng-template let-option  pTemplate="selectedItem">
        <div class="ofa" pTooltip="{{option.nom}}" tooltipPosition="bottom">
          <img width="25" [src]="option.bannerPath" class="img-circle" />
          {{option.nom}}
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <img width="25" [src]="option.bannerPath" class="iimg-circle" />
        <span> {{option.nom}} </span>
      </ng-template>
    </p-dropdown>
  </div>

  <div *ngIf="showCategorieChant" class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
    <label for="inputtext">{{textField.categorieChantId}}</label>
    <p-dropdown [(ngModel)]="chantFilter.categorieChantId" [options]="categoryCeService.categoryDataList"  [itemSize]="10"
      [virtualScroll]="false" [filter]="false"  optionValue="code" optionLabel="nom"
      styleClass="w100" placeholder=" -Select {{textField.categorieChantId}} -" [loading]="categoryCeService.loadingCategoryDataList"
      (onChange)="onChangeDropdown(enumFields.CATEGORY)">
      <ng-template let-option  pTemplate="selectedItem">
          <div class="ofa" pTooltip="{{option.code}}-{{option.nom}}" tooltipPosition="bottom">
            <span>
              @if(!isCU && option.code!=''){[{{option.code}}] : } {{option.nom}} </span>
          </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span>
          @if(!isCU && option.code!=''){[{{option.code}}] : }
        {{option.nom}} </span>
      </ng-template>
    </p-dropdown>
  </div>

  <div *ngIf="showIsChantChoeur" class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
    <label for="inputtext">{{textField.isChantChoeur}}</label>
    <p-dropdown [(ngModel)]="chantFilter.isChantChoeur" [options]="keyValueYesNoList"  [itemSize]="10"
      [virtualScroll]="true" [filter]="false"  optionValue="value" optionLabel="label"
      styleClass=""  placeholder=" - is choir -" (onChange)="onChangeDropdown(enumFields.IS_CHOEUR)">
      <ng-template let-option  pTemplate="selectedItem">
        <span>{{option.label}}:{{option.value}} </span>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span>{{option.label}} </span>
      </ng-template>
    </p-dropdown>
  </div>

  <div *ngIf="showGenreEtStyleMusical" class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      Genre <span *ngIf="genreMusicalService.genreMusicalDataList.length>0">
        (<b>{{genreMusicalService.genreMusicalDataList.length}}</b> items)
      </span>
      <p-listbox
        [options]="genreMusicalService.genreMusicalDataList"
        [(ngModel)]="genreMusicalService.genreMusicalDataListSelected"
        optionLabel="name"
        [filter]="true"
        [multiple]="true"
        [metaKeySelection]="false"
        [listStyle]="{ 'max-height': '250px' }"
        (onChange)="onChangeGenre($event)">
        <ng-template let-obj pTemplate="item">
          <div [pTooltip]="obj.descriptions" tooltipPosition="top">{{ obj.name }}</div>
        </ng-template>
      </p-listbox>
      <span>
        <b>{{genreMusicalService.genreMusicalDataListSelected.length}}</b> items selected.
      </span>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
      Style <span *ngIf="genreMusicalService.styleMusicalDataList.length>0">
        (<b>{{genreMusicalService.styleMusicalDataList.length}}</b> items)
      </span>
      <p-listbox
        [options]="genreMusicalService.styleMusicalDataList"
        [(ngModel)]="genreMusicalService.styleMusicalDataListSelected"
        optionLabel="name"
        [filter]="true"
        [multiple]="true"
        [metaKeySelection]="false"
        [listStyle]="{ 'max-height': '250px' }"
        (onSelectAllChange)="onSelectAllChange($event)"
        (onChange)="onChangeStyle($event)">
        <ng-template let-obj pTemplate="item">
          <div [pTooltip]="obj.descriptions" tooltipPosition="top">{{ obj.name }}</div>
        </ng-template>
      </p-listbox>
      <span>
        <b>{{genreMusicalService.styleMusicalDataListSelected.length}}</b> items selected.
      </span>
    </div>
  </div>
</div>

 <div class="ibox-content">
   <br style="clear: both;" />
   <div class="hr-line-dashed"></div>
   <div class="form-group">
       <div class="col-sm-12">
           <button type="button" (click)="onClose()" class="btn btn-danger">{{ enumText.Cancel }}</button>
           &nbsp;&nbsp;
           <button type="button" (click)="onSubmit()" class="btn btn-primary" value="Save" >{{enumText.StartSearch}}</button>
       </div>
   </div>
</div>
<!-- </form> -->

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
