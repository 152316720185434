<div class="container">
  <div class="hearderPadding"></div>

  <p-breadcrumb class="myBreadcrumbCE" [model]="menuItemBreadcrumb">
    <ng-template pTemplate="item" let-item>
        <ng-container *ngIf="item.route; else elseBlock">
            <a [routerLink]="item.route" class="p-menuitem-link">
                <span [ngClass]="[item.icon ? item.icon : '', 'text-color']"></span>
                &nbsp;<span class="text-primary font-semibold">{{ item.label }}</span>
            </a>
        </ng-container>
        <ng-template #elseBlock>
            <a [href]="item.url">
                <span class="text-color">{{ item.label }}</span>
            </a>
        </ng-template>
    </ng-template>
  </p-breadcrumb>

  @if (!groupeCeService.isShowCEEBV)
  {
      <app-p-groupe-ce-items [isShowCEEBV]="groupeCeService.isShowCEEBV" [groupeChantantList]="groupeCeService.groupeCEdto.groupeCEList"></app-p-groupe-ce-items>
  }

  @if (groupeCeService.isShowCEEBV)
  {
      <app-p-groupe-ce-items [isShowCEEBV]="groupeCeService.isShowCEEBV" [groupeChantantList]="groupeCeService.groupeCEdto.groupeCEEBVList"></app-p-groupe-ce-items>
  }
</div>
