import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'environments/environment';

/*
https://aglowiditsolutions.com/blog/angular-seo/
https://www.semrush.com/blog/canonical-url-guide/
https://www.ventrips.com/blog/how-to-add-google-adsense-in-angular-to-make-passive-income-online
https://www.ventrips.com/blog/passive-income-online-with-seo-friendly-angular-pwa-spa
https://nintriva.com/blog/seo-friendly-angular-websites/
*/
@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta,
    private router: Router
  ) {}

  public setTitleTag(newTitle: string){
    this.title.setTitle(newTitle);
  }

  setMetaTags(config?: any) {
    config = {
      title: `Lyrics Chant | Chant d'Esperance | Chant Chorale et Groupe`,
      description: `Lyrics Chant | Chant d'Esperance | Chant Chorale et Groupe est une version électronique des Chants d'Espérance Edition spéciale Revue, corrigée et augmentée avec une interface plus conviviale et des fonctionnalités très utiles`,
      image: `${environment.serverPathWeb}/assets/images/lyrics_chant_620x529_exp.png`,
      url: `${environment.serverPathWeb}/${this.router.url}`,
      ...config
    };

    // Set title
    this.title.setTitle(config.title);

    // Google
    this.meta.updateTag({ name: 'Description', content: config.description });

    // Twitter
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: `@Grafritz` });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    // Facebook and other social sites
    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: `Chant Lyrics` });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: config.url });
    this.meta.updateTag({ property: 'fb:app_id', content: `your-facebook-app-id` });
  }
}
