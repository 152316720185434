import { Component, OnInit } from '@angular/core';
import { SettingsModel, SettingsTextField } from '@core/models/ce/settings.model';
import { CommonService } from '@core/service/common/common.service';
import { GlobalService } from '@core/service/global.service';
import { SettingsService } from '@core/service/settings/settings.service';
import { UnsubscribeOnDestroyAdapter } from '@shared';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent implements OnInit
{
  constructor( public settingsService: SettingsService,) { }

  ngOnInit(): void {
    this.settingsService.loadData();
  }

}
