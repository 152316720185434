import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GenreMusicalModel, StyleMusicalModel } from '@core/models/ce/chant-genre-style-model';
import { ChantUserTextField } from '@core/models/cu/chant-user-model';
import { CategoryCeService } from '@core/service/ce/categoryCE/category-ce.service';
import { GroupeChantantService } from '@core/service/ce/groupChantant/groupe-chantant.service';
import { CommonService } from '@core/service/common/common.service';
import { AlbumGroupeService } from '@core/service/cu/album-groupe/album-groupe.service';
import { GenreMusicalService } from '@core/service/genre-musical/genre-musical.service';
import { StyleMusicalService } from '@core/service/style-musical/style-musical.service';
import { ImportALlModule } from '@shared/imports';
import { LANGUES_DATA } from '@shared/mock-data/langue.md';
import { EnumFields, keyValueYesNoList } from '@shared/tools/enums/enum-status.enum';
import { UnsubscribeOnDestroyAdapter } from '@shared/UnsubscribeOnDestroyAdapter';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-search-advance-dialog-item',
  standalone: true,
  imports: [
    ImportALlModule,
  ],
  templateUrl: './search-advance-dialog-item.component.html',
  styleUrl: './search-advance-dialog-item.component.scss'
})
export class SearchAdvanceDialogItemComponent  extends UnsubscribeOnDestroyAdapter implements OnInit, OnDestroy {

  public textField=ChantUserTextField;
  public isCU: boolean= false;
  public isCUPublic: boolean= false;
  public keyValueYesNoList = keyValueYesNoList;
  public typeChantList = LANGUES_DATA;

  public showKeywordSearch: boolean=false;
  public showLangue: boolean=false;
  public showGroupeChantant: boolean=false;
  public showAlbumGroupe: boolean=false;
  public showCategorieChant: boolean=false;
  public showIsChantChoeur: boolean=false;
  public showGenreEtStyleMusical: boolean=false;

  //public responseGroupeCEObservable: any;
  //public responseCategoryCEObservable: any;

  constructor( public fb: FormBuilder,
    public commonService: CommonService,
    public dynamicDialogConfig: DynamicDialogConfig,
    public dynamicDialogRef: DynamicDialogRef,
    public categoryCeService: CategoryCeService,
    public groupeChantantService: GroupeChantantService,
    public albumGroupeService: AlbumGroupeService,
    public genreMusicalService: GenreMusicalService,
    public styleMusicalService: StyleMusicalService,
  ){
    super();
    //if( dynamicDialogConfig && dynamicDialogConfig.data ){
      this.isCU = dynamicDialogConfig.data.isCU!=undefined?dynamicDialogConfig.data.isCU:false;
      this.isCUPublic = dynamicDialogConfig.data.isCUPublic!=undefined?dynamicDialogConfig.data.isCUPublic:false;

      this.showKeywordSearch = dynamicDialogConfig.data.showKeywordSearch!=undefined?dynamicDialogConfig.data.showKeywordSearch:false;
      this.showLangue = dynamicDialogConfig.data.showLangue!=undefined?dynamicDialogConfig.data.showLangue:false;
      this.showGroupeChantant = dynamicDialogConfig.data.showGroupeChantant!=undefined?dynamicDialogConfig.data.showGroupeChantant:false;
      this.showAlbumGroupe = dynamicDialogConfig.data.showAlbumGroupe!=undefined?dynamicDialogConfig.data.showAlbumGroupe:false;
      this.showCategorieChant = dynamicDialogConfig.data.showCategorieChant!=undefined?dynamicDialogConfig.data.showCategorieChant:false;
      this.showIsChantChoeur = dynamicDialogConfig.data.showIsChantChoeur!=undefined?dynamicDialogConfig.data.showIsChantChoeur:false;
      this.showGenreEtStyleMusical = dynamicDialogConfig.data.showGenreEtStyleMusical!=undefined?dynamicDialogConfig.data.showGenreEtStyleMusical:false;

      // if( !this.isCUPublic ){
        this.chantFilter = dynamicDialogConfig.data.chantFilter;
        //this.categoryDataList=dynamicDialogConfig.data.categoryDataList;
        //this.groupeDataList=dynamicDialogConfig.data.groupeDataList;
      //}else{
      //   this.filterPublic = dynamicDialogConfig.data.filterPublic;
      // }
    //}
  }

  public get genreMusicalDataList(): GenreMusicalModel[] { return this.genreMusicalService.genreMusicalDataList; };
  public get genreMusicalDataListSelected(): GenreMusicalModel[] { return this.genreMusicalService.genreMusicalDataListSelected; };
  public get styleMusicalDataList():StyleMusicalModel[] { return this.genreMusicalService.styleMusicalDataList; };
  public get styleMusicalDataListSelected(): StyleMusicalModel[] { return this.genreMusicalService.styleMusicalDataListSelected; };

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    if( this.showGroupeChantant ){
      this.groupeChantantService.loadDataGroupeChantant();
    }
    if( this.showAlbumGroupe ){
      this.albumGroupeService.loadDataAlbumGroupe();
    }
    if( this.showCategorieChant ){
      this.categoryCeService.loadDataCategory();
    }
    if( this.showGenreEtStyleMusical ){
      this.loadDataGenreMusical();
    }
  }

  async loadDataGenreMusical()  {
    if(this.genreMusicalService.genreMusicalDataList?.length<=0){
      this.subs.sink = await this.genreMusicalService.getAll().subscribe({
        next: (result) => {
          this.genreMusicalService.genreMusicalDataList = result;
          //this.genreMusicalDataList.unshift(genreOuStyleMusicalVIDE);
          //console.log(`this.genreMusicalDataList:`, this.genreMusicalService.genreMusicalDataList);
        },
        error: (ex) => {
          console.log(ex);
        },
        complete: () => {
        }
      });
    }
  }

  onChangeDropdown(action: number){
    switch (action) {
      case EnumFields.TYPE_CHANT:
        this.chantFilterObjDto.typeChantSelected = this.typeChantList.find((x) => x.value==this.chantFilter.typeChant);
        break;
        case EnumFields.GROUPE_CHANTANT:
          this.chantFilterObjDto.groupeChantantSelected = this.groupeChantantService.groupeChantantDataList.find((x) => x.code==this.chantFilter.groupeChantantId);
          break;
        case EnumFields.ALBUM_GROUPE:
          this.chantFilterObjDto.albumGroupeSelected = this.albumGroupeService.albumGroupeDataList.find((x) => x.id==this.chantFilter.albumGroupeId);
          break;
        case EnumFields.CATEGORY:
          this.chantFilterObjDto.categorieChantSelected = this.categoryCeService.categoryDataList.find((x) => x.code==this.chantFilter.categorieChantId);
          break;
        case EnumFields.IS_CHOEUR:
          this.chantFilterObjDto.isChantChoeur = this.chantFilter.isChantChoeur;
          break;
        // case EnumFields.IS_CHOEUR:
        //   this.chantFilterObjDto.isChantChoeur = this.chantFilter.isChantChoeur;
        //   break;
      default:
        break;
    }
  }
  setAllSelectedData(){
    this.chantFilterObjDto.typeChantSelected = this.typeChantList.find((x) => x.value==this.chantFilter.typeChant);
    this.chantFilterObjDto.groupeChantantSelected = this.groupeChantantService.groupeChantantDataList.find((x) => x.code==this.chantFilter.groupeChantantId);
    this.chantFilterObjDto.albumGroupeSelected = this.albumGroupeService.albumGroupeDataList.find((x) => x.id==this.chantFilter.albumGroupeId);
    this.chantFilterObjDto.categorieChantSelected = this.categoryCeService.categoryDataList.find((x) => x.code==this.chantFilter.categorieChantId);
    this.chantFilterObjDto.isChantChoeur = this.chantFilter.isChantChoeur;

    this.chantFilterObjDto.genreMusicalListSelected = this.genreMusicalService.genreMusicalDataListSelected;
    this.chantFilterObjDto.styleMusicalListSelected = this.genreMusicalService.styleMusicalDataListSelected;
    console.log('this.chantFilterObjDto:', this.chantFilterObjDto);
  }

  onChangeGenre(event:any) {
    let gmData = event.value;
    //console.log(`ON.CHANGE GENRE:`,gmData);
    this.genreMusicalService.styleMusicalDataList=[];
    //this.chantFilter.genreMusicalNameDisplay=[];
    //this.chantFilter.genreMusicalIds=[];
    this.chantFilter.genreMusicalIds=[];
    this.genreMusicalService.styleMusicalDataListSelected=[];
    this.chantFilter.styleMusicalIds = undefined;
    //this.chantFilter.styleMusicalNameDisplay=undefined;

    gmData.forEach((gmItem: any) => {
      //this.filterPublic.genreMusicalIds?.push(gmItem.id);
      this.chantFilter.genreMusicalIds?.push(gmItem.id);
      //this.filterPublic.genreMusicalNameDisplay?.push(gmItem.name);
      if( gmItem.styleMusicals.length > 0 ){
        gmItem.styleMusicals.forEach((dt: any) => {
          // let val:boolean = this.genreMusicalService.styleMusicalDataListSelected.includes(permissions);
          this.genreMusicalService.styleMusicalDataList.push(dt);
        });
      }
    });
    // this.chantFilterObjDto.genreMusicalListSelected = this.genreMusicalService.genreMusicalDataListSelected;
    // this.chantFilterObjDto.styleMusicalListSelected = this.genreMusicalService.styleMusicalDataListSelected;
    //console.log(`filterPublic:`,this.filterPublic);
  }
  onChangeStyle(event:any) {
    let data = event.value;
    //this.filterPublic.styleMusicalNameDisplay=[];
    //this.filterPublic.styleMusicalIds=[];
    this.chantFilter.styleMusicalIds=[];
    //console.log(`ON.CHANGE STYLE:`,data);
    data.forEach((item: any) => {
      //this.filterPublic.styleMusicalIds?.push(item.id);
      this.chantFilter.styleMusicalIds?.push(item.id);
      //this.filterPublic.styleMusicalNameDisplay?.push(item.name);
    });
    //this.chantFilterObjDto.styleMusicalListSelected = data;
    //console.log(`filterPublic:`,this.filterPublic);
  }
  onSelectAllChange(event:any) {
    let data = event.value;
    //console.log(`ON.SELECTED.ALL.CHANGE STYLE:`,event);
    //console.log(`ON.SELECTED.ALL.CHANGE STYLE:`,data);
  }


onClose(){
  let list = {};
  list = {
    groupeDataList1: this.groupeChantantService.groupeChantantDataList,
    groupeDataList: this.albumGroupeService.albumGroupeDataList,
    categoryDataList: this.categoryCeService.categoryDataList,
  };
  if( this.isCUPublic ){
    list = {
      genreMusicalDataList: this.genreMusicalService.genreMusicalDataList,
      styleMusicalDataList: this.genreMusicalService.styleMusicalDataList,
    };
  }
  this.setAllSelectedData();
  this.dynamicDialogRef.close({ list: list, selected: this.chantFilterObjDto});
}

onSubmit(): void {
  this.clear();
  let data =this.chantFilter;
  let list = {};
  list = {
    groupeDataList1: this.groupeChantantService.groupeChantantDataList,
    groupeDataList: this.albumGroupeService.albumGroupeDataList,
    categoryDataList: this.categoryCeService.categoryDataList,
  };
  if( this.isCUPublic ){
    list = {
      genreMusicalDataList: this.genreMusicalService.genreMusicalDataList,
      styleMusicalDataList: this.genreMusicalService.styleMusicalDataList,
    };
   //data =this.chantFilter;
  }
  this.setAllSelectedData();
  this.dynamicDialogRef.close({isReloadPage: true, list: list, data: data, selected: this.chantFilterObjDto});
}

clear() {
  this.commonService.messageService.clear();
}

onError(error: any){
  console.log(error.message);
}
}
