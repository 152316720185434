import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PermissionCode } from '@shared/tools/enums/permission';
import { SessionAuthService } from '../session-auth/session-auth.service';
import { AuthService, InfoService, UserModel } from '@core';
import { InfoModel } from '@core/models/Info/info-model';
import { UserTextField } from '@core/models/user/user-model';
import { GS_DEV } from '@shared/tools/constants/constants';
import { LogicalOperator } from '@shared/tools/constants/types';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  public currentPermissions: PermissionCode[] = [];
  private permissionCodeSubject = new Subject<PermissionCode[]>();
  public roleName: string;

  constructor(
    public infoService: InfoService,
    public sessionAuthService: SessionAuthService,
    ) {
    this.roleName = this.sessionAuthService.userConnected?.role;
  }

  notifyPermissionCode(): void {
    this.permissionCodeSubject.next( this.currentPermissions );
  }

  get userConnected() : UserModel {
    return this.sessionAuthService.userConnected;
  }

  public getPermission(): Promise<PermissionCode[]> {
    return this.infoService.getInfoPermission().then((result: InfoModel) => {
      this.currentPermissions = result.permissions;
      this.notifyPermissionCode();
      this.roleName = result.roleCode;
      let curPermissions: UserModel = this.sessionAuthService.userConnected;
      curPermissions.permissions =result.permissions;
      //console.log(curPermissions.permissions);
      //console.log(curPermissions);
      this.sessionAuthService.setItem(UserTextField.user, JSON.stringify( curPermissions ));
      return result.permissions;
    });
  }

  public async hasPermission(permissionsCheck: PermissionCode[], logicalOp?: LogicalOperator): Promise<boolean> {
    if( this.currentPermissions.length > 0 ){
      return Promise.resolve(this.checkPermission(permissionsCheck, logicalOp));
    }else{
      const token = this.sessionAuthService.token;
      if( token ){
        await this.getPermission();
        return Promise.resolve( this.checkPermission(permissionsCheck, logicalOp));
      }else{
        return Promise.resolve(false);
      }
    }
  }

  public checkPermission(permissions: PermissionCode[], logicalOp: string='AND'): boolean {
    if( this.roleName === GS_DEV )
      return true;

    if( typeof permissions === 'string' ){
      let val:boolean = this.currentPermissions.includes(permissions);
      return val;
    }
    if( !permissions )
      return false;

    let hasPermission=false;

    for( const permission of permissions ){
      const permissionFound = this.currentPermissions.find((x) => x === permission);
      if( permissionFound ){
        hasPermission=true;
        if( logicalOp === 'OR'){
          break;
        }
      }else{
        hasPermission=false;
        if( logicalOp === 'AND'){
          break;
        }
      }
    }
    return hasPermission;
  }

  public checkPermissionLocal(permissionsCheck: PermissionCode[], logicalOp: string='AND'): boolean {
    //console.log(permissionsCheck);

    if( typeof permissionsCheck === 'string' ){
      if( permissionsCheck==PermissionCode.NoCheck){
        return true;
      }
    }
    if( !this.sessionAuthService.userConnected){
      return false;
    }
    let user = this.sessionAuthService.userConnected;
    if( user.role === GS_DEV )
      return true;

    if( !user.permissions ){
      return false;
    }
    if( typeof permissionsCheck === 'string' ){
      if( permissionsCheck==PermissionCode.NoCheck){
        return true;
      }
      let val:boolean = user.permissions.includes(permissionsCheck);
      return val;
    }
    if( !permissionsCheck )
      return false;

    let hasPermission=false;

    for( const permission of permissionsCheck ){
      if( permission==PermissionCode.NoCheck){
        return true;
      }
      const permissionFound = user.permissions.find((x) => x === permission);
      if( permissionFound ){
        hasPermission=true;
        if( logicalOp === 'OR'){
          break;
        }
      }else{
        hasPermission=false;
        if( logicalOp === 'AND'){
          break;
        }
      }
    }
    return hasPermission;
  }
  public checkPermissionLocalAny(permissionsCheck: PermissionCode[] | string[] | string ): boolean {
    if( typeof permissionsCheck === 'string' ){
      if( permissionsCheck==PermissionCode.NoCheck){
        return true;
      }
    }
    let user = this.sessionAuthService.userConnected;
    if( !user){
      return false;
    }
    if( user.role === GS_DEV )
      return true;

    if( !user.permissions ){
      return false;
    }
    if( typeof permissionsCheck === 'string' ){
      if( permissionsCheck==PermissionCode.NoCheck){
        return true;
      }
      //let val:boolean = user.permissions.includes(permissionsCheck);
      let val:boolean = user.permissions.findIndex((x) => x===permissionsCheck)>=0;
      return val;
    }
    if( !permissionsCheck )
      return false;

    let hasPermission=false;

    for( const permission of permissionsCheck ){
      if( permission==PermissionCode.NoCheck){
        return true;
      }
      const permissionFound = user.permissions.find((x) => x === permission);
      if( permissionFound ){
        hasPermission=true;
      }else{
        hasPermission=false;
      }
    }
    return hasPermission;
  }

}

