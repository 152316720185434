import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { GlobalService } from '@core/service/global.service';
import { AuthorizationService } from '@core/service/authorization/authorization.service';
import { MenuLink } from '@shared/tools/enums/enum-router';

export const authGuard: CanActivateFn = (route, state) => {
  let router= inject(Router);
  let globalService= inject(GlobalService);
  let authorizationService= inject(AuthorizationService);

  //console.log(`authGuard .::. DEBUT`);
  globalService.showLoader();
  if( !authorizationService.userConnected?.isConnected ){
    console.log(`authGuard .::. NOT CONNECTED`);
    router.navigate([ MenuLink.Login ]);
    globalService.showLoader(false);
    return false;
  }
  globalService.showLoader(false);
  //console.log(`authGuard .::. FIN`);
  return true;
};
