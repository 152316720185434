export enum EnumRouterLink{
  //---- Public ----
  Home= 'Home',
  Dashboard= 'dashboard',
  DashboardMain= 'dashboard/main',

  Genre= 'genre',
  Artist= 'artist',
  Contact= 'contact',
  PrivacyPolicy= 'privacy-policy',
  TermsConditions= 'terms-conditions',
  Guide= 'guide',

  ChantDesperanceDirRoot= 'CE',
  ChantDesperancePublic= 'lyrics',
  ChantDesperanceViewDetailsPublic= 'lyrics/view',
  FavorisPublic= 'Favoris',

  ChantDesperance= 'ChantDesperance',
  ChantDesperanceList= 'ChantDesperanceList',
  ChantDesperanceForm= 'ChantDesperanceform',
  ChantDesperanceFormDialod= 'ChantDesperanceFormDialod',

  CorrectionCEList= 'CorrectionCEList',
  CorrectionCEForm= 'CorrectionCEform',

  GroupeCEPublic= 'groupe-ce',
  GroupeCE= 'groupeCE',
  GroupeCEList= 'groupeCEList',
  GroupeCEForm= 'GroupeCEform',

  CategoryCE= 'CategoryCE',
  CategoryCEList= 'CategoryCEList',
  CategoryCEForm= 'CategoryCEform',

  SearchPagePublic= 'search',

  ChantUserDirRoot= 'CU',
  ChantUserPublic= 'song',
  ChantUserViewDetailsPublic= 'song/view',
  ChantUser= 'ChantUser',
  ChantSharedWithMeRoot= 'shared-with-me',
  ChantUserByGroup= 'ByGroup',
  ChantUserList= 'ChantUserList',
  ChantUserForm= 'ChantUserform',
  ChantUserAdmin= 'ChantUserAdmin',

  GroupeCU= 'groupeCU',
  GroupeCUList= 'groupeCUList',
  GroupeCUForm= 'groupeCUform',

  CategoryCU= 'categoryCU',
  CategoryCUList= 'categoryCUList',
  CategoryCUForm= 'categoryCUform',

  Settings= 'settings',
  Category= 'category',

  Security= 'security',
  Users= 'users',
  UserForm= 'userform',
  UsersConnected= 'usersConnected',

  Authentication= 'authentication',
  Login= 'Login',
  Logout= 'Logout',
  Signup= 'signup',
  ForgotPassword= 'forgotpassword',
  Locked= 'locked',
  Page401= 'page401',
  Page403= 'page403',
  Page404= 'page404',
  Page405= 'page405',
  Page500= 'page500',

  Roles= 'roles',
  GroupPermissions= 'groupPermissions',
  Permissions= 'permissions',
  RolesPermissions= 'RolesPermissions',

  AccessDenied= 'Accessdenied',
  ErrorPage= 'Error',
  ErrorHttp= 'Error/http/:httpCode',
}

export const MenuLink = {
  // Authentication
  Dashboard: `/${EnumRouterLink.DashboardMain}`,
  Repport: `/${EnumRouterLink.Dashboard}`,

  Contact: `/${EnumRouterLink.Contact}`,
  PrivacyPolicy: `/${EnumRouterLink.PrivacyPolicy}`,
  TermsConditions: `/${EnumRouterLink.TermsConditions}`,
  Guide: `/${EnumRouterLink.Guide}`,

  // ChantDesperance
  ChantDesperanceRoot: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.ChantDesperance}`,
  ChantDesperanceList: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.ChantDesperanceList}`,
  ChantDesperanceForm: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.ChantDesperanceForm}`,

  ChantDesperanceFormDialod: `/${EnumRouterLink.ChantDesperance}/${EnumRouterLink.ChantDesperanceFormDialod}`,
  // ChantDesperance
  GroupeCEPublic: `/${EnumRouterLink.GroupeCEPublic}`,
  ChantDesperancePublic: `/${EnumRouterLink.ChantDesperancePublic}`,
  FavorisPublic: `/${EnumRouterLink.FavorisPublic}`,
  ChantDesperanceDetailsPublic: `/${EnumRouterLink.ChantDesperanceViewDetailsPublic}`,


  CorrectionCERoot: `/${EnumRouterLink.ChantDesperance}`,
  CorrectionCEList: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.CorrectionCEList}`,
  CorrectionCEForm: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.CorrectionCEForm}`,
  // ChantDesperance/CategoryCE
  CategoryCERoot: `/${EnumRouterLink.ChantDesperanceDirRoot}`,
  CategoryCEList: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.CategoryCEList}`,
  CategoryCEForm: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.CategoryCEForm}`,
  // ChantDesperance/GroupeCE
  GroupeCERoot: `/${EnumRouterLink.ChantDesperanceDirRoot}`,
  GroupeCEList: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.GroupeCEList}`,
  GroupeCEForm: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.GroupeCEForm}`,

  ArtistList: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.Artist}`,
  GenreMusicalList: `/${EnumRouterLink.ChantDesperanceDirRoot}/${EnumRouterLink.Genre}`,

  // ChantUserAdmin
  ChantUserAdminList: `/${EnumRouterLink.ChantUser}/${EnumRouterLink.ChantUserAdmin}`,

  // /ChantUser
  ChantUserRoot: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.ChantUser}`,
  ChantSharedWithMeRoot: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.ChantSharedWithMeRoot}`,
  ChantUserByGroupRoot: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.ChantUser}/${EnumRouterLink.ChantUserByGroup}`,
  ChantUserPublic: `/${EnumRouterLink.ChantUserPublic}`,
  ChantUserDetailsPublic: `/${EnumRouterLink.ChantUserViewDetailsPublic}`,

  SearchPagePublic: `/${EnumRouterLink.SearchPagePublic}`,

  // CU/GroupeCU
  GroupeCURoot: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.GroupeCU}`,
  GroupeCUList: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.GroupeCUList}`,
  GroupeCUForm: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.GroupeCUForm}`,

  // ChantUser/CategoryCU
  CategoryCURoot: `/${EnumRouterLink.ChantUserDirRoot}`,
  CategoryCUList: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.CategoryCUList}`,
  CategoryCUForm: `/${EnumRouterLink.ChantUserDirRoot}/${EnumRouterLink.CategoryCUForm}`,

  // Authentication
  Login: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Login}`,
  Logout: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Logout}`,
  Signup: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Signup}`,
  Locked: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Locked}`,
  Page401: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Page401}`,
  Page403: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Page403}`,
  Page404: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Page404}`,
  Page405: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Page405}`,
  Page500: `/${EnumRouterLink.Authentication}/${EnumRouterLink.Page500}`,
  ForgotPassword: `/${EnumRouterLink.Authentication}/${EnumRouterLink.ForgotPassword}`,

  // Settings

  //Security
  Users: `/${EnumRouterLink.Security}/${EnumRouterLink.Users}`,
  UserForm: `/${EnumRouterLink.Security}/${EnumRouterLink.UserForm}`,
  UsersConnected: `/${EnumRouterLink.Security}/${EnumRouterLink.UsersConnected}`,

  Roles: `/${EnumRouterLink.Security}/${EnumRouterLink.Roles}`,
  Permissions: `/${EnumRouterLink.Security}/${EnumRouterLink.Permissions}`,
  GroupPermissions: `/${EnumRouterLink.Security}/${EnumRouterLink.GroupPermissions}`,
  RolesPermissions: `/${EnumRouterLink.Security}/${EnumRouterLink.RolesPermissions}`,

  Settings: `/${EnumRouterLink.Security}/${EnumRouterLink.Settings}`,

};
