import { Component } from '@angular/core';

@Component({
  selector: 'app-p-favoris-ce-list',
  standalone: true,
  imports: [],
  templateUrl: './p-favoris-ce-list.component.html',
  styleUrl: './p-favoris-ce-list.component.scss'
})
export class PFavorisCeListComponent {

}
