<div class="hearderPadding"></div>
<div class="container">
  <app-display-horizontal-ads-responsive></app-display-horizontal-ads-responsive>
  <div class="row">
    <h2>How to find us</h2>
    <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
      <div class="card">
        <div class="boxs mail_listing">
          <div class="inbox-center table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th colspan="1">
                    <div class="inbox-header">
                      Post Us Message
                    </div>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="row">
            <form class="composeForm" [formGroup]="formGroupPageName" (ngSubmit)="onSubmit()">
              <input type="hidden" [(ngModel)]="contact.infoDevice" formControlName="infoDevice">
              <div class="col-lg-12">
                  <p-messages [(value)]="message"></p-messages>
                  <div class="form-line">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{textField.name}}</mat-label>
                      <input onfocus="this.select()" matInput [(ngModel)]="contact.name" formControlName="name">
                      <app-validator-error [control]="formGroupPageName.get('name')" ></app-validator-error>
                    </mat-form-field>
                  </div>

                  <div class="form-line">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{textField.email}}</mat-label>
                      <input type="email" onfocus="this.select()" matInput [(ngModel)]="contact.email" formControlName="email">
                      <app-validator-error [control]="formGroupPageName.get('email')" ></app-validator-error>
                    </mat-form-field>
                  </div>

                  <div class="form-line">
                    <mat-form-field class="example-full-width" appearance="outline">
                      <mat-label>{{textField.subject}}</mat-label>
                      <input onfocus="this.select()" matInput [(ngModel)]="contact.subject" formControlName="subject">
                      <app-validator-error [control]="formGroupPageName.get('subject')" ></app-validator-error>
                    </mat-form-field>
                  </div>

                  <app-validator-error [control]="formGroupPageName.get('message')" ></app-validator-error>
                  <ckeditor [editor]="Editor" data="Message" placeholder="Message..." [(ngModel)]="contact.message" formControlName="message"></ckeditor>

              </div>
              <div class="col-lg-12">

                <div class="m-l-25 m-b-20">
                  <app-tools-button-save
                    class="tc"
                    [addOrEditButton]="true"
                    [formGroupPageName]="this.formGroupPageName"
                    iconBtnSaveChanges="far fa-envelope">
                  </app-tools-button-save>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
      <div class="body">
      <h4>Contact Info</h4>
      <div class="card">
        <div id="mail-nav">
          <ul class="online-user" id="online-offline">
            <li>
              <a><i class="fa fa-envelope" aria-hidden="true">:</i>chantchoraleetgroupe&#64;gmail.com</a>
            </li>
            <li>
              <a target="_blank" href="http://www.facebook.com/Rezo509">
                <i class="fa fa-envelope" aria-hidden="true">:</i>Rezo509
              </a>
            </li>
            <li>
              <a target="_blank" href="http://www.rezo509.com/Institution.aspx?ID=1&page=tab_FicheOffresEmploi">
                <i class="fa fa-bullseye" aria-hidden="true"></i>
                <span>Fiche technique</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>

  </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="top-center"></p-toast>
